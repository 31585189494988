import React from 'react';
import { List } from '../list';
import { VestingBlock } from './vesting-block';
import st from './style.module.css';
import { VestingBlockProps } from './vesting-block/VestingBlock';

const Vesting = (props: VestingBlockProps) => {
  return (
    <div className={st.box}>
      <List title="Vesting" list={[]} titleWithSeparator />
      <VestingBlock {...props} />
    </div>
  );
};

export { Vesting };
