import React, { useRef } from 'react';
import { InfoBlock } from './info-block';
import { ProgressBar } from './progress-bar';
import useProgressBar from './progress-bar/useProgressBar';
import { UserAllocation } from './user-allocation';
import { UserAllocationProps } from './user-allocation/UserAllocation';

export type InvestmentProgressIndicatorProps = {
  committed: number;
  yourCommit: number | null;
  softCup: number;
  hardCup: number;
  currencyLabel: string;
  userAllocation: UserAllocationProps | null;
};
const InvestmentProgressIndicator = ({ currencyLabel, userAllocation, ...rest }: InvestmentProgressIndicatorProps) => {
  const { committed, yourCommit, softCup, hardCup } = rest;
  const separator1Ref = useRef<HTMLElement>(null);
  const separator2Ref = useRef<HTMLElement>(null);

  const { softCupPart, middlePart, hardCupPart } = useProgressBar({
    committed,
    yourCommit,
    softCup,
    hardCup,
    separator1Ref,
    separator2Ref,
  });
  return (
    <div className="investment-progress-indicator">
      <ProgressBar
        {...rest}
        separator1Ref={separator1Ref}
        separator2Ref={separator2Ref}
        softCupPart={softCupPart}
        middlePart={middlePart}
        hardCupPart={hardCupPart}
      />
      <InfoBlock
        committed={committed}
        yourCommit={yourCommit}
        softCup={softCup}
        hardCup={hardCup}
        currencyLabel={currencyLabel}
      />
      {userAllocation && <UserAllocation {...userAllocation} />}
    </div>
  );
};

export { InvestmentProgressIndicator };
