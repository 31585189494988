import React, { createContext, useState } from 'react';

// eslint-disable-next-line no-shadow
export enum ReturnRequest {
  normalCondition,
  start,
  good,
  bad,
}

type ReturnContext = {
  returnEver: ReturnRequest;
  setReturnEver: (r: ReturnRequest) => void;
  returnToken: ReturnRequest;
  setReturnToken: (r: ReturnRequest) => void;
  returnProjectEver: ReturnRequest;
  setReturnProjectEver: (r: ReturnRequest) => void;
  finishFundraising: ReturnRequest;
  setFinishFundraising: (r: ReturnRequest) => void;
};
export const ReturnContext = createContext<ReturnContext>({
  returnEver: ReturnRequest.normalCondition,
  setReturnEver: () => undefined,
  returnToken: ReturnRequest.normalCondition,
  setReturnToken: () => undefined,
  returnProjectEver: ReturnRequest.normalCondition,
  setReturnProjectEver: () => undefined,
  finishFundraising: ReturnRequest.normalCondition,
  setFinishFundraising: () => undefined,
});

const ReturnProvider: React.FC = ({ children }) => {
  const [returnEver, setReturnEver] = useState<ReturnRequest>(ReturnRequest.normalCondition);
  const [returnToken, setReturnToken] = useState<ReturnRequest>(ReturnRequest.normalCondition);
  const [returnProjectEver, setReturnProjectEver] = useState<ReturnRequest>(ReturnRequest.normalCondition);
  const [finishFundraising, setFinishFundraising] = useState<ReturnRequest>(ReturnRequest.normalCondition);

  const contextValue = {
    returnEver,
    setReturnEver,
    returnToken,
    setReturnToken,
    returnProjectEver,
    setReturnProjectEver,
    finishFundraising,
    setFinishFundraising,
  };

  return <ReturnContext.Provider value={contextValue}>{children}</ReturnContext.Provider>;
};

export default ReturnProvider;
