import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router';
import { links } from '../../constants/links';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { ProjectType } from '../../ton/get-project';
import { VestingPeriods } from '../../ton/utils/LaunchPool';
import { formatCurrencies, formatDate, formatPriceNew, formatTONAddress, PriceProps } from '../../utils/helpers';
import { Button } from '../button';
import { List } from '../list';
import { ListItem } from '../list/List';
import { CardProps } from '../small-card/Card';
import { TokenLink } from '../token-link';
import CardHeader from './CardHeader';
import common from './common.module.css';
import st from './sale-details.module.css';
import { useYourInvestment, YourInvestmentProps } from './YourInvestment';

export type SaleDetailsProps = {
  totalSales: {
    amount: number;
    currency: string;
  } | null;
  finishFundraisingByOwnerButton?: JSX.Element;
  withdrawalByOwnerButton?: JSX.Element;
  rate: number;
  project: ProjectType;
  poolInformation: {
    purchaseOpensDate?: Date;
    purchaseClosesDate?: Date;
  };
  tokenInformation: {
    tokenForSale?: PriceProps;
    softcap?: PriceProps;
    hardcap?: PriceProps;
    // received?: PriceProps;
    periods: VestingPeriods[];
  };
  userProps: YourInvestmentProps;
  buttonsBox: JSX.Element | null;
};

const SaleDetails = ({
  totalSales,
  finishFundraisingByOwnerButton,
  withdrawalByOwnerButton,
  tokenCurrency,
  mainCurrency,
  project,
  poolInformation: { purchaseOpensDate, purchaseClosesDate },
  tokenInformation,
  userProps,
  buttonsBox,
}: SaleDetailsProps & CardProps) => {
  const { tokenForSale, softcap, hardcap /* , received */ } = tokenInformation;
  const { isLogin } = useContext(TONWalletContext);
  const [tokenInformationList, setTokenInformationList] = useState<ListItem[]>([]);

  const userList = useYourInvestment(userProps);

  useEffect(() => {
    const innerList: ListItem[] = [];
    if (tokenCurrency && mainCurrency) {
      if (totalSales)
        innerList.push({
          title: 'Committed',
          data: formatPriceNew({
            amount: totalSales.amount,
            currency: totalSales.currency,
          }),
        });
      if (typeof softcap?.amount === 'number')
        innerList.push({
          title: 'Soft cap',
          data: formatPriceNew({
            amount: new BigNumber(softcap.amount).shiftedBy(-mainCurrency.decimals).toNumber(),
            currency: softcap.currency,
          }),
        });
      if (typeof hardcap?.amount === 'number')
        innerList.push({
          title: 'Hard cap',
          data: formatPriceNew({
            amount: new BigNumber(hardcap.amount).shiftedBy(-mainCurrency.decimals).toNumber(),
            currency: hardcap.currency,
          }),
        });
      if (typeof tokenForSale?.amount === 'number')
        innerList.push({
          title: 'Token For Sale',
          data: formatPriceNew({
            amount: new BigNumber(tokenForSale.amount).shiftedBy(-tokenCurrency.decimals).toNumber(),
            currency: tokenForSale.currency,
          }),
        });
      // if (isLogin) {
      //   if (typeof received?.amount === 'number' && received?.amount > 0)
      //     innerList.push({
      //       title: 'Received',
      //       data: formatPriceNew({
      //         amount: received.amount / 10 ** (tokenCurrency.decimals ?? 0),
      //         currency: <TokenLink tonTokenAddress={tokenCurrency.launchTokenRoot}>{received.currency}</TokenLink>,
      //       }),
      //     });
      // }
    }
    setTokenInformationList(innerList);
  }, [isLogin, tokenForSale, softcap, hardcap, /* received, */ mainCurrency, tokenCurrency]);

  return (
    <>
      <div className={st['info-box']}>
        <div className={st.box}>
          <section className={`${common['box-section']} ${st.section}`}>
            <List
              title="Pool Information"
              list={[
                {
                  title: <>Pay&nbsp;in&nbsp;</>,
                  data: project.mainCurrency.symbol,
                },
                {
                  title: 'Purchase opens',
                  data: formatDate({
                    date: purchaseOpensDate,
                  }),
                },
                {
                  title: 'Purchase closes',
                  data: formatDate({
                    date: purchaseClosesDate,
                  }),
                },
                // {
                //   title: 'Pool contract',
                //   data: (
                //     <a
                //       href={`https://${links.tonscanHostName}/accounts/${project.launchPoolAddress}`}
                //       target="_blank"
                //       rel="noopener noreferrer"
                //       className="initial"
                //     >
                //       {formatTONAddress({
                //         address: project.launchPoolAddress,
                //         numberOfCharactersUpTo: 2,
                //         numberOfCharactersAfter: 4,
                //       })}
                //     </a>
                //   ),
                // },
              ]}
            />
          </section>
          <section className={`${common['box-section']} ${st.section}`}>
            <List title="Token Information" list={[...(tokenInformationList || []), ...(userList || [])]} />
          </section>
          <section className={st.buttons}>
            {finishFundraisingByOwnerButton}
            {withdrawalByOwnerButton}
            {buttonsBox}
          </section>
        </div>
      </div>
    </>
  );
};

export default SaleDetails;
