import React from 'react';
import participantsIconPng from './participants-icon.svg';
import st from './style.module.css';
import totalSalesIconPng from './total-sales-icon.svg';

export const wallet = (
  <svg className={st.wallet} width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2 0C0.895431 0 0 0.89543 0 2V11C0 12.1046 0.89543 13 2 13H11C12.1046 13 13 12.1046 13 11V2C13 0.895431 12.1046 0 11 0H2ZM7.5 5C6.67157 5 6 5.67157 6 6.5C6 7.32843 6.67157 8 7.5 8H9.5C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5H7.5Z"
    />
  </svg>
);

export const walletWhite = (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2 0C0.895431 0 0 0.89543 0 2V11C0 12.1046 0.89543 13 2 13H11C12.1046 13 13 12.1046 13 11V2C13 0.895431 12.1046 0 11 0H2ZM7.5 5C6.67157 5 6 5.67157 6 6.5C6 7.32843 6.67157 8 7.5 8H9.5C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5H7.5Z"
      fill="white"
    />
  </svg>
);

export const walletLogout = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M21.737 12.1635L15.4034 18.7249C14.8379 19.3108 13.8577 18.9007 13.8577 18.061V14.3116H8.73044C8.22903 14.3116 7.82564 13.8937 7.82564 13.3743V9.6249C7.82564 9.10545 8.22903 8.68755 8.73044 8.68755H13.8577V4.93818C13.8577 4.10238 14.8341 3.68838 15.4034 4.27422L21.737 10.8356C22.0877 11.2028 22.0877 11.7964 21.737 12.1635ZM10.2385 18.5297V16.9674C10.2385 16.7097 10.0349 16.4988 9.78605 16.4988H6.61923C5.95193 16.4988 5.41282 15.9403 5.41282 15.249V7.75021C5.41282 7.05892 5.95193 6.50042 6.61923 6.50042H9.78605C10.0349 6.50042 10.2385 6.28951 10.2385 6.03174V4.4695C10.2385 4.21173 10.0349 4.00083 9.78605 4.00083H6.61923C4.62111 4.00083 3 5.68024 3 7.75021V15.249C3 17.3189 4.62111 18.9983 6.61923 18.9983H9.78605C10.0349 18.9983 10.2385 18.7874 10.2385 18.5297Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);

export const door = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M26.667 26.1743V23.5077L26.667 4.621C26.667 4.30874 26.5575 4.00637 26.3574 3.7666C26.1574 3.52684 25.8795 3.36489 25.5723 3.309L12.943 1.013C12.8277 0.991871 12.7093 0.996363 12.5959 1.02615C12.4826 1.05594 12.3772 1.1103 12.2873 1.18538C12.1973 1.26045 12.125 1.3544 12.0754 1.46056C12.0258 1.56672 12.0002 1.68249 12.0003 1.79966V3.50633L6.66699 3.50766C6.31337 3.50766 5.97423 3.64814 5.72418 3.89819C5.47413 4.14824 5.33366 4.48738 5.33366 4.841V23.5077V26.1743H8.00032V6.17433H12.0003V26.1743H26.667ZM16.0003 12.841H18.667V15.5077H16.0003V12.841Z"
      fill="#64FF99"
    />
    <path
      // eslint-disable-next-line max-len
      d="M26.667 17.4999V20.1666L26.667 27.5531C26.667 27.8654 26.5575 28.1678 26.3574 28.4075C26.1574 28.6473 25.8795 28.8092 25.5723 28.8651L12.943 31.1611C12.8277 31.1823 12.7093 31.1778 12.5959 31.148C12.4826 31.1182 12.3772 31.0638 12.2873 30.9888C12.1973 30.9137 12.125 30.8197 12.0754 30.7136C12.0258 30.6074 12.0002 30.4916 12.0003 30.3745V28.6678L6.66699 28.6665C6.31337 28.6665 5.97423 28.526 5.72418 28.2759C5.47413 28.0259 5.33366 27.6868 5.33366 27.3331V20.1666V17.4999H8.00032V25.9998H12.0003V17.4999H26.667Z"
      fill="#64FF99"
    />
  </svg>
);

export const projects = (
  <svg className={st.projects} width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1388_2976)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.89 1.95197L20.89 5.95197C21.5697 6.2897 21.9996 6.98303 22 7.74197V17.272C21.9996 18.0309 21.5697 18.7242 20.89 19.062L12.89 23.062C12.3267 23.3439 11.6634 23.3439 11.1 23.062L3.10005 19.062C2.42104 18.7198 1.99476 18.0223 2.00005 17.262V7.74197C2.00045 6.98303 2.43039 6.2897 3.11005 5.95197L11.11 1.95197C11.6707 1.67338 12.3294 1.67338 12.89 1.95197Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.31982 6.66197L11.9998 11.502L21.6798 6.66197"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 23.262V11.502" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1388_2976">
        <rect width="24" height="24" transform="translate(0 0.501968)" />
      </clipPath>
    </defs>
  </svg>
);

export const trade = (
  <svg width="23" height="16" viewBox="0 0 23 16" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M18.5 4L14.5 8H17.5C17.5 9.5913 16.8679 11.1174 15.7426 12.2426C14.6174 13.3679 13.0913 14 11.5 14C10.5 14 9.53 13.75 8.7 13.3L7.24 14.76C8.47 15.54 9.93 16 11.5 16C13.6217 16 15.6566 15.1571 17.1569 13.6569C18.6571 12.1566 19.5 10.1217 19.5 8H22.5L18.5 4ZM5.5 8C5.5 6.4087 6.13214 4.88258 7.25736 3.75736C8.38258 2.63214 9.9087 2 11.5 2C12.5 2 13.47 2.25 14.3 2.7L15.76 1.24C14.53 0.46 13.07 0 11.5 0C9.37827 0 7.34344 0.842855 5.84315 2.34315C4.34285 3.84344 3.5 5.87827 3.5 8H0.5L4.5 12L8.5 8"
    />
  </svg>
);

export const social = [
  {
    key: 'facebook',
    element: (
      <svg width="40" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z"
        />
      </svg>
    ),
  },
  {
    key: 'twitter',
    element: (
      <svg width="40" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M21.533 5.11175C21.5482 5.32494 21.5482 5.53817 21.5482 5.75136C21.5482 12.2539 16.599 19.7463 7.5533 19.7463C4.76648 19.7463 2.17767 18.9391 0 17.5382C0.395953 17.5838 0.776625 17.5991 1.18781 17.5991C3.48727 17.5991 5.60405 16.8224 7.29441 15.4976C5.13197 15.4519 3.31978 14.0356 2.69541 12.0864C3 12.132 3.30455 12.1625 3.62437 12.1625C4.06598 12.1625 4.50764 12.1016 4.91878 11.995C2.66498 11.5381 0.974578 9.55845 0.974578 7.16759V7.1067C1.62937 7.47219 2.39086 7.70061 3.19791 7.73103C1.87303 6.84777 1.00505 5.34017 1.00505 3.63458C1.00505 2.72089 1.24866 1.88333 1.67508 1.15236C4.09641 4.13713 7.73602 6.08633 11.8172 6.29956C11.7411 5.93408 11.6954 5.55341 11.6954 5.17269C11.6954 2.462 13.8883 0.253906 16.6141 0.253906C18.0304 0.253906 19.3095 0.847813 20.208 1.8072C21.3197 1.59402 22.3857 1.18283 23.3299 0.619391C22.9643 1.76155 22.1877 2.72094 21.1674 3.33003C22.1573 3.22348 23.1167 2.94931 23.9999 2.56864C23.33 3.54322 22.4924 4.4112 21.533 5.11175Z"
        />
      </svg>
    ),
  },
  {
    key: 'vk',
    element: (
      <svg width="40" height="14" viewBox="0 0 21 14" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M11.3774 13.2398C4.81755 13.2398 1.07589 8.55508 0.919983 0.759766H4.20593C4.31386 6.48133 6.73635 8.90487 8.65515 9.40457V0.759766H11.7492V5.6943C13.644 5.48193 15.6348 3.23328 16.3064 0.759766H19.4004C19.1474 2.0426 18.6429 3.25724 17.9186 4.32771C17.1943 5.39818 16.2658 6.30145 15.1911 6.98103C16.3907 7.60197 17.4503 8.48087 18.2999 9.55972C19.1495 10.6386 19.7699 11.8928 20.12 13.2398H16.7141C16.3998 12.0699 15.7611 11.0226 14.8778 10.2293C13.9946 9.4359 12.9063 8.93174 11.7492 8.77995V13.2398H11.3774Z"
        />
      </svg>
    ),
  },
  {
    key: 'telegram',
    element: (
      <svg width="40" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M1.83408 8.98896C8.27652 6.18209 12.5725 4.33163 14.722 3.43758C20.8592 0.884884 22.1345 0.441454 22.9657 0.426656C23.1485 0.423591 23.5573 0.468898 23.8221 0.683747C24.0456 0.865162 24.1071 1.11023 24.1366 1.28223C24.166 1.45423 24.2027 1.84605 24.1735 2.15221C23.841 5.64665 22.4019 14.1268 21.6698 18.0406C21.36 19.6967 20.75 20.252 20.1595 20.3063C18.8761 20.4244 17.9016 19.4582 16.6586 18.6434C14.7136 17.3684 13.6147 16.5747 11.7267 15.3305C9.54484 13.8927 10.9593 13.1024 12.2027 11.8109C12.5282 11.4729 18.1827 6.3297 18.2921 5.86313C18.3058 5.80478 18.3185 5.58727 18.1893 5.47242C18.0601 5.35757 17.8693 5.39684 17.7317 5.42808C17.5366 5.47235 14.4294 7.52612 8.41004 11.5894C7.52806 12.195 6.7292 12.4901 6.01344 12.4746C5.22438 12.4576 3.70654 12.0285 2.57818 11.6617C1.1942 11.2118 0.0942366 10.9739 0.190018 10.2099C0.239907 9.81196 0.787928 9.40498 1.83408 8.98896Z"
        />
      </svg>
    ),
  },
  {
    key: 'telegram2',
    element: (
      <svg width="40" height="21" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M1.83408 8.98896C8.27652 6.18209 12.5725 4.33163 14.722 3.43758C20.8592 0.884884 22.1345 0.441454 22.9657 0.426656C23.1485 0.423591 23.5573 0.468898 23.8221 0.683747C24.0456 0.865162 24.1071 1.11023 24.1366 1.28223C24.166 1.45423 24.2027 1.84605 24.1735 2.15221C23.841 5.64665 22.4019 14.1268 21.6698 18.0406C21.36 19.6967 20.75 20.252 20.1595 20.3063C18.8761 20.4244 17.9016 19.4582 16.6586 18.6434C14.7136 17.3684 13.6147 16.5747 11.7267 15.3305C9.54484 13.8927 10.9593 13.1024 12.2027 11.8109C12.5282 11.4729 18.1827 6.3297 18.2921 5.86313C18.3058 5.80478 18.3185 5.58727 18.1893 5.47242C18.0601 5.35757 17.8693 5.39684 17.7317 5.42808C17.5366 5.47235 14.4294 7.52612 8.41004 11.5894C7.52806 12.195 6.7292 12.4901 6.01344 12.4746C5.22438 12.4576 3.70654 12.0285 2.57818 11.6617C1.1942 11.2118 0.0942366 10.9739 0.190018 10.2099C0.239907 9.81196 0.787928 9.40498 1.83408 8.98896Z"
        />
      </svg>
    ),
  },
  {
    key: 'discord',
    element: (
      <svg height="25px" viewBox="0 -28.5 256 256" version="1.1" preserveAspectRatio="xMidYMid" overflow="visible">
        <g>
          <path
            // eslint-disable-next-line max-len
            d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    ),
  },
  {
    key: 'instagram',
    element: (
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" overflow="visible">
        <path
          // eslint-disable-next-line max-len
          d="M15.52 12C15.52 12.6962 15.3136 13.3767 14.9268 13.9556C14.54 14.5345 13.9902 14.9856 13.347 15.2521C12.7038 15.5185 11.9961 15.5882 11.3133 15.4524C10.6305 15.3165 10.0033 14.9813 9.51098 14.489C9.0187 13.9967 8.68346 13.3695 8.54764 12.6867C8.41182 12.0039 8.48152 11.2961 8.74794 10.653C9.01436 10.0098 9.46553 9.46001 10.0444 9.07323C10.6233 8.68644 11.3038 8.48 12 8.48C12.9332 8.48107 13.8279 8.85227 14.4878 9.51216C15.1477 10.1721 15.5189 11.0668 15.52 12ZM23 7.16V16.84C22.9982 18.4732 22.3486 20.0389 21.1937 21.1937C20.0389 22.3486 18.4732 22.9982 16.84 23H7.16C5.52683 22.9982 3.96109 22.3486 2.80627 21.1937C1.65144 20.0389 1.00185 18.4732 1 16.84V7.16C1.00185 5.52683 1.65144 3.96109 2.80627 2.80627C3.96109 1.65144 5.52683 1.00185 7.16 1H16.84C18.4732 1.00185 20.0389 1.65144 21.1937 2.80627C22.3486 3.96109 22.9982 5.52683 23 7.16V7.16ZM17.28 12C17.28 10.9557 16.9703 9.93488 16.3902 9.06659C15.81 8.1983 14.9854 7.52155 14.0206 7.12192C13.0558 6.72229 11.9941 6.61772 10.9699 6.82145C9.9457 7.02518 9.0049 7.52806 8.26648 8.26648C7.52806 9.0049 7.02518 9.9457 6.82145 10.9699C6.61772 11.9941 6.72229 13.0558 7.12192 14.0206C7.52155 14.9854 8.1983 15.81 9.06659 16.3902C9.93488 16.9703 10.9557 17.28 12 17.28C13.3999 17.2784 14.7419 16.7216 15.7318 15.7318C16.7216 14.7419 17.2784 13.3999 17.28 12V12ZM19.04 6.28C19.04 6.01893 18.9626 5.76372 18.8175 5.54665C18.6725 5.32957 18.4663 5.16039 18.2251 5.06048C17.9839 4.96057 17.7185 4.93443 17.4625 4.98536C17.2064 5.0363 16.9712 5.16201 16.7866 5.34662C16.602 5.53122 16.4763 5.76643 16.4254 6.02248C16.3744 6.27854 16.4006 6.54394 16.5005 6.78514C16.6004 7.02634 16.7696 7.2325 16.9866 7.37754C17.2037 7.52258 17.4589 7.6 17.72 7.6C18.0701 7.6 18.4058 7.46093 18.6534 7.21338C18.9009 6.96583 19.04 6.63009 19.04 6.28Z"
          stroke="none"
        />
      </svg>
    ),
  },
  {
    key: 'medium',
    element: (
      <svg width="40" height="15" viewBox="0 0 24 15" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M6.77965 0.759945C10.5239 0.759945 13.5593 3.74471 13.5593 7.4266C13.5593 11.1085 10.5239 14.0933 6.77965 14.0933C3.03535 14.0933 0 11.1085 0 7.4266C0 3.74471 3.03535 0.759945 6.77965 0.759945Z"
        />
        <path
          // eslint-disable-next-line max-len
          d="M17.5729 1.07993C19.4451 1.07993 20.9628 3.91546 20.9628 7.41326C20.9628 10.9111 19.4451 13.7466 17.5729 13.7466C15.7008 13.7466 14.1831 10.9111 14.1831 7.41326C14.1831 3.91546 15.7008 1.07993 17.5729 1.07993Z"
        />
        <path
          // eslint-disable-next-line max-len
          d="M22.8068 1.74657C23.4658 1.74657 24 4.27765 24 7.3999C24 10.5221 23.4658 13.0532 22.8068 13.0532C22.1478 13.0532 21.6136 10.5221 21.6136 7.3999C21.6136 4.27765 22.1478 1.74657 22.8068 1.74657Z"
        />
      </svg>
    ),
  },
];

export const buttonsBoxMobile = (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M8 8C8.7911 8 9.5645 7.76541 10.2223 7.32588C10.8801 6.88635 11.3928 6.26164 11.6955 5.53074C11.9983 4.79983 12.0775 3.99556 11.9231 3.21964C11.7688 2.44372 11.3878 1.73098 10.8284 1.17157C10.269 0.612161 9.5563 0.231201 8.7804 0.0768609C8.0044 -0.0774791 7.2002 0.00173104 6.4693 0.304481C5.73836 0.607231 5.11365 1.11992 4.67412 1.77772C4.2346 2.43552 4 3.20888 4 4C4.00119 5.0605 4.423 6.07723 5.17289 6.82711C5.92278 7.577 6.9395 7.99881 8 8ZM8 2C8.3956 2 8.7822 2.1173 9.1111 2.33706C9.44 2.55683 9.6964 2.86918 9.8478 3.23463C9.9991 3.60009 10.0387 4.00222 9.9616 4.39018C9.8844 4.77814 9.6939 5.13451 9.4142 5.41422C9.1345 5.69392 8.7781 5.8844 8.3902 5.96157C8.0022 6.03874 7.6001 5.99914 7.2346 5.84776C6.8692 5.69639 6.5568 5.44004 6.3371 5.11114C6.1173 4.78224 6 4.39556 6 4C6.0006 3.46976 6.2115 2.9614 6.5865 2.58646C6.9614 2.21152 7.4698 2.00061 8 2ZM15.707 13.2041C13.6615 11.163 10.8897 10.0166 8 10.0166C5.11027 10.0166 2.33855 11.163 0.293 13.2041L0 13.4971V19H16V13.4971L15.707 13.2041ZM14 17H2V14.3383C3.64125 12.8445 5.78072 12.0166 8 12.0166C10.2193 12.0166 12.3588 12.8445 14 14.3383V17Z"
    />
  </svg>
);

export const arrowOld = (
  <svg width="35" height="29" viewBox="0 0 30 29" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M17.2246 27.0575L15.7504 28.5317C15.1262 29.1559 14.1168 29.1559 13.4992 28.5317L0.589868 15.629C-0.0343506 15.0047 -0.0343506 13.9954 0.589868 13.3778L13.4992 0.468408C14.1235 -0.155811 15.1328 -0.155811 15.7504 0.468408L17.2246 1.94263C17.8555 2.57349 17.8422 3.60278 17.1981 4.22036L9.19612 11.8438H28.2813C29.1645 11.8438 29.875 12.5543 29.875 13.4376V15.5626C29.875 16.4458 29.1645 17.1563 28.2813 17.1563H9.19612L17.1981 24.7797C17.8489 25.3973 17.8621 26.4266 17.2246 27.0575Z"
    />
  </svg>
);

export const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path d="M3 19.00L13.9062 6.84378M3 16.75L14.074 28.824M3 17.75H35" stroke="#276EF5" strokeWidth="4" />
  </svg>
);

export const dropDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M18 15L12 9L6 15" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
  </svg>
);

export const close = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g opacity="0.7">
      <path
        d="M3 3L12 12M12 12L21 3M12 12L21 21M12 12L3 21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const settings = (
  <svg width="25" height="26" viewBox="0 0 25 26" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M23.7988 15.9151L21.7187 14.7139C21.9287 13.5811 21.9287 12.419 21.7187 11.2862L23.7988 10.085C24.0381 9.94829 24.1455 9.66508 24.0674 9.40141C23.5254 7.66313 22.6025 6.09087 21.3965 4.78227C21.2109 4.58208 20.9082 4.53325 20.6738 4.66997L18.5937 5.87114C17.7197 5.11919 16.7139 4.53813 15.625 4.15727V1.75981C15.625 1.48637 15.4346 1.24712 15.166 1.18852C13.374 0.788132 11.5381 0.807663 9.83398 1.18852C9.56542 1.24712 9.37499 1.48637 9.37499 1.75981V4.16216C8.29101 4.5479 7.28515 5.12895 6.40624 5.87602L4.33105 4.67485C4.09179 4.53813 3.79394 4.58208 3.60839 4.78716C2.40234 6.09087 1.47949 7.66313 0.937494 9.4063C0.854486 9.66997 0.96679 9.95317 1.20605 10.0899L3.28613 11.2911C3.07617 12.4239 3.07617 13.586 3.28613 14.7188L1.20605 15.92C0.96679 16.0567 0.859369 16.3399 0.937494 16.6036C1.47949 18.3418 2.40234 19.9141 3.60839 21.2227C3.79394 21.4229 4.09667 21.4717 4.33105 21.335L6.41113 20.1338C7.28515 20.8858 8.29101 21.4668 9.37988 21.8477V24.25C9.37988 24.5235 9.57031 24.7627 9.83886 24.8213C11.6309 25.2217 13.4668 25.2022 15.1709 24.8213C15.4394 24.7627 15.6299 24.5235 15.6299 24.25V21.8477C16.7139 21.462 17.7197 20.8809 18.5986 20.1338L20.6787 21.335C20.918 21.4717 21.2158 21.4278 21.4014 21.2227C22.6074 19.919 23.5303 18.3467 24.0723 16.6036C24.1455 16.335 24.0381 16.0518 23.7988 15.9151ZM12.5 16.9063C10.3467 16.9063 8.59374 15.1534 8.59374 13C8.59374 10.8467 10.3467 9.0938 12.5 9.0938C14.6533 9.0938 16.4062 10.8467 16.4062 13C16.4062 15.1534 14.6533 16.9063 12.5 16.9063Z"
    />
  </svg>
);

export const refresh = (
  <svg width="30" height="22" viewBox="0 0 30 22" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M24.3333 5.66634L19 10.9997H23C23 13.1214 22.1571 15.1562 20.6568 16.6565C19.1565 18.1568 17.1217 18.9997 15 18.9997C13.6666 18.9997 12.3733 18.6663 11.2666 18.0663L9.31998 20.013C10.96 21.053 12.9066 21.6663 15 21.6663C17.829 21.6663 20.5421 20.5425 22.5425 18.5421C24.5428 16.5418 25.6666 13.8286 25.6666 10.9997H29.6666L24.3333 5.66634ZM6.99998 10.9997C6.99998 8.87794 7.84283 6.84311 9.34313 5.34282C10.8434 3.84253 12.8782 2.99967 15 2.99967C16.3333 2.99967 17.6266 3.33301 18.7333 3.93301L20.68 1.98634C19.04 0.946341 17.0933 0.333008 15 0.333008C12.171 0.333008 9.45789 1.45681 7.45751 3.4572C5.45712 5.45759 4.33331 8.1707 4.33331 10.9997H0.333313L5.66665 16.333L11 10.9997"
    />
  </svg>
);

export const exit = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1583_3055" x="0" y="0" width="18" height="19">
      <g clipPath="url(#clip0_1583_3055)">
        <path
          d="M7.5 17.25H3.75C2.92157 17.25 2.25 16.5784 2.25 15.75V3.75C2.25 2.92157 2.92157 2.25 3.75 2.25H7.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.75 12.75L15.75 9.75L12.75 6.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.75 9.75H6.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </mask>
    <g mask="url(#mask0_1583_3055)">
      <rect y="0.75" width="18" height="18" />
    </g>
  </svg>
);

export const info = (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_111_1354)">
      <path
        // eslint-disable-next-line max-len
        d="M24.6094 13C24.6094 19.6893 19.1874 25.1094 12.5 25.1094C5.81265 25.1094 0.390625 19.6893 0.390625 13C0.390625 6.3146 5.81265 0.890625 12.5 0.890625C19.1874 0.890625 24.6094 6.3146 24.6094 13ZM12.5 15.4414C11.2595 15.4414 10.2539 16.447 10.2539 17.6875C10.2539 18.928 11.2595 19.9336 12.5 19.9336C13.7405 19.9336 14.7461 18.928 14.7461 17.6875C14.7461 16.447 13.7405 15.4414 12.5 15.4414ZM10.3675 7.36787L10.7297 14.0085C10.7467 14.3192 11.0036 14.5625 11.3148 14.5625H13.6852C13.9964 14.5625 14.2533 14.3192 14.2703 14.0085L14.6325 7.36787C14.6508 7.03223 14.3835 6.75 14.0474 6.75H10.9525C10.6164 6.75 10.3492 7.03223 10.3675 7.36787V7.36787Z"
        fill="#17AAD8"
      />
    </g>
    <defs>
      <clipPath id="clip0_111_1354">
        <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const info2 = (
  <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9Z"
      fill="white"
    />
    <path
      // eslint-disable-next-line max-len
      d="M8.66201 5.67002C8.55934 5.67002 8.47534 5.63735 8.41001 5.57202C8.34468 5.50669 8.31201 5.42269 8.31201 5.32002V4.06002C8.31201 3.95735 8.34468 3.87335 8.41001 3.80802C8.47534 3.74269 8.55934 3.71002 8.66201 3.71002H10.342C10.4447 3.71002 10.5287 3.74269 10.594 3.80802C10.6593 3.87335 10.692 3.95735 10.692 4.06002V5.32002C10.692 5.42269 10.6593 5.50669 10.594 5.57202C10.5287 5.63735 10.4447 5.67002 10.342 5.67002H8.66201ZM8.67601 14C8.57334 14 8.48934 13.9673 8.42401 13.902C8.35868 13.8367 8.32601 13.7527 8.32601 13.65V7.07002C8.32601 6.96735 8.35868 6.88335 8.42401 6.81802C8.48934 6.75269 8.57334 6.72002 8.67601 6.72002H10.328C10.4307 6.72002 10.5147 6.75269 10.58 6.81802C10.6453 6.88335 10.678 6.96735 10.678 7.07002V13.65C10.678 13.7433 10.6453 13.8273 10.58 13.902C10.5147 13.9673 10.4307 14 10.328 14H8.67601Z"
      fill="#8A79FF"
    />
  </svg>
);

export const hamburger = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H21" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6H21" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 18H21" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const hamburgerClose = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.63599 18.364L18.3639 5.63603"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3639 18.364L5.63597 5.63603"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ton = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="12" fill="#050B2F" />
    <path d="M9.28125 6L3.84375 11.4375H12.5625V20.3438L18 14.9062V6H9.28125Z" fill="#C4E4F3" />
  </svg>
);

export const metamask = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_221_6043)">
      <path
        d="M22.8046 1L13.4399 7.95529L15.1717 3.85176L22.8046 1Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.18604 1L10.4754 8.02117L8.82838 3.85176L1.18604 1Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4355 17.1226L16.9414 20.9437L22.2779 22.412L23.812 17.2073L19.4355 17.1226Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.197754 17.2073L1.72246 22.412L7.05892 20.9437L4.56481 17.1226L0.197754 17.2073Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75805 10.666L5.271 12.9154L10.5698 13.1507L10.3816 7.45654L6.75805 10.666Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2329 10.6659L13.5623 7.39062L13.4399 13.1506L18.7293 12.9153L17.2329 10.6659Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05908 20.9434L10.2403 19.3905L7.49202 17.2446L7.05908 20.9434Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.751 19.3905L16.9416 20.9434L16.4992 17.2446L13.751 19.3905Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9411 20.9436L13.7505 19.3906L14.0046 21.4706L13.9764 22.3459L16.9411 20.9436Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05859 20.9436L10.0233 22.3459L10.0045 21.4706L10.2398 19.3906L7.05859 20.9436Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0706 15.8706L7.4165 15.0894L9.28944 14.2329L10.0706 15.8706Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9199 15.8706L14.7011 14.2329L16.5834 15.0894L13.9199 15.8706Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05906 20.9432L7.51082 17.1221L4.56494 17.2068L7.05906 20.9432Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4897 17.1221L16.9415 20.9432L19.4356 17.2068L16.4897 17.1221Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7293 12.915L13.4399 13.1503L13.9294 15.8703L14.7105 14.2327L16.5929 15.0892L18.7293 12.915Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.41688 15.0892L9.29923 14.2327L10.071 15.8703L10.5698 13.1503L5.271 12.915L7.41688 15.0892Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.27051 12.915L7.49168 17.2444L7.41639 15.0892L5.27051 12.915Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5931 15.0892L16.499 17.2444L18.7296 12.915L16.5931 15.0892Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5691 13.1504L10.0703 15.8704L10.6915 19.0798L10.8327 14.8539L10.5691 13.1504Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4397 13.1504L13.1855 14.8445L13.2985 19.0798L13.9291 15.8704L13.4397 13.1504Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9294 15.8705L13.2988 19.0799L13.7506 19.3905L16.4988 17.2446L16.5929 15.0894L13.9294 15.8705Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4165 15.0894L7.4918 17.2446L10.24 19.3905L10.6918 19.0799L10.0706 15.8705L7.4165 15.0894Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.9762 22.3462L14.0045 21.4709L13.7692 21.2638H10.2209L10.0045 21.4709L10.0233 22.3462L7.05859 20.9438L8.09389 21.7909L10.1927 23.2497H13.7974L15.9056 21.7909L16.9409 20.9438L13.9762 22.3462Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.7503 19.3907L13.2985 19.0801H10.6915L10.2397 19.3907L10.0044 21.4707L10.2209 21.2636H13.7691L14.0044 21.4707L13.7503 19.3907Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M23.1999 8.40705L23.9999 4.56705L22.8046 1L13.7505 7.71999L17.2328 10.6659L22.1552 12.1059L23.2469 10.8353L22.7764 10.4965L23.5293 9.8094L22.9458 9.35764L23.6987 8.78352L23.1999 8.40705Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M0 4.56705L0.799999 8.40705L0.291765 8.78352L1.0447 9.35764L0.470588 9.8094L1.22353 10.4965L0.75294 10.8353L1.83529 12.1059L6.75764 10.6659L10.24 7.71999L1.18588 1L0 4.56705Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1555 12.106L17.2331 10.666L18.7296 12.9154L16.499 17.2448L19.4355 17.2072H23.812L22.1555 12.106Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75775 10.666L1.8354 12.106L0.197754 17.2072H4.56481L7.49186 17.2448L5.27069 12.9154L6.75775 10.666Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.4399 13.1509L13.7505 7.72028L15.1811 3.85205H8.82812L10.2399 7.72028L10.5693 13.1509L10.6822 14.8638L10.6917 19.0803H13.2987L13.3175 14.8638L13.4399 13.1509Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0941175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_221_6043">
        <rect width="24" height="24" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export const walletConnect = (
  <svg xmlns="http://www.w3.org/2000/svg" width="300px" height="185px" viewBox="0 0 300 185" version="1.1">
    <title>WalletConnect</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="walletconnect-logo-alt" fill="#3B99FC" fillRule="nonzero">
        <path
          // eslint-disable-next-line max-len
          d="M61.4385429,36.2562612 C110.349767,-11.6319051 189.65053,-11.6319051 238.561752,36.2562612 L244.448297,42.0196786 C246.893858,44.4140867 246.893858,48.2961898 244.448297,50.690599 L224.311602,70.406102 C223.088821,71.6033071 221.106302,71.6033071 219.883521,70.406102 L211.782937,62.4749541 C177.661245,29.0669724 122.339051,29.0669724 88.2173582,62.4749541 L79.542302,70.9685592 C78.3195204,72.1657633 76.337001,72.1657633 75.1142214,70.9685592 L54.9775265,51.2530561 C52.5319653,48.8586469 52.5319653,44.9765439 54.9775265,42.5821357 L61.4385429,36.2562612 Z M280.206339,77.0300061 L298.128036,94.5769031 C300.573585,96.9713 300.573599,100.85338 298.128067,103.247793 L217.317896,182.368927 C214.872352,184.763353 210.907314,184.76338 208.461736,182.368989 C208.461726,182.368979 208.461714,182.368967 208.461704,182.368957 L151.107561,126.214385 C150.496171,125.615783 149.504911,125.615783 148.893521,126.214385 C148.893517,126.214389 148.893514,126.214393 148.89351,126.214396 L91.5405888,182.368927 C89.095052,184.763359 85.1300133,184.763399 82.6844276,182.369014 C82.6844133,182.369 82.684398,182.368986 82.6843827,182.36897 L1.87196327,103.246785 C-0.573596939,100.852377 -0.573596939,96.9702735 1.87196327,94.5758653 L19.7936929,77.028998 C22.2392531,74.6345898 26.2042918,74.6345898 28.6498531,77.028998 L86.0048306,133.184355 C86.6162214,133.782957 87.6074796,133.782957 88.2188704,133.184355 C88.2188796,133.184346 88.2188878,133.184338 88.2188969,133.184331 L145.571,77.028998 C148.016505,74.6345347 151.981544,74.6344449 154.427161,77.028798 C154.427195,77.0288316 154.427229,77.0288653 154.427262,77.028899 L211.782164,133.184331 C212.393554,133.782932 213.384814,133.782932 213.996204,133.184331 L271.350179,77.0300061 C273.79574,74.6355969 277.760778,74.6355969 280.206339,77.0300061 Z"
          id="WalletConnect"
        />
      </g>
    </g>
  </svg>
);

export const globe = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 6.97715 18.0228 2.5 12.5 2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.5 12.5H22.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M12.5 2.5C15.0013 5.23835 16.4228 8.79203 16.5 12.5C16.4228 16.208 15.0013 19.7616 12.5 22.5C9.99872 19.7616 8.57725 16.208 8.5 12.5C8.57725 8.79203 9.99872 5.23835 12.5 2.5V2.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const tooltipIcon = (
  <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" />
    <path
      // eslint-disable-next-line max-len
      d="M8.162 5.67C8.05933 5.67 7.97533 5.63733 7.91 5.572C7.84467 5.50667 7.812 5.42267 7.812 5.32V4.06C7.812 3.95733 7.84467 3.87333 7.91 3.808C7.97533 3.74267 8.05933 3.71 8.162 3.71H9.842C9.94467 3.71 10.0287 3.74267 10.094 3.808C10.1593 3.87333 10.192 3.95733 10.192 4.06V5.32C10.192 5.42267 10.1593 5.50667 10.094 5.572C10.0287 5.63733 9.94467 5.67 9.842 5.67H8.162ZM8.176 14C8.07333 14 7.98933 13.9673 7.924 13.902C7.85867 13.8367 7.826 13.7527 7.826 13.65V7.07C7.826 6.96733 7.85867 6.88333 7.924 6.818C7.98933 6.75267 8.07333 6.72 8.176 6.72H9.828C9.93067 6.72 10.0147 6.75267 10.08 6.818C10.1453 6.88333 10.178 6.96733 10.178 7.07V13.65C10.178 13.7433 10.1453 13.8273 10.08 13.902C10.0147 13.9673 9.93067 14 9.828 14H8.176Z"
      fill="white"
    />
  </svg>
);

type FixIconWrapperProps = {
  src?: string;
};
const FixIconWrapper = ({ src }: FixIconWrapperProps) => (
  <div
    className={st['fix-icon-wrapper']}
    style={
      src
        ? {
            backgroundImage: `url("${src}")`,
          }
        : {}
    }
  />
);

export const totalSalesIcon = <FixIconWrapper src={totalSalesIconPng} />;

export const participantsIcon = <FixIconWrapper src={participantsIconPng} />;

const noProjectsIcon = (
  <svg width="60" height="61" viewBox="0 0 60 61" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M53.3333 0.5H6.66667C3 0.5 0 3.5 0 7.16667V53.8333C0 57.5 2.96667 60.5 6.66667 60.5H53.3333C57 60.5 60 57.5 60 53.8333V7.16667C60 3.5 57 0.5 53.3333 0.5ZM53.3333 53.8333H6.66667V43.8333H18.5333C20.8333 47.8 25.1 50.5 30.0333 50.5C34.9667 50.5 39.2 47.8 41.5333 43.8333H53.3333V53.8333ZM53.3333 37.1667H36.7C36.7 40.8333 33.7 43.8333 30.0333 43.8333C26.3667 43.8333 23.3667 40.8333 23.3667 37.1667H6.66667V7.16667H53.3333V37.1667Z"
    />
  </svg>
);

const connectWalletIcon = (
  <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M41.0523 35.4651C43.6684 35.4651 45.7891 33.3444 45.7891 30.7283C45.7891 28.1122 43.6684 25.9915 41.0523 25.9915C38.4362 25.9915 36.3154 28.1122 36.3154 30.7283C36.3154 33.3444 38.4362 35.4651 41.0523 35.4651Z"
    />
    <path
      // eslint-disable-next-line max-len
      d="M56.8421 15.8229V8.62292C56.8421 5.14923 54 2.30713 50.5263 2.30713H6.31579C2.81053 2.30713 0 5.14923 0 8.62292V52.8334C0 56.3071 2.81053 59.1492 6.31579 59.1492H50.5263C54 59.1492 56.8421 56.3071 56.8421 52.8334V45.6334C58.7053 44.5282 60 42.5387 60 40.2019V21.2545C60 18.9177 58.7053 16.9282 56.8421 15.8229ZM53.6842 21.2545V40.2019H31.5789V21.2545H53.6842ZM6.31579 52.8334V8.62292H50.5263V14.9387H31.5789C28.1053 14.9387 25.2632 17.7808 25.2632 21.2545V40.2019C25.2632 43.6756 28.1053 46.5177 31.5789 46.5177H50.5263V52.8334H6.31579Z"
    />
  </svg>
);

export const notificationIcons = {
  noProjectsIcon,
  connectWalletIcon,
};

export const book = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    style={{ background: 'black' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2 0C0.895431 0 0 0.89543 0 2V11C0 12.1046 0.89543 13 2 13H11C12.1046 13 13 12.1046 13 11V2C13 0.895431 12.1046 0 11 0H2ZM7.5 5C6.67157 5 6 5.67157 6 6.5C6 7.32843 6.67157 8 7.5 8H9.5C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5H7.5Z"
      fill="white"
    />
  </svg>
);

export const checked = (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M1 16.0039C1 7.71964 7.71573 1.00391 16 1.00391C24.2843 1.00391 31 7.71964 31 16.0039C31 24.2882 24.2843 31.0039 16 31.0039C7.71573 31.0039 1 24.2882 1 16.0039Z"
      stroke="#276EF5"
      strokeWidth="2"
    />
    <path d="M10.5 15.5039L14.5 19.5039L22 12.0039" stroke="white" strokeWidth="3" />
  </svg>
);

export const cancel = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M1 16.0039C1 7.71964 7.71573 1.00391 16 1.00391C24.2843 1.00391 31 7.71964 31 16.0039C31 24.2882 24.2843 31.0039 16 31.0039C7.71573 31.0039 1 24.2882 1 16.0039Z"
      stroke="#E64082"
      strokeWidth="2"
    />
    <path d="M11 22.0039L14.5 18.5039L22 11.0039" stroke="white" strokeWidth="3" />
    <path d="M22 22.0039L18.5 18.5039L11 11.0039" stroke="white" strokeWidth="3" />
  </svg>
);
