/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import ReactDOM from 'react-dom';
import st from './style.module.css';

export type ModalProps = {
  setIsShow?: (bool: boolean) => void;
  children: JSX.Element;
  inWithdrawal?: boolean;
};

const Modal = ({ children, setIsShow = () => undefined, inWithdrawal }: ModalProps) => {
  const height100vh = use100vh();

  useEffect(() => {
    const rootElement = document.querySelector('#root');
    rootElement?.classList.toggle('page-blur');

    return () => {
      rootElement?.classList.toggle('page-blur');
    };
  }, [setIsShow]);

  return ReactDOM.createPortal(
    <div className={st.backdrop} style={{ height: height100vh || '100vh' }} onClick={() => setIsShow(false)}>
      <div className={st.box}>
        <div
          className={`${st.wrapper} ${inWithdrawal ? st.withdrawal : ''}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
