import React, { createContext, useState } from 'react';
import { isMobile as deviceDetectedAsMobile } from 'react-device-detect';

type Context = {
  isMobileMenuShow: any;
  setIsMobileMenuShow: (bool: boolean) => void;
  isMobile: boolean;
  setIsMobile: (bool: boolean) => void;
  htmlWidth: number;
  setHtmlWidth: (n: number) => void;
  mobileWidth: number;
  setMobileWidth: (n: number) => void;
  headerWidth: number;
  setHeaderWidth: (n: number) => void;
};

export const Context = createContext<Context>({
  isMobileMenuShow: deviceDetectedAsMobile,
  setIsMobileMenuShow: () => undefined,
  isMobile: deviceDetectedAsMobile,
  setIsMobile: () => undefined,
  htmlWidth: 0,
  setHtmlWidth: () => undefined,
  mobileWidth: 0,
  setMobileWidth: () => undefined,
  headerWidth: 0,
  setHeaderWidth: () => undefined,
});

const Provider: React.FC = ({ children }) => {
  const [isMobileMenuShow, setIsMobileMenuShow] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(deviceDetectedAsMobile);
  const [htmlWidth, setHtmlWidth] = useState(0);
  const [mobileWidth, setMobileWidth] = useState(0);
  const [headerWidth, setHeaderWidth] = useState(2);
  const contextValue = {
    isMobileMenuShow,
    setIsMobileMenuShow: (bool: boolean) => setIsMobileMenuShow(bool),
    isMobile,
    setIsMobile: (bool: boolean) => {
      if (!deviceDetectedAsMobile) setIsMobile(bool);
    },
    htmlWidth,
    setHtmlWidth,
    mobileWidth,
    setMobileWidth,
    headerWidth,
    setHeaderWidth,
  };
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default Provider;
