/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'dragscroll';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { StatusEnum } from '../status/Status';
import st from './style.module.css';

export type TabsStatusEnum = StatusEnum | 'Participated';

export type TabsProps = {
  options: TabsStatusEnum[];
  currentOption: TabsStatusEnum;
  setCurrentOption: (options: TabsStatusEnum) => void;
};

const Tabs = ({ options, setCurrentOption, currentOption }: TabsProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const location = useLocation();

  if (location.pathname.includes('404')) {
    return null;
  }

  return (
    <div className={st.wrapper}>
      <div className={`${st.main}`}>
        <ul ref={ref} className={st.list}>
          <span className={st.subheader}>Projects</span>
          {options.map((option) => (
            <li key={option} className={`${st.item} ${option === currentOption ? st.is__active : ''}`}>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={st['clickable-area']}
                onClick={(e) => {
                  setCurrentOption(option);
                }}
              >
                {option}
                <div className={st.line} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
