import BigNumber from 'bignumber.js';
import { AbiItem } from 'web3-utils';
import { getCreditorFactoryAddressHex } from './get-assets';
import { getErc20Approve } from './web3';
import { networkOptionsMap, Networks } from '../utils/get-networks';
import VaultAbi from '../abi/erc20/Vault.abi.json';
import { getPolygonNetworkGasGWei } from './gasstation';
import { CONTRACT_FEE, EVER_WALLET_DEPLOY_FEE } from '../constants/marketConstants';
import { useEvmWallet } from '../services/EvmWalletService';

const minSlippage = {
  numerator: '10',
  denominator: '1000',
};

type Bridge = {
  chainId: number;
  amount: string;
  evmDecimals: number;
  evmWallet: string;
  user: string;
  recipient: string;
  cell: string;
  vault: string;
  onTransactionHash: (transactionHash: string) => void;
  maxBridgeFee: BigNumber | null;
  tip3Decimals: number;
  isLessThan10EversOnAccount: boolean;
};
export const bridge = async ({
  chainId,
  amount,
  evmDecimals,
  evmWallet: userAddress,
  user,
  recipient,
  cell,
  vault,
  onTransactionHash,
  maxBridgeFee,
  tip3Decimals,
  isLessThan10EversOnAccount,
}: Bridge): Promise<boolean | null> => {
  const { web3 } = useEvmWallet();
  if (!web3) return false;

  const contract = new web3.eth.Contract(VaultAbi as unknown as AbiItem, vault);
  if (!contract) return null;

  const creditorFactoryAddressHex = await getCreditorFactoryAddressHex();
  if (!creditorFactoryAddressHex || !maxBridgeFee) return null;

  const amountWithDecimals = new BigNumber(amount)
    .plus(maxBridgeFee)
    .shiftedBy(evmDecimals)
    .dp(0, BigNumber.ROUND_DOWN)
    .toFixed();

  const tokenAmount = new BigNumber(amount).shiftedBy(tip3Decimals).dp(0, BigNumber.ROUND_DOWN).toFixed();

  let gasPrice: string | undefined;

  if (networkOptionsMap[chainId].value === Networks.polygonMainnet) {
    const { fast } = await getPolygonNetworkGasGWei();
    gasPrice = String(Math.round(fast * 10 ** 9)); // gWei to Wei
  }

  const approve = await getErc20Approve({
    amount: amountWithDecimals,
    evmWallet: userAddress,
    vault,
    transactionType: chainId === 1 ? '0x2' : '0x0',
    chainId,
    gasPrice,
  });

  if (approve) {
    await contract.methods
      .depositToFactory(
        /** amount */
        amountWithDecimals,

        /** FreeTON workchainId */
        '0',

        /** user */
        `0x${user.split(':')[1]}`,

        // ОСТАВИТЬ БЕЗ ИЗМЕНЕНИЙ
        // Это контракт CreditFactory, который дает 6 TON "в кредит" для перехода.
        // Из них примерно ~2.5 ton тратится на газ
        // eslint-disable-next-line max-len
        // Этот кредит гасится автоматически из суммы перевода, оставшийся газ будет передан в IReceiveTONsFromBridgeCallback(recipient).onReceiveTONsFromBridgeCallback(...)
        `0x${creditorFactoryAddressHex}`,

        /** recipient */
        `0x${recipient.split(':')[1]}`,

        /** tokenAmount */
        tokenAmount,

        // tonAmount
        isLessThan10EversOnAccount
          ? (CONTRACT_FEE.nanoEVER + EVER_WALLET_DEPLOY_FEE.nanoEVER).toString()
          : CONTRACT_FEE.nanoEVER.toString(),

        // swapType
        '1',

        // slippage 1% - это не slippage всей операции, а slippage для попыток обмена внутри контракта.
        minSlippage.numerator,
        minSlippage.denominator,
        `0x${Buffer.from(cell, 'base64').toString('hex')}`
      )
      .send({
        from: userAddress,
        gasPrice,
      })
      .once('transactionHash', async (transactionHash: string) => {
        // eslint-disable-next-line no-console
        console.log(`transactionHash: ${transactionHash}`);
        setTimeout(() => onTransactionHash(transactionHash), 2000);
      });
    return true;
  }
  return false;
};
