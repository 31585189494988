import { Address, ProviderRpcClient, Subscriber, Transaction } from 'everscale-inpage-provider';

let ton: ProviderRpcClient | undefined;
try {
  ton = new ProviderRpcClient();
  // eslint-disable-next-line no-empty
} catch (error) {}

type ReturnFromTransactions = {
  isGoodAnswer?: boolean;
  transactionData?: any;
};
type Data = { transactions: Pick<Transaction<Address>, 'inMessage'>[] };
export const subscribeOnX = async ({
  x,
  onData,
  promiseFn,
  checkAllTransactions,
}: {
  x: string;
  onData: (data: Data) => Promise<ReturnFromTransactions>;
  promiseFn: () => Promise<boolean>;
  checkAllTransactions?: () => Promise<Pick<Transaction<Address>, 'inMessage'>[] | null>;
}): Promise<ReturnFromTransactions | null> => {
  if (!ton) return null;
  const subscriber = new Subscriber(ton);

  let isGoodAnswer: boolean | undefined;
  let transactionData: any;
  const onIsGoodAnswer = ({ isGoodAnswer: _, transactionData: __ }: ReturnFromTransactions) => {
    isGoodAnswer = _;
    transactionData = __;
  };

  const getTransactionPromise = async () => {
    const transactionPromise = new Promise<ReturnFromTransactions>((resolve) => {
      const id = setInterval(() => {
        if (typeof isGoodAnswer === 'boolean') {
          clearInterval(id);
          resolve({
            isGoodAnswer,
            transactionData,
          });
        }
      }, 100);
    });
    return transactionPromise;
  };

  const makeProducerOnData = async (data: Data) => {
    const _ = await onData(data);
    onIsGoodAnswer(_);
  };

  subscriber.transactions(new Address(x)).makeProducer(makeProducerOnData, () => undefined);

  if (checkAllTransactions) {
    const transactions = await checkAllTransactions();
    await makeProducerOnData({
      transactions: transactions || [],
    });
  }

  const bool = await promiseFn();
  if (!bool) return null;

  return getTransactionPromise();
};
