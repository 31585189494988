import { LAUNCH_POOL_FACTORY } from '../constants/marketConstants';
import { getLaunchPoolAddress } from './utils';
import { tonClientRunLocal } from './ton-labs';
import LaunchPoolAbi from '../abi/ton/LaunchPool.abi.json';

export const getUserDepositLimit = async (poolNumber: number): Promise<string> => {
  try {
    const { launchPoolAddress } = await getLaunchPoolAddress({
      address: LAUNCH_POOL_FACTORY,
      poolNumber: String(poolNumber),
    });
    if (!launchPoolAddress) return '0';

    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolAbi,
      },
      launchPoolAddress,
      {
        function_name: 'getDepositLimit',
        input: {},
      }
    );
    return (output?.value0 as string) || '0';
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return '0';
  }
};
