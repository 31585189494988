import React, { useRef } from 'react';
import st from './common.module.css';
import input from './input.module.css';

type Types = 'number' | 'text' | 'wallet-address';

export type InputProps = {
  value: string;
  setValue: (value: string) => void;
  fixTo?: number;
  type?: Types;
  isError?: boolean;
};
const Input = ({ value, setValue, fixTo = 0, type = 'text', isError }: InputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const onTextInputChange = (targetValue: string) => {
    setValue(targetValue);
  };

  const onWalletAddressInputChange = (targetValue: string) => {
    // todo
    setValue(targetValue);
  };

  const onNumberInputChange = (targetValue: string) => {
    const regexp = new RegExp(`^[0-9]*[.,]?[0-9]{0,${fixTo}}$`);
    if (targetValue === '') {
      setValue('0');
    } else if (targetValue.match(regexp) !== null) {
      const newValue = targetValue?.includes('.')
        ? targetValue.slice(0, targetValue.indexOf('.') + 1).concat(
            targetValue
              .slice(targetValue.indexOf('.') + 1)
              .replace(',', '')
              .replace('.', '')
          )
        : targetValue.replace(',', '.');
      const x = newValue.replace(/^0+/, '0');
      // eslint-disable-next-line no-nested-ternary
      const y = x.length > 1 ? (x[0] === '0' ? x.slice(1) || x : x) : x;

      setValue(y.match(/^\.(.*)/) ? y.replace(/^\./, '0.') : y);
    }
  };

  return (
    <div className={input['input-wrapper']}>
      <input
        ref={ref}
        type="text"
        className={`${st.select} ${`${st['plain-input']} ${isError ? st.error : ''}`}`}
        value={value}
        onChange={(e) => {
          const { value: targetValue } = e.target;
          switch (type) {
            case 'number':
              onNumberInputChange(targetValue);
              break;
            case 'wallet-address':
              onWalletAddressInputChange(targetValue);
              break;
            case 'text':
            default:
              onTextInputChange(targetValue);
              break;
          }
        }}
      />
    </div>
  );
};

export default Input;
