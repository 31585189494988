/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TONWalletContext } from '../../../providers/TONWalletProvider';
import { MetaMaskContext } from '../../../providers/MetaMaskProvider';
import useTONCrystalWallet from '../../../utils/hooks/useTONCrystalWallet';
import { Button } from '../../button';
import { InstallPopup } from '../../install-popup';
import { Modal } from '../../modal';
import { book, ton, metamask, dropDown, walletConnect } from '../../../common/icons';
import st from '../style.module.css';
import { Line } from '../../line';
import { WalletButton } from './WalletButton';
import wm from './wallets-manager.module.css';
import { Context } from '../../../providers/Provider';
import { useEvmWallet } from '../../../services/EvmWalletService';

type ButtonBoxProps = {
  isInvert?: boolean;
  inMobileMenu?: boolean;
};

export const WalletsManager = ({ isInvert = true, inMobileMenu = false }: ButtonBoxProps) => {
  const evmWallet = useEvmWallet();
  const { accountInteraction, isLogin, isInstall: isEverwalletInstalled } = useContext(TONWalletContext);
  const { isMobile } = useContext(Context);

  const { connect: connectEverwallet, disconnect: disconnectEverwallet } = useTONCrystalWallet();

  const [isComboboxOpen, setIsComboboxOpen] = useState(false);
  const [isPopupShow, setIsPopupShow] = useState(false);

  const navRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event && navRef.current && !navRef.current.contains(event.target)) {
        setTimeout(() => setIsComboboxOpen(false), 0);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [navRef]);

  useEffect(() => setIsComboboxOpen(false), [isLogin, evmWallet.address]);

  const isLoggedIn = isLogin || !!evmWallet.address;

  return (
    <nav ref={navRef} className={`${st['buttons-box']} ${inMobileMenu ? st.mobile : ''}`}>
      <Button
        width={isMobile ? '240px' : ''}
        isCentred
        isInvert={isLoggedIn}
        onClick={() => setIsComboboxOpen(!isComboboxOpen)}
        className={wm.icon}
        text={
          <span className={isLoggedIn ? st['sign-out'] : st['sign-in']}>
            <span className={st.icon}>{book}</span>
            <span className={st.text}>{isLoggedIn ? 'Manage wallets' : 'Sign via wallet'}</span>
            {isLoggedIn && (
              <span className={isComboboxOpen ? wm['dropdown-open'] : wm['dropdown-close']}>{dropDown}</span>
            )}
          </span>
        }
      />
      {isComboboxOpen && (
        <div className={wm.wrapper}>
          <WalletButton
            address={accountInteraction?.address.toString()}
            icon={ton}
            isInstalled={!!isEverwalletInstalled}
            isConnected={!!isLogin}
            connectWallet={connectEverwallet}
            disconnectWallet={disconnectEverwallet}
            walletName="Connect EverWallet"
            isEverwallet
          />
          <Line />
          <WalletButton
            address={evmWallet.address}
            icon={evmWallet.isMetaMask ? metamask : walletConnect}
            isInstalled={evmWallet.isInitialized}
            isConnected={evmWallet.isConnected}
            connectWallet={evmWallet.connect}
            disconnectWallet={evmWallet.disconnect}
            walletName={evmWallet.isMetaMask ? 'Connect MetaMask' : 'WalletConnect'}
          />
          {isPopupShow && (
            <Modal setIsShow={setIsPopupShow}>
              <InstallPopup onCancel={() => setIsPopupShow(false)} />
            </Modal>
          )}
        </div>
      )}
    </nav>
  );
};
