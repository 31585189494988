import { CommonSmallCardProps, SmallCardProps, UserInvestSmallCardProps } from '../components/small-card';
import { StatusEnum } from '../components/status/Status';
import { LAUNCH_POOL_FACTORY } from '../constants/marketConstants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EmptyPlaceholder from './EmptyPlaceholder.svg';
import { getLaunchPoolAddress, getProjectDetails, getTokenDetails, getUserDetails } from './utils';
import {
  buildPaymentPayload,
  getLaunchPoolParticipationAddress,
  LaunchPoolStatus,
  LaunchPoolStatusEnum,
  MatchingStatuses,
  SocialLink,
} from './utils/LaunchPool';

export type ProjectType = Omit<CommonSmallCardProps, 'isEmbed' | 'children'> &
  SmallCardProps & {
    poolNumber: number;
    socialLinks: SocialLink[];
    launchPoolAddress: string;
  };

export type GetProject = {
  poolNumber: number;
  userAddress?: string;
};

export const getProject = async ({ poolNumber, userAddress }: GetProject): Promise<ProjectType | null> => {
  const { launchPoolAddress } = await getLaunchPoolAddress({
    address: LAUNCH_POOL_FACTORY,
    poolNumber: String(poolNumber),
  });
  if (!launchPoolAddress) return null;

  // eslint-disable-next-line no-bitwise
  const callId = ((Math.random() * 100000) | 0).toString();

  let cell = null;

  if (userAddress) {
    const { cell: cellInfo } = await buildPaymentPayload({
      launchPoolAddress,
      userAddress,
      callId,
    });
    cell = cellInfo;
  }

  if (userAddress && !cell) return null;

  const details = await getProjectDetails({
    address: launchPoolAddress,
  });
  if (!details) return null;

  const status = MatchingStatuses[LaunchPoolStatus[+details.state] as LaunchPoolStatusEnum] as StatusEnum;

  const tokenCurrency = {
    symbol: 'unknown token',
    decimals: 0,
    launchTokenRoot: details.launchTokenRoot,
  };

  if (details?.launchTokenRoot) {
    const tokenDetails = await getTokenDetails({
      address: details.launchTokenRoot,
    });
    if (tokenDetails) {
      const { symbol, decimals } = tokenDetails;
      tokenCurrency.symbol = symbol;
      tokenCurrency.decimals = +decimals || 0;
    }
  }

  const mainCurrency = {
    symbol: 'unknown token',
    decimals: 0,
    launchTokenRoot: details.paymentTokenRoot,
  };

  if (details?.paymentTokenRoot) {
    const tokenDetails = await getTokenDetails({
      address: details.paymentTokenRoot,
    });
    if (tokenDetails) {
      const { symbol, decimals } = tokenDetails;
      mainCurrency.symbol = symbol;
      mainCurrency.decimals = +decimals || 0;
    }
  }

  const filteredProjectDescription = details?.additionalProjectInfo?.projectDescription?.replaceAll('\n', '<br />');

  const logoUrl = details?.additionalProjectInfo?.projectImageUrl || EmptyPlaceholder;

  return {
    owner: details.owner,
    cell: cell || '',
    callId,
    launchTokenRoot: details.launchTokenRoot,
    paymentTokenRoot: details.paymentTokenRoot,
    amountOfInvestors: +details?.amountOfInvestors || 0,
    totalRaised: +details?.totalRaised || 0, // Total Sales
    totalWithdraw: +details?.totalWithdraw || 0,
    startTime: +details?.startTime,
    endTime: +details?.endTime,
    launchPoolAddress,
    mainCurrency,
    tokenCurrency,
    poolNumber,
    socialLinks: details?.additionalProjectInfo?.projectSocialLinks || [],
    linkTo: details?.additionalProjectInfo?.projectLandingUrl || '',
    logoUrl: logoUrl || '',
    status: Date.now() < +details?.startTime * 1000 ? 'Upcoming' : status,
    title: details?.additionalProjectInfo?.projectName || '',
    text: filteredProjectDescription || '',
    tokenForSale: +(details?.tokensSupply || '0'),
    softcap: +(details?.softCap || '0'),
    hardcap: +(details?.hardCap || '0'),
    tokensSupply: +(details?.tokensSupply || '0'),
    vestingPeriods: details?.vestingPeriods || [],
  };
};

export type ReducedProjectType = UserInvestSmallCardProps & {
  poolNumber: number;
};
export const getUserDataForProject = async ({
  project,
  userAccountAddress,
}: {
  project: ProjectType;
  userAccountAddress: string;
}): Promise<ReducedProjectType | null> => {
  const { launchPoolAddress } = await getLaunchPoolAddress({
    address: LAUNCH_POOL_FACTORY,
    poolNumber: String(project.poolNumber),
  });
  if (!launchPoolAddress) return null;

  const { launchPoolParticipationAddress } = await getLaunchPoolParticipationAddress({
    address: launchPoolAddress,
    userAddress: userAccountAddress,
  });
  if (!launchPoolParticipationAddress) return null;

  const details = launchPoolParticipationAddress
    ? await getUserDetails({
        address: launchPoolParticipationAddress,
      })
    : null;

  if (!details) return null;

  const userFullAllocation =
    details?.depositAmount && project?.totalRaised && project?.tokensSupply
      ? Math.floor((project.tokensSupply * +details.depositAmount) / project.totalRaised)
      : 0;

  const getSum = ({ claimFrom, claimTo }: { claimFrom: Date; claimTo: Date }) => {
    if (claimFrom && claimTo && project?.vestingPeriods) {
      const percent = project.vestingPeriods
        .filter((period) => +period.unfreezeTime * 1000 > +claimFrom && +period.unfreezeTime * 1000 <= +claimTo)
        .reduce((sum, period) => sum + +period.percent, 0);

      return Math.floor((+userFullAllocation * percent) / 10000);
    }
    return 0;
  };

  // eslint-disable-next-line no-underscore-dangle
  let _return = 0;

  if (details?.depositAmount && project?.totalRaised && project?.softcap && project?.hardcap) {
    if (project.totalRaised < project.softcap) {
      _return = (!details.depositReturned && +details?.depositAmount) || 0;
    }
    if (project.totalRaised > project.hardcap) {
      _return =
        (!details.sulpurReturned &&
          Math.floor((+details.depositAmount * (project.totalRaised - project.hardcap)) / project.totalRaised)) ||
        0;
    }
  }

  const periods = (project.status !== 'Failed' ? project.vestingPeriods : []).map((period, i, arr) => ({
    amount: getSum({
      claimFrom: new Date(i ? +arr[i - 1].unfreezeTime * 1000 + 1 : 0),
      claimTo: new Date(+period.unfreezeTime * 1000),
    }),
    unfreezeTime: +period.unfreezeTime,
  }));

  return {
    periods,
    launchPoolParticipationAddress,
    poolNumber: project.poolNumber,
    youInvested: +details?.depositAmount || 0,
    appreciated: userFullAllocation,
    appreciatedPercent: +((userFullAllocation / project.tokensSupply) * 100).toFixed(),
    accessible: getSum({
      claimFrom: new Date(+details.lastClaim * 1000 + 1),
      claimTo: new Date(),
    }),
    received: getSum({
      claimFrom: new Date(0),
      claimTo: new Date(+details.lastClaim * 1000),
    }),
    _return,
  };
};
