/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ton, metamask } from '../../common/icons';
import { links } from '../../constants/links';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import useTONCrystalWallet from '../../utils/hooks/useTONCrystalWallet';
import { networkOptionsMap } from '../../utils/get-networks';
import { Button } from '../button';
import Subinvest, { SubinvestProps } from './subinvest';
import st from './extensions-manager.module.css';
import { useEvmWallet } from '../../services/EvmWalletService';

type Network = 'free-ton' | 'meta-mask';
export type ExtensionsManagerProps = {
  network: Network;
  setIsGood: (bool: boolean) => void;
  networkChainId: number | undefined;
};
const ExtensionsManager = observer(({ setIsGood, networkChainId }: ExtensionsManagerProps) => {
  const [isEverwalletInstalled, setIsEverwalletInstalled] = useState(false);
  const [isEverwalletConnected, setIsEverwalletConnected] = useState(false);

  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);

  const [isCurrentEverwalletNetwork, setIsCurrentEverwalletNetwork] = useState(false);
  const [isCurrentMetamaskNetwork, setIsCurrentMetamaskNetwork] = useState(false);

  const {
    isInstall: isTONWalletInstall,
    isLogin: isTONWalletLogin,
    isMainnet,
    accountInteraction,
  } = useContext(TONWalletContext);

  const { connect: TONWalletConnect } = useTONCrystalWallet();
  const {
    connect: metaMaskConnect,
    changeNetwork: switchChain,
    isInitialized: isMetaMaskInstall,
    address: account,
    chainId,
  } = useEvmWallet();

  useEffect(() => {
    setIsEverwalletInstalled(!!isTONWalletInstall);
    setIsEverwalletConnected(!!isTONWalletLogin);
  }, [isTONWalletInstall, isTONWalletLogin]);

  useEffect(() => {
    setIsMetamaskInstalled(isMetaMaskInstall);
    setIsMetamaskConnected(!!account);
  }, [isMetaMaskInstall, account]);

  useEffect(() => {
    setIsCurrentMetamaskNetwork(networkChainId ? networkChainId === chainId : true);
    setTimeout(() => setIsGood(networkChainId ? networkChainId === chainId : true), 1000);
  }, [chainId, networkChainId]);

  useEffect(() => {
    setIsCurrentEverwalletNetwork(!!isMainnet);
  }, [isMainnet, networkChainId]);

  useEffect(() => {
    setIsGood(isEverwalletConnected && isMetamaskConnected);
    if ((isMetamaskConnected && isEverwalletConnected) || (!isMetamaskConnected && isEverwalletConnected)) {
      setIsGood(true);
    }
  }, [isEverwalletConnected, isMetamaskConnected]);

  const wrongMetaMaskNetworkText: SubinvestProps['paragraph'] = {
    title: (
      <span style={{ color: 'white' }}>
        Wrong blockchain
        <br /> selected in MetaMask
      </span>
    ),
    text: (
      <span style={{ color: 'white' }}>
        Open the wallet to select {networkChainId ? networkOptionsMap[networkChainId].label : 'necessary blockchain'}.
      </span>
    ),
  };

  const wrongEverNetworkText: SubinvestProps['paragraph'] = {
    title: <span style={{ color: 'white' }}>Wrong network</span>,
    text: (
      <span style={{ color: 'white' }}>You need to switch your EVER Wallet network to the Main everscale Network.</span>
    ),
  };

  const everwalletElement = (
    <Subinvest
      paragraph={
        isEverwalletInstalled
          ? isEverwalletConnected
            ? isCurrentEverwalletNetwork
              ? null
              : wrongEverNetworkText
            : null
          : {
              title: 'EVER WALLET',
              text: (
                <span style={{ color: 'white' }}>
                  To use network you need to install plugin using instructions from{' '}
                  <a href={links.extension} rel="noopener noreferrer" target="_blank">
                    broxus.com
                  </a>
                </span>
              ),
            }
      }
    >
      <>
        {!isEverwalletInstalled && (
          <a href={links.extension} target="_blank" rel="noopener noreferrer">
            <Button text="Install EVER Wallet" />
          </a>
        )}
        {isEverwalletInstalled && isCurrentEverwalletNetwork && (
          <Button
            gap="12px"
            isCentred
            text={!isEverwalletConnected ? 'Everwallet' : ''}
            className={isEverwalletConnected ? st['button-logged'] : undefined}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              TONWalletConnect();
            }}
          >
            {ton}
            {isEverwalletConnected && isCurrentEverwalletNetwork && !!accountInteraction ? (
              <span>
                {accountInteraction.address
                  .toString()
                  .slice(0, 6)
                  .concat('...')
                  .concat(accountInteraction.address.toString().slice(-4) || '') || 'no address'}
              </span>
            ) : null}
          </Button>
        )}
      </>
    </Subinvest>
  );

  const metamaskElement = (
    <Subinvest
      paragraph={
        isMetamaskInstalled
          ? isMetamaskConnected
            ? isCurrentMetamaskNetwork
              ? null
              : wrongMetaMaskNetworkText
            : null
          : {
              title: 'MetaMask WALLET',
              text: (
                <span style={{ color: 'white' }}>
                  To use network you need to install plugin using instructions from{' '}
                  <a href={links.metamask} rel="noopener noreferrer" target="_blank">
                    metamask.io
                  </a>
                </span>
              ),
            }
      }
    >
      <>
        {isMetamaskInstalled && isMetamaskConnected && !isCurrentMetamaskNetwork && (
          <Button
            gap="12px"
            isCentred
            text="Change Network"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (networkChainId) switchChain(networkChainId);
            }}
          />
        )}
        {isMetamaskInstalled && isCurrentMetamaskNetwork && (
          <Button
            gap="12px"
            isCentred
            text={!isMetamaskConnected ? 'Metamask' : ''}
            className={isMetamaskConnected ? st['button-logged'] : undefined}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isMetamaskConnected) return;
              metaMaskConnect();
            }}
          >
            {metamask}
            {isMetamaskConnected && isCurrentMetamaskNetwork && !!account ? (
              <span>
                {account
                  .slice(0, 6)
                  .concat('...')
                  .concat(account.slice(-4) || '') || 'no address'}
              </span>
            ) : null}
          </Button>
        )}
      </>
    </Subinvest>
  );

  return (
    <div className={st.buttons}>
      {isCurrentEverwalletNetwork && metamaskElement}
      {isCurrentMetamaskNetwork && everwalletElement}
    </div>
  );
});

export default ExtensionsManager;
