import React, { useEffect, useState } from 'react';
import { Item, PlainInput } from '../../components/controls';
import { Currency } from '../../components/small-card/Card';
import st from './form.module.css';
import InfoBlock, { InfoCardProps } from './InfoCard';

const validateWalletAddress = (address: string) => {
  const dangerousAddresses = ['0:0000000000000000000000000000000000000000000000000000000000000000'];
  return !dangerousAddresses.includes(address);
};

export type FormProps = {
  info: InfoCardProps[];
  walletAddress: string;
  setWalletAddress: (str: string) => void;
  currency?: Currency;
  maxAmount: number;
  amount: string;
  setAmount: (str: string) => void;
  setIsFormValid: (bool: boolean) => void;
};

const Form = ({
  info,
  currency,
  maxAmount,
  amount,
  setAmount,
  walletAddress,
  setWalletAddress,
  setIsFormValid,
}: FormProps) => {
  const [errorText, setErrorText] = useState<string | undefined>();
  const [walletError, setWalletError] = useState<boolean | undefined>();
  const [amountError, setAmountError] = useState<boolean | undefined>();

  const [amountTouched, setAmountTouched] = useState(false);
  const [walletAddressTouched, setWalletAddressTouched] = useState(false);

  const onEnterMaxClick = () => {
    setAmount(maxAmount.toString());
  };
  useEffect(() => {
    const reasons = {
      amount: {
        notZero: {
          goodCondition: !!+amount && +amount !== 0,
          text: 'The amount must be a positive number',
        },
        mustBeLessOrEqual: {
          goodCondition: !!+amount && +amount <= maxAmount,
          text: 'The amount must be less than or equal to the amount available for withdraw',
        },
      },
      wallet: {
        notEmptyString: {
          goodCondition: !!walletAddress,
          text: 'Wallet address required',
        },
        validation: {
          goodCondition: validateWalletAddress(walletAddress),
          text: 'Incorrect address',
        },
      },
    };
    const walletList = [reasons.wallet.notEmptyString, reasons.wallet.validation];
    const walletFilteredList = walletList.filter((reason) => !reason.goodCondition);

    const amountList = [reasons.amount.notZero, reasons.amount.mustBeLessOrEqual];
    const amountFilteredList = amountList.filter((reason) => !reason.goodCondition);

    setIsFormValid(!(walletFilteredList.length + amountFilteredList.length));
    setWalletError(!!walletFilteredList.length);
    setAmountError(!!amountFilteredList.length);
    setErrorText(walletFilteredList.concat(amountFilteredList)[0]?.text);
  }, [amount, maxAmount, walletAddress]);
  return (
    <>
      <div className={st.title}>Withdraw {currency?.symbol || ''}</div>
      <InfoBlock cards={info} currency={currency} />
      <Item text="Wallet address*" isError={walletAddressTouched && walletError}>
        <PlainInput
          type="wallet-address"
          value={walletAddress}
          setValue={(x) => {
            setWalletAddress(x);
            setWalletAddressTouched(true);
          }}
          isError={walletAddressTouched && walletError}
        />
      </Item>
      <Item
        text={`Amount of ${currency?.symbol || ''}*`}
        isError={amountTouched && amountError}
        anchor={
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <span onClick={onEnterMaxClick} className="initial">
            Enter max
          </span>
        }
      >
        <PlainInput
          type="number"
          value={amount}
          setValue={(x) => {
            setAmount(x);
            setAmountTouched(true);
          }}
          fixTo={currency?.decimals || 0}
          isError={amountTouched && amountError}
        />
      </Item>
      <div
        className={`${st['error-text']} ${
          (walletAddressTouched && walletError) || (amountTouched && amountError) ? st.error : ''
        }`}
      >
        {errorText}
      </div>
    </>
  );
};

export default Form;
