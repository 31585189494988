import React from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Zoom from 'react-reveal/Zoom';

const tooltipOptions = {
  offset: [15, 15] as [number, number],
};

const PortalWrapper = ({ children }: { children: JSX.Element }) => ReactDOM.createPortal(children, document.body);

export const useTooltip = (element: JSX.Element) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(tooltipOptions);

  return {
    setTriggerRef,
    element: (
      <PortalWrapper>
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <Zoom when={visible} duration={20}>
            <div>{element}</div>
          </Zoom>
        </div>
      </PortalWrapper>
    ),
  };
};
