import React, { ForwardedRef, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import st from './style.module.css';

export type ButtonProps = {
  isDisabled?: boolean;
  isSocial?: boolean;
  isInMenu?: boolean;
  isInvert?: boolean;
  isCentred?: boolean;
  children?: JSX.Element | null | (JSX.Element | null)[];
  text?: string | JSX.Element;
  width?: string;
  maxWidth?: string;
  gap?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setTriggerRef?: React.ForwardedRef<unknown>;
  fallback?: JSX.Element;
  notButton?: boolean;
  className?: string;
};

const Button = ({
  isDisabled = false,
  isSocial = false,
  isInMenu = false,
  isInvert = false,
  isCentred = false,
  children,
  text,
  width,
  maxWidth,
  gap,
  onClick,
  setTriggerRef,
  fallback,
  notButton,
  className,
}: ButtonProps) => {
  if (fallback) {
    return <span ref={setTriggerRef as ForwardedRef<HTMLButtonElement>}>{fallback}</span>;
  }

  return (
    <button
      ref={setTriggerRef as ForwardedRef<HTMLButtonElement>}
      type="button"
      className={
        // eslint-disable-next-line prefer-template
        st.button +
        (isInMenu ? ` ${st['button--menu']}` : '') +
        (isInvert ? ` ${st['button--invert']}` : ` ${st['button--simple']}`) +
        (isCentred ? ` ${st['button--centred']}` : '') +
        (isSocial ? ` ${st['button--social']}` : '') +
        (isDisabled ? ` ${st['button--disabled']}` : '') +
        (setTriggerRef ? ` ${st['fake-button']}` : '') +
        (notButton ? ` ${st['not-button']}` : '') +
        ` ${className}`
      }
      disabled={isDisabled}
      style={{
        ...(isMobile ? { cursor: 'none' } : {}),
        ...(maxWidth ? { maxWidth: width } : {}),
        ...(width ? { width } : {}),
        ...(gap ? { gap } : {}),
      }}
      onClick={onClick}
    >
      {children && <span className={st['children-wrapper']}>{children}</span>}
      {text}
    </button>
  );
};

export default forwardRef((props: Omit<ButtonProps, 'setTriggerRef'>, ref) => (
  <Button setTriggerRef={ref} {...props} />
));
