import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserInvestSmallCardProps } from '../../components/small-card';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { getProject, ProjectType } from '../../ton/get-project';

type Project = {
  commonInfo: null | ProjectType;
  userInfo: null | UserInvestSmallCardProps;
};

export type UseProjectData = {
  poolNumber: string | undefined;
  isDevMode?: boolean;
};

const useProjectData = ({ poolNumber, isDevMode }: UseProjectData) => {
  const [isRedirect, setIsRedirect] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const { isLogin, accountInteraction } = useContext(TONWalletContext);

  const navigate = useNavigate();

  const fetchProject = async (): Promise<{ project: null | Project; error: boolean } | undefined> => {
    if (typeof isLogin !== 'boolean') return undefined;

    if (
      poolNumber !== undefined &&
      !Number.isNaN(+poolNumber) &&
      Number.isFinite(+poolNumber) &&
      +poolNumber >= 0 &&
      Math.floor(+poolNumber) === +poolNumber &&
      isLogin
    ) {
      const commonProjectData = await getProject({
        poolNumber: +poolNumber,
      });
      if (!commonProjectData)
        return {
          project: null,
          error: true,
        };

      // const userAccountAddress = accountInteraction?.address.toString();
      // if (!userAccountAddress)
      return {
        project: {
          commonInfo: commonProjectData,
          userInfo: null,
        },
        error: false,
      };

      // const userProjectData = await getUserDataForProject({
      //   project: commonProjectData,
      //   userAccountAddress,
      // });
      // return {
      //   project: {
      //     commonInfo: commonProjectData,
      //     userInfo: userProjectData || null,
      //   },
      //   error: false,
      // };
    }

    return {
      project: null,
      error: true,
    };
  };

  const redirect = () => {
    navigate(`/project/${poolNumber ?? ''}`);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProject()
      .then((data) => {
        if (!data) return;

        const { project: projectData, error } = data;
        if (error || !projectData?.commonInfo) throw Error();

        const { commonInfo, userInfo } = projectData;

        if (commonInfo.owner === String(accountInteraction?.address) || isDevMode) {
          setProject({
            commonInfo,
            userInfo,
          });
        } else {
          setIsRedirect(true);
        }
      })
      .catch(() => {
        setIsLoadingError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLogin]);

  useEffect(() => {
    if (isRedirect && !isDevMode) redirect();
  }, [isRedirect]);

  return {
    isLoading,
    isLoadingError,
    project,
    redirect,
  };
};

export default useProjectData;
