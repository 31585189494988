import React from 'react';
import st from './style.module.css';

type NotificationProps = {
  logo?: JSX.Element;
  paragraph?: {
    title: string | JSX.Element;
    text: string | JSX.Element;
  };
  button?: JSX.Element;
  children?: JSX.Element;
};
const Notification = ({ logo, paragraph, button, children }: NotificationProps) => {
  const { title, text } = paragraph || {};

  return (
    <div className={st.box}>
      {logo && <section className={st.logo}>{logo}</section>}
      {paragraph && (
        <section className={st.paragraph}>
          <div className={st.title}>{title}</div>
          <div>{text}</div>
        </section>
      )}
      {button && <section>{button}</section>}
      {children && <section>{children}</section>}
    </div>
  );
};

export default Notification;
