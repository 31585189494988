/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from 'react-reveal/Fade';
import { useNavigate, useParams } from 'react-router';
import { Button } from '../components/button';
import { ButtonsBox, Card, SaleDetails, TheHIGHSaleHasEnded } from '../components/card';
import { Content } from '../components/content';
import { FinishFundraisingByOwnerButton } from '../components/finish-fundraising-by-owner-button';
import { Modal } from '../components/modal';
import { Popup } from '../components/popup';
import { ProjectWrapper } from '../components/projects-list';
import { UserInvestSmallCardProps } from '../components/small-card';
import { Spinner } from '../components/spinner';
import { StatusEnum } from '../components/status/Status';
import { Vesting } from '../components/vesting';
import { VestingCard } from '../components/vesting/vesting-card';
import { Context } from '../providers/Provider';
import { ReturnContext, ReturnRequest } from '../providers/ReturnProvider';
import { TONWalletContext } from '../providers/TONWalletProvider';
import { getProject, getUserDataForProject, ProjectType } from '../ton/get-project';
import useResize from '../utils/hooks/useResize';
import useTONCrystalWallet from '../utils/hooks/useTONCrystalWallet';
import { WithdrawalByOwnerButton } from './withdrawal-by-owner';
import st from './project.module.css';
import { useEvmWallet } from '../services/EvmWalletService';

const Project = () => {
  const { poolNumber } = useParams();
  const navigate = useNavigate();

  const { isLogin, accountInteraction } = useContext(TONWalletContext);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState<(ProjectType & Partial<UserInvestSmallCardProps>) | null>(null);
  const [isProjectFetchingEnd, setIsProjectFetchingEnd] = useState(false);
  const [isGetARefund, setIsGetARefund] = useState(false);
  const [isGetAvailable, setIsGetAvailable] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isRefundSuccess, setIsRefundSuccess] = useState(false);
  const [isAvailableSuccess, setIsAvailableSuccess] = useState(false);
  const [currentButton, setCurrentButton] = useState<'tokens' | 'tons' | null>(null);

  const { onGetBack } = useTONCrystalWallet();

  const { returnEver, returnToken, returnProjectEver } = useContext(ReturnContext);

  const { setHeaderWidth, isMobile } = useContext(Context);

  useEffect(() => {
    if (!isModalShow) {
      setIsRefundSuccess(false);
      setIsAvailableSuccess(false);
    }
  }, [isModalShow]);

  useEffect(() => {
    setHeaderWidth(420);
  }, []);
  useResize({
    mobileWidth: 270 + 350 + 760,
  });

  useEffect(() => {
    setIsLoading(true);
    if (
      poolNumber !== undefined &&
      !Number.isNaN(+poolNumber) &&
      Number.isFinite(+poolNumber) &&
      +poolNumber >= 0 &&
      Math.floor(+poolNumber) === +poolNumber &&
      typeof isLogin === 'boolean'
    ) {
      getProject({
        poolNumber: +poolNumber,
      }).then((loadedProject) => {
        if (!loadedProject) {
          navigate('/404');
          return;
        }
        setProject(loadedProject);
        setIsProjectFetchingEnd(true);
      });
    }
  }, [poolNumber, isLogin]);

  useEffect(() => {
    if (isProjectFetchingEnd) {
      if (project && isLoading) {
        if (isLogin) {
          const userAccountAddress = accountInteraction?.address.toString();
          if (userAccountAddress) {
            getUserDataForProject({
              project,
              userAccountAddress,
            }).then((userData) => {
              setProject((oldProject) => {
                if (!oldProject) return null;
                return {
                  ...oldProject,
                  ...(userData || {}),
                };
              });

              const isFinished = project.status === 'Finished' || project.status === 'Failed';

              setIsGetARefund(
                isFinished && Date.now() > project.endTime * 1000 && !!userData?._return && userData._return > 0
              );
              setIsGetAvailable(
                project.totalRaised >= project.softcap &&
                  project.status === 'Finished' &&
                  Date.now() > project.endTime * 1000 &&
                  !!userData?.accessible &&
                  userData.accessible > 0
              );

              setIsLoading(false);
              setIsProjectFetchingEnd(false);
            });
          }
        } else {
          setIsLoading(false);
          setIsProjectFetchingEnd(false);
        }
      }
    }
  }, [isProjectFetchingEnd]);

  const investButtonExist =
    project?.status === 'Live' && Date.now() > project.startTime * 1000 && Date.now() < project.endTime * 1000;

  return (
    <Content backTo={`/projects/${project?.status || ''}`} title="Back">
      {project && !isLoading ? (
        <ProjectWrapper>
          <Fade>
            <Card {...project}>
              <>
                {project?.status && (['Finished', 'Failed', 'Live'] as StatusEnum[]).includes(project.status) && (
                  <TheHIGHSaleHasEnded
                    project={project}
                    investButtonExist={investButtonExist}
                    participants={project?.amountOfInvestors ?? 0}
                    totalSales={{
                      amount: project?.totalRaised / 10 ** (project.mainCurrency.decimals ?? 0),
                      currency: project.mainCurrency.symbol,
                    }}
                    isProjectActive={project.status === 'Live'}
                    investmentProgressIndicator={{
                      committed: project.totalRaised / 10 ** (project.mainCurrency.decimals ?? 0),
                      yourCommit: project?.youInvested
                        ? project?.youInvested / 10 ** (project.mainCurrency.decimals ?? 0)
                        : null,
                      softCup: project.softcap / 10 ** (project.mainCurrency.decimals ?? 0),
                      hardCup: project.hardcap / 10 ** (project.mainCurrency.decimals ?? 0),
                      currencyLabel: project.mainCurrency.symbol,
                      userAllocation:
                        project.appreciated && project.launchPoolParticipationAddress
                          ? {
                              amount: project.appreciated / 10 ** (project.tokenCurrency.decimals ?? 0),
                              tokenSymbol: project.tokenCurrency.symbol,
                              address: project.launchPoolParticipationAddress,
                            }
                          : null,
                    }}
                  />
                )}
                <SaleDetails
                  totalSales={
                    project.status !== 'Upcoming'
                      ? {
                          amount: project?.totalRaised / 10 ** (project.mainCurrency.decimals ?? 0),
                          currency: project.mainCurrency.symbol,
                        }
                      : null
                  }
                  finishFundraisingByOwnerButton={
                    project.status === 'Live' &&
                    project.endTime * 1000 <= Date.now() &&
                    project.owner === String(accountInteraction?.address) ? (
                      <FinishFundraisingByOwnerButton
                        launchPoolAddress={project.launchPoolAddress}
                        // onSuccess={() => window.location.reload()}
                      />
                    ) : undefined
                  }
                  withdrawalByOwnerButton={
                    project.status === 'Finished' &&
                    project.endTime * 1000 <= Date.now() &&
                    project.totalRaised >= project.softcap &&
                    project.owner === String(accountInteraction?.address) &&
                    project.totalWithdraw < Math.min(project.totalRaised, project.hardcap) &&
                    returnProjectEver !== ReturnRequest.start &&
                    poolNumber ? (
                      <WithdrawalByOwnerButton poolNumber={poolNumber} currency={project.mainCurrency.symbol} />
                    ) : undefined
                  }
                  tokenCurrency={project.tokenCurrency}
                  mainCurrency={project.mainCurrency}
                  project={project}
                  poolInformation={{
                    purchaseOpensDate: project?.startTime ? new Date(project?.startTime * 1000) : undefined,
                    purchaseClosesDate: project?.endTime ? new Date(project?.endTime * 1000) : undefined,
                  }}
                  rate={0}
                  tokenInformation={{
                    tokenForSale: {
                      amount: project.tokenForSale ?? 0,
                      currency: project.tokenCurrency.symbol,
                    },
                    softcap: {
                      amount: project.softcap ?? 0,
                      currency: project.mainCurrency.symbol,
                    },
                    hardcap: {
                      amount: project.hardcap ?? 0,
                      currency: project.mainCurrency.symbol,
                    },
                    // received:
                    //   !!project?.youInvested && project.youInvested > 0
                    //     ? {
                    //         amount: project.received ?? 0,
                    //         currency: project.tokenCurrency.symbol,
                    //       }
                    //     : undefined,
                    periods: project.vestingPeriods || [],
                  }}
                  userProps={
                    isLogin &&
                    project?.status &&
                    (['Live', 'Finished', 'Failed'] as StatusEnum[]).includes(project?.status)
                      ? {
                          rate: 0,
                          tokenCurrency: project.tokenCurrency,
                          mainCurrency: project.mainCurrency,
                          yourInvestment: {
                            youInvested: project.youInvested ?? 0,
                            appreciated:
                              project.appreciated && project.appreciated > 0 && project?.status !== 'Failed'
                                ? project.appreciated
                                : null,
                            appreciatedPercent:
                              typeof project.appreciatedPercent === 'number' ? project.appreciatedPercent : null,
                            accessible: project?.status === 'Failed' ? null : project.accessible ?? 0,
                            periods: project?.status === 'Failed' ? [] : project.periods || [],
                            received: project?.status === 'Failed' ? null : project.received ?? 0,
                            // eslint-disable-next-line no-underscore-dangle
                            _return: Date.now() > project.endTime * 1000 && project._return ? project._return : 0,
                            launchPoolParticipationAddress: project.launchPoolParticipationAddress || null,
                          },
                          launchTokenRoot: project.tokenCurrency.launchTokenRoot,
                          buttonsShow: {
                            getARefund: isGetARefund,
                            getAvailable: isGetAvailable,
                          },
                        }
                      : {}
                  }
                  buttonsBox={
                    isLogin &&
                    project?.status &&
                    (['Live', 'Finished', 'Failed'] as StatusEnum[]).includes(project?.status) ? (
                      <ButtonsBox
                        mainCurrency={project.mainCurrency}
                        buttonsShow={{
                          getARefund:
                            isGetARefund &&
                            (returnEver === ReturnRequest.normalCondition || returnEver === ReturnRequest.bad),
                          getAvailable:
                            isGetAvailable &&
                            (returnToken === ReturnRequest.normalCondition || returnToken === ReturnRequest.bad),
                        }}
                        onGetARefund={async () => {
                          setCurrentButton('tons');
                          setIsModalShow(true);
                          setIsModalLoading(true);
                          if (isGetARefund && project?.launchPoolParticipationAddress && accountInteraction?.address) {
                            onGetBack({
                              functionName:
                                project.totalRaised > project.hardcap ? 'returnDepositSulpur' : 'returnDeposit',
                              launchPoolParticipationAddress: project.launchPoolParticipationAddress,
                              myAddress: String(accountInteraction?.address),
                              myPublicKey: String(accountInteraction?.publicKey),
                            })
                              .then((answer) => {
                                setIsRefundSuccess(!!answer);
                                setIsProjectFetchingEnd(true);
                              })
                              .catch((error) => {
                                let errorObject;
                                try {
                                  errorObject = JSON.parse(error.message);
                                  // eslint-disable-next-line no-empty
                                } catch (err) {}
                                if (+errorObject?.code === 3) {
                                  setIsModalShow(false);
                                  // eslint-disable-next-line no-console
                                  console.error(errorObject.message);
                                } else {
                                  setIsRefundSuccess(false);
                                  // eslint-disable-next-line no-console
                                  console.error(error);
                                }
                              })
                              .finally(() => {
                                setIsModalLoading(false);
                              });
                          }
                        }}
                        onGetAvailable={async () => {
                          setCurrentButton('tokens');
                          setIsModalShow(true);
                          setIsModalLoading(true);
                          if (
                            isGetAvailable &&
                            project?.launchPoolParticipationAddress &&
                            accountInteraction?.address
                          ) {
                            onGetBack({
                              functionName: 'claimReward',
                              launchPoolParticipationAddress: project.launchPoolParticipationAddress,
                              myAddress: String(accountInteraction?.address),
                              myPublicKey: String(accountInteraction?.publicKey),
                            })
                              .then((answer) => {
                                setIsAvailableSuccess(!!answer);
                                setIsProjectFetchingEnd(true);
                              })
                              .catch((error) => {
                                let errorObject;
                                try {
                                  errorObject = JSON.parse(error.message);
                                  // eslint-disable-next-line no-empty
                                } catch (err) {}
                                if (+errorObject?.code === 3) {
                                  setIsModalShow(false);
                                  // eslint-disable-next-line no-console
                                  console.error(errorObject.message);
                                } else {
                                  setIsAvailableSuccess(false);
                                  // eslint-disable-next-line no-console
                                  console.error(error);
                                }
                              })
                              .finally(() => {
                                setIsModalLoading(false);
                              });
                          }
                        }}
                      />
                    ) : null
                  }
                />
                <Vesting
                  slides={project.vestingPeriods.map(({ percent, unfreezeTime }, i) => {
                    const amount =
                      (project.periods?.find(({ unfreezeTime: my }) => my === +unfreezeTime)?.amount || 0) /
                      10 ** (project.tokenCurrency.decimals ?? 0);
                    return {
                      key: String(i),
                      element: (
                        <VestingCard
                          mainSection={{
                            date: new Date((+unfreezeTime || 0) * 1000),
                            percent: (+percent || 0) / 100,
                          }}
                          userSection={
                            amount
                              ? {
                                  amount,
                                  currencyLabel: project.tokenCurrency.symbol,
                                }
                              : undefined
                          }
                        />
                      ),
                    };
                  })}
                />
                {investButtonExist && isMobile && (
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0 19px 36px' }}>
                    <Button
                      isCentred
                      text="Deposit"
                      width="176px"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // setIsModalShow(true);
                        navigate(`/project/${project.poolNumber}/deposit`);
                      }}
                    />
                  </div>
                )}
                {isModalShow && (
                  <Modal setIsShow={setIsModalShow}>
                    <Popup
                      onCancel={() => {
                        setCurrentButton(null);
                        setIsModalShow(false);
                      }}
                      buttons={
                        isModalLoading ? undefined : (
                          <div className={st['ok-button']}>
                            <div>
                              <br />
                              <Button
                                isCentred
                                text="OK"
                                onClick={() => {
                                  setCurrentButton(null);
                                  setIsModalShow(false);
                                }}
                              />
                            </div>
                          </div>
                        )
                      }
                    >
                      <div className={st.modal}>
                        {isModalLoading ? (
                          <>
                            {currentButton === 'tons' && (
                              <span>
                                A return request has been created.
                                <br />
                                Please wait...
                              </span>
                            )}
                            {currentButton === 'tokens' && (
                              <span>
                                Your token claim request has been created.
                                <br />
                                Please wait...
                              </span>
                            )}
                            <div className={st.spinner}>
                              <Spinner backgroundColor="transparent" />
                            </div>
                          </>
                        ) : (
                          <>
                            {isRefundSuccess && (
                              <>
                                Your {project.mainCurrency.symbol} return request has successfully been created.
                                <br />
                                <br />
                                Track the receipt in your EVER Wallet.
                              </>
                            )}
                            {isAvailableSuccess && (
                              <>
                                A request for available tokens has been successfully created.
                                <br />
                                <br />
                                Track the receipt in your EVER Wallet.
                              </>
                            )}
                            {!isRefundSuccess && !isAvailableSuccess && (
                              <>
                                Something went wrong.
                                <br />
                                <br />
                                Try again later.
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Popup>
                  </Modal>
                )}
              </>
            </Card>
          </Fade>
        </ProjectWrapper>
      ) : (
        <Spinner withWrapper />
      )}
    </Content>
  );
};

export default Project;
