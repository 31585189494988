import { Address } from 'everscale-inpage-provider';
import { TonSingleton } from './ton-singleton';

const getMyAddress = async () => {
  try {
    const ton = TonSingleton.getInstance().client;
    if (!ton) return null;
    const currentProviderState = await ton.getProviderState();
    return currentProviderState?.permissions?.accountInteraction?.address || null;
  } catch (error) {
    return null;
  }
};

type CheckIsMyTonWalletDeployed = {
  walletAddress?: string;
};
export const checkIsMyTonWalletDeployed = async ({
  walletAddress,
}: CheckIsMyTonWalletDeployed): Promise<boolean | null> => {
  try {
    const address = walletAddress || (await getMyAddress());
    if (!address) return null;

    const ton = TonSingleton.getInstance().client;
    if (!ton) return null;

    const { state } = await ton.getFullContractState({
      address: new Address(String(address)),
    });
    if (!state) return null;

    return state.isDeployed;
  } catch (error) {
    return null;
  }
};
