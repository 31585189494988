/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSwiper } from 'swiper/react';
import st from './cut.module.css';

// for storybook
// // eslint-disable-next-line import/extensions
// import { useSwiper } from 'swiper/react/swiper-react.js';

export type HideObject = {
  isHidePrevArrow: boolean;
  isHideNextArrow: boolean;
};

type CutProps = {
  render: (props: HideObject) => React.ReactNode;
};
const Cut = ({ render }: CutProps) => {
  const [isHidePrevArrow, setIsHidePrevArrow] = useState(true);
  const [isHideNextArrow, setIsHideNextArrow] = useState(false);

  const swiper = useSwiper();

  useEffect(() => {
    swiper?.onAny(() => {
      const _isHidePrevArrow = swiper ? swiper?.isBeginning : true;
      setIsHidePrevArrow(_isHidePrevArrow);
      // swiper.$el[_isHidePrevArrow ? 'removeClass' : 'addClass'](st['arrow-prev-cut']);

      const _isHideNextArrow = swiper ? swiper?.isEnd : true;
      setIsHideNextArrow(_isHideNextArrow);
      // swiper.$el[_isHidePrevArrow ? 'removeClass' : 'addClass'](st['arrow-next-cut']);
    });
  }, [swiper]);

  if (!swiper) return null;
  return <div className={st.box}>{render({ isHidePrevArrow, isHideNextArrow })}</div>;
};

export default Cut;
