import React, { useContext, useState } from 'react';
import { ReturnContext, ReturnRequest } from '../../providers/ReturnProvider';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import useTONCrystalWallet, { FinishFundraisingByOwner } from '../../utils/hooks/useTONCrystalWallet';
import { Button } from '../button';
import { Modal } from '../modal';
import { Popup } from '../popup';
import st from './style.module.css';

const FinishFundraisingByOwnerButton = ({
  launchPoolAddress,
  onSuccess,
}: Pick<FinishFundraisingByOwner, 'launchPoolAddress'> & {
  onSuccess?: () => void;
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { accountInteraction } = useContext(TONWalletContext);
  const { finishFundraisingByOwner } = useTONCrystalWallet();
  const { finishFundraising } = useContext(ReturnContext);

  if (!accountInteraction) return <></>;

  return (
    <>
      {!isClicked && finishFundraising !== ReturnRequest.start && finishFundraising !== ReturnRequest.good && (
        <Button
          isCentred
          isInvert
          width="230px"
          text="Finish Fundraising"
          onClick={() => {
            setIsClicked(true);
            setIsModalShow(true);
            setIsLoading(true);
            finishFundraisingByOwner({
              launchPoolAddress,
              myAddress: String(accountInteraction.address),
              myPublicKey: String(accountInteraction.publicKey),
            })
              .then(() => {
                onSuccess?.();
              })
              .catch((error) => {
                let errorObject;
                try {
                  errorObject = JSON.parse(error.message);
                  // eslint-disable-next-line no-empty
                } catch (err) {}
                if (+errorObject?.code === 3) {
                  setIsModalShow(false);
                  // eslint-disable-next-line no-console
                  console.error(errorObject.message);
                } else {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
                setIsClicked(false);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {isModalShow && (
        <Modal setIsShow={setIsModalShow}>
          <Popup
            onCancel={() => setIsModalShow(false)}
            buttons={
              isLoading ? undefined : (
                <div className={st['ok-button']}>
                  <div>
                    <Button
                      isCentred
                      text="OK"
                      onClick={() => {
                        setIsModalShow(false);
                      }}
                    />
                  </div>
                </div>
              )
            }
          >
            <div className={st.text}>
              {isLoading ? (
                <>
                  Confirm the transaction in the extension to complete the project
                  <br />
                  And wait a bit.
                </>
              ) : (
                <>
                  {isClicked ? (
                    <>
                      A project completion request has successfully been created.
                      <br />
                      Soon the status will change and the project will be displayed on the corresponding tab of the
                      project catalog.
                    </>
                  ) : (
                    <>
                      A project completion request has not been created.
                      <br />
                      Please try again later
                    </>
                  )}
                </>
              )}
            </div>
          </Popup>
        </Modal>
      )}
    </>
  );
};

export default FinishFundraisingByOwnerButton;
