import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '../../components/button';

export type ButtonProps = {
  poolNumber: string;
  currency: string;
};

const WithdrawalByOwnerButton = ({ poolNumber, currency }: ButtonProps) => {
  const navigate = useNavigate();

  const onButtonClick = () => navigate(`/project/${poolNumber}/withdraw`);

  return <Button width="230px" isInvert isCentred text={`Withdraw ${currency}`} onClick={onButtonClick} />;
};

export default WithdrawalByOwnerButton;
