import React from 'react';
import { cancel, checked } from '../../common/icons';
import { Button } from '../button';
import installPopup from '../install-popup/style.module.css';
import { Popup } from '../popup';
import st from './style.module.css';
import { Modal } from '../modal';

export type TransactionPopupProps = {
  isSuccess: boolean;
  onCancel?: () => void;
  children?: JSX.Element;
};
const TransactionPopup = ({ isSuccess, onCancel, children }: TransactionPopupProps) => {
  return (
    <Modal>
      <Popup
        buttons={
          <div className={st['button-box']}>
            <div>
              <Button
                isCentred
                width="165px"
                text="Close"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel?.();
                }}
              />
            </div>
          </div>
        }
      >
        <div className={`${installPopup.box} ${st.box}`}>
          <>
            {isSuccess ? <span className={st.checked}>{checked}</span> : <span className={st.checked}>{cancel}</span>}
          </>
          <h1>{isSuccess ? 'Your purchase is successful!' : 'Purchase failed'}</h1>
          <span className={st.info}>
            {isSuccess ? 'You have successfully invested in the project' : 'Error! Tokens have not been deposited'}
          </span>
          {children}
        </div>
      </Popup>
    </Modal>
  );
};

export default TransactionPopup;
