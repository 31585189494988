import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from 'react-reveal/Fade';
import st from './style.module.css';

const SPINNER = {
  fullWidth: 70,
  padding: 15,
};

type SpinnerProps = {
  color?: string;
  backgroundColor?: string;
  padding?: number;
  width?: number;
};
const Spinner = ({ color, backgroundColor, padding, width = SPINNER.fullWidth }: SpinnerProps) => {
  return (
    <Fade>
      <div
        className={st.spinner}
        style={{
          ...(color
            ? {
                color,
              }
            : {}),
          ...(backgroundColor
            ? {
                backgroundColor,
              }
            : {}),
          ...(padding !== undefined
            ? {
                padding: `${padding}px`,
              }
            : {}),
          ...{
            width,
            height: width,
          },
        }}
      >
        <div
          style={{
            zoom: width / (SPINNER.fullWidth - (SPINNER.padding - (padding || 0)) * 2),
          }}
          className={st['dot-windmill']}
        />
      </div>
    </Fade>
  );
};
type WrapperProps = {
  withWrapper?: boolean;
};
const Wrapper = ({ withWrapper, ...rest }: SpinnerProps & WrapperProps) => {
  if (withWrapper)
    return (
      <div className={st.wrapper}>
        <div
          style={{
            width: rest.width,
          }}
        >
          <Spinner {...rest} />
        </div>
      </div>
    );

  return <Spinner {...rest} />;
};

export default Wrapper;
