import BigNumber from 'bignumber.js';

export const calculateUserFutureAllocation = (
  amount: BigNumber,
  totalRaised: BigNumber,
  accountInvestments: BigNumber,
  decimals: number
) => {
  if (totalRaised.isEqualTo(accountInvestments)) return '100';

  const totalUserInvestments = amount
    .plus(totalRaised.shiftedBy(-decimals))
    .plus(accountInvestments.shiftedBy(-decimals));

  return amount.div(totalUserInvestments).multipliedBy(100).toFixed(2);
};
