/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import abiDecoder from 'abi-decoder';
import init, { mapEthBytesIntoTonCell } from 'eth-ton-abi-converter';
import wasm from 'eth-ton-abi-converter/eth_ton_abi_converter_bg.wasm';
import Web3 from 'web3';
import { Log, TransactionReceipt } from 'web3-core';
import { CreditEthereumEventConfigurationDetails } from '.';
import creditFactoryAbi from '../abi/ton/CreditFactory.abi.json';
import creditProcessorAbi from '../abi/ton/CreditProcessor.abi.json';
import VaultAbi from '../abi/erc20/Vault.abi.json';
import { tonClientRunLocal } from '../ton/ton-labs';
import { delay } from '../utils/helpers';
import { useEvmWallet } from '../services/EvmWalletService';

// eslint-disable-next-line no-shadow
export enum PaymentSteps {
  start,
  signaturesCollected,
  creditProcessorContractCreated,
  processedStatus,
  error,
}

type GetAddresses = {
  evmConfigDetails: CreditEthereumEventConfigurationDetails;
  txHash: string;
  evmConfigAddress: string;
  creditFactoryAddress: string;
  onCreditProcessorStatusChanged: (status: string | null) => void;
};

const checkThatTheTransactionHasCollectedTheRequiredNumberOfSign = async (props: {
  web3: Web3;
  txHash: string;
  eventBlocksToConfirm: number;
}): Promise<boolean> => {
  const { web3, txHash, eventBlocksToConfirm } = props;
  const txReceipt = await web3.eth.getTransactionReceipt(txHash);
  const networkBlockNumber = await web3.eth.getBlockNumber();

  let result = true;

  if (txReceipt?.blockNumber == null || networkBlockNumber == null) {
    // статус pending
    result = false;
  }
  if (result) {
    const confirmedBlocksCount = networkBlockNumber - txReceipt.blockNumber;
    result = confirmedBlocksCount >= eventBlocksToConfirm;
  }
  if (!result) {
    await delay(1000);
    return checkThatTheTransactionHasCollectedTheRequiredNumberOfSign(props);
  }
  return true;
};

const getCreditProcessorAddress = async ({
  evmConfigAddress,
  evmConfigDetails,
  creditFactoryAddress,
  txReceipt,
  log,
}: {
  evmConfigAddress: GetAddresses['evmConfigAddress'];
  evmConfigDetails: CreditEthereumEventConfigurationDetails;
  creditFactoryAddress: string;
  txReceipt: TransactionReceipt;
  log: Log;
}): Promise<string | null> => {
  //  mapEthBytesIntoTonCell из "eth-ton-abi-converter": "^0.1.5"
  let eventData;
  try {
    await init(wasm);
    eventData = mapEthBytesIntoTonCell(
      Buffer.from(evmConfigDetails._basicConfiguration.eventABI, 'hex').toString(),
      log.data
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }

  const eventVoteData = {
    eventBlock: txReceipt.blockHash,
    eventBlockNumber: txReceipt.blockNumber.toString(),
    eventData,
    eventIndex: log.logIndex.toString(),
    eventTransaction: txReceipt.transactionHash,
  };

  let creditProcessorAddress: string;
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: creditFactoryAbi,
      },
      creditFactoryAddress,
      {
        function_name: 'getCreditProcessorAddress',
        input: {
          answerId: 0,
          eventVoteData,
          configuration: evmConfigAddress,
        },
      }
    );
    if (!output?.value0) return null;
    creditProcessorAddress = output.value0;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }

  return creditProcessorAddress || null;
};

// eslint-disable-next-line no-shadow
export enum CreditProcessorStatus {
  Created,
  EventNotDeployed,
  EventDeployInProgress,
  EventConfirmed,
  EventRejected,
  CheckingAmount,
  CalculateSwap,
  SwapInProgress,
  SwapFailed,
  SwapUnknown,
  UnwrapInProgress,
  UnwrapFailed,
  ProcessRequiresGas,
  Processed,
  Cancelled,
}

type CreditProcessorDetails = {
  eventVoteData: {
    eventTransaction: string;
    eventIndex: string;
    eventData: string;
    eventBlockNumber: string;
    eventBlock: string;
  };
  configuration: string;
  amount: string;
  slippage: {
    numerator: string;
    denominator: string;
  };
  dexRoot: string;
  wtonVault: string;
  wtonRoot: string;
  state: string;
  eventState: string;
  deployer: string;
  debt: string;
  fee: string;
  eventAddress: string;
  tokenRoot: string;
  tokenWallet: string;
  wtonWallet: string;
  dexPair: string;
  dexVault: string;
  swapAttempt: string;
  swapAmount: string;
  unwrapAmount: string;
};
export const getCreditProcessorState = async ({
  creditProcessorAddress,
}: {
  creditProcessorAddress: string;
}): Promise<number | null> => {
  let creditProcessorDetails: CreditProcessorDetails;
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: creditProcessorAbi,
      },
      creditProcessorAddress,
      {
        function_name: 'getDetails',
        input: {
          answerId: 0,
        },
      }
    );
    if (!output?.value0) return null;
    creditProcessorDetails = output.value0;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }

  if (creditProcessorDetails && !Number.isNaN(+creditProcessorDetails.state)) {
    return +creditProcessorDetails.state;
  }
  return null;
};

const checkCreditProcessorByStatus = async (props: {
  creditProcessorAddress: string;
  creditProcessorStatus: number | null;
  onCreditProcessorStatusChanged: GetAddresses['onCreditProcessorStatusChanged'];
}): Promise<boolean> => {
  const { creditProcessorAddress, creditProcessorStatus, onCreditProcessorStatusChanged } = props;

  const state = await getCreditProcessorState({ creditProcessorAddress });

  onCreditProcessorStatusChanged(state ? CreditProcessorStatus[state] : null);

  if (
    state === CreditProcessorStatus.EventNotDeployed ||
    state === CreditProcessorStatus.EventRejected ||
    state === CreditProcessorStatus.SwapFailed ||
    state === CreditProcessorStatus.UnwrapFailed ||
    state === CreditProcessorStatus.Cancelled
  )
    return false;

  if (creditProcessorStatus === null && state !== null) return true;

  if (!(state === creditProcessorStatus)) {
    await delay(1000);
    return checkCreditProcessorByStatus(props);
  }
  return true;
};

const checkProcessed = async (props: {
  web3: Web3;
  txHash: string;
  evmConfigAddress: string;
  evmConfigDetails: CreditEthereumEventConfigurationDetails;
  creditFactoryAddress: string;
  onCreditProcessorStatusChanged: GetAddresses['onCreditProcessorStatusChanged'];
}): Promise<null | {
  isStartPromise: Promise<boolean>;
  isProcessedPromise: Promise<boolean>;
}> => {
  const { web3, txHash, evmConfigAddress, evmConfigDetails, creditFactoryAddress, onCreditProcessorStatusChanged } =
    props;
  // Стадия 2:
  // транза в evm набрала необходумую глубину, можно начинать чекать CreditProcessor
  // вычислить его адрес можно следующим образом

  // Функции из модуля "abi-decoder": "^2.4.0"
  abiDecoder.keepNonDecodedLogs();
  abiDecoder.addABI(VaultAbi);

  const txReceipt = await web3.eth.getTransactionReceipt(txHash);

  const decodedLogs = abiDecoder.decodeLogs(txReceipt?.logs || []);
  const log = txReceipt.logs[decodedLogs.findIndex((l: any) => l !== undefined && l.name === 'FactoryDeposit')];

  let result = true;

  if (log?.data == null || evmConfigAddress === undefined) {
    result = false;
  }

  if (result) {
    const creditProcessorAddress = await getCreditProcessorAddress({
      evmConfigAddress,
      evmConfigDetails,
      creditFactoryAddress,
      txReceipt,
      log,
    });

    if (creditProcessorAddress) {
      const isStartPromise = checkCreditProcessorByStatus({
        creditProcessorAddress,
        creditProcessorStatus: null,
        onCreditProcessorStatusChanged,
      });
      const isProcessedPromise = checkCreditProcessorByStatus({
        creditProcessorAddress,
        creditProcessorStatus: CreditProcessorStatus.Processed,
        onCreditProcessorStatusChanged,
      });
      return {
        isStartPromise,
        isProcessedPromise,
      };
    }
    result = false;
  }
  if (!result) {
    await delay(1000);
    return checkProcessed(props);
  }
  return null;
};

export const trackThePayment = async ({
  evmConfigDetails,
  txHash,
  evmConfigAddress,
  creditFactoryAddress,
  onCreditProcessorStatusChanged,
}: GetAddresses) => {
  const { web3 } = useEvmWallet();
  if (!web3) return null;

  const checkingPromise = checkThatTheTransactionHasCollectedTheRequiredNumberOfSign({
    web3,
    txHash,
    eventBlocksToConfirm: +evmConfigDetails._networkConfiguration.eventBlocksToConfirm,
  })
    .then((isAllSignExist) => {
      if (isAllSignExist) {
        const processedCheckingPromise = checkProcessed({
          creditFactoryAddress,
          evmConfigAddress,
          evmConfigDetails,
          txHash,
          web3,
          onCreditProcessorStatusChanged,
        });
        return {
          isAllSignExist: true,
          processedCheckingPromise,
        };
      }
      throw Error('Verification that the transaction has collected the required number of signatures failed');
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    });

  return {
    checkingPromise,
  };
};
