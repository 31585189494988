import React from 'react';
import { useNavigate } from 'react-router';
import image from '../../common/icons/404.svg';
import { Button } from '../../components/button';
import st from './styles.module.css';

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={st.gap}>
      <div className={st.wrapper}>
        <img src={image} alt="" />
        <div className={st.header}>Whoops!</div>
        <div className={st.text}>Looking like a page is stolen by ghosts</div>
        <Button text="Go to main" width="165px" onClick={() => navigate('/')} />
      </div>
    </div>
  );
};
