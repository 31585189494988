import React, { useEffect, useRef, useState } from 'react';
import { Badge } from '../badge';
import { Colors } from '../common';
import '../common.css';
import { ProgressLine } from '../progress-line';
import { Separator } from '../separator';
import st from './style.module.css';
import useBadge from './useBadge';
import { UseProgressBarProps, UseProgressBarReturnType } from './useProgressBar';

export type ProgressBarProps = Omit<UseProgressBarProps, 'yourCommit'> & UseProgressBarReturnType;

const ProgressBar = ({
  committed,
  softCup,
  hardCup,
  separator1Ref,
  separator2Ref,
  softCupPart,
  middlePart,
  hardCupPart,
}: ProgressBarProps) => {
  const line1Ref = useRef<HTMLDivElement>(null);
  const line2Ref = useRef<HTMLDivElement>(null);
  const line3Ref = useRef<HTMLDivElement>(null);

  const badgeRef = useRef<HTMLDivElement>(null);

  const { isLineNotSmall: isLine1NotSmall } = useBadge({
    ref: line1Ref,
  });
  const {
    isLineNotSmall: isLine2NotSmall,
    elementWidth,
    approximateBadgeWidth,
  } = useBadge({
    ref: line2Ref,
  });
  const { isLineNotSmall: isLine3NotSmall } = useBadge({
    ref: line3Ref,
  });

  const [badgeLength, setBadgeLength] = useState<number | undefined>();
  const [badgeNormalLength, setBadgeNormalLength] = useState<number | undefined>();

  useEffect(() => {
    const badgeWidth = badgeRef?.current?.offsetWidth;
    setBadgeLength(badgeWidth);
    if (badgeWidth) setBadgeNormalLength((old) => Math.max(old || 0, badgeWidth));
  }, [elementWidth]);

  return (
    <div className={st.box}>
      <ProgressLine
        ref={line1Ref}
        lineWidthRaw={softCupPart?.css || '0'}
        color={committed >= softCup ? Colors.good : Colors.beforeSoftCup}
        layer1WidthPercent={softCupPart?.percents?.committed || undefined}
        layer2WidthPercent={softCupPart?.percents?.yourCommit || undefined}
      />
      <section ref={separator1Ref} className={st.separator}>
        <Separator
          text={
            isLine1NotSmall &&
            isLine2NotSmall &&
            elementWidth &&
            (badgeLength ? elementWidth > badgeLength + approximateBadgeWidth / 2 : true)
              ? 'Soft Cap'
              : null
          }
          color={committed >= softCup ? Colors.good : Colors.beforeSoftCup}
        />
      </section>
      <ProgressLine
        ref={line2Ref}
        lineWidthRaw={middlePart?.css || '0'}
        color={Colors.good}
        layer1WidthPercent={middlePart?.percents?.committed || undefined}
        layer2WidthPercent={middlePart?.percents?.yourCommit || undefined}
      />
      {hardCupPart.css ? (
        <>
          <section ref={separator2Ref} className={st.separator}>
            <Separator text={isLine2NotSmall && isLine3NotSmall ? 'Hard Cap' : null} color={Colors.good} />
          </section>

          <ProgressLine
            ref={line3Ref}
            lineWidthRaw={hardCupPart?.css || '0'}
            color={Colors.afterHardCup}
            layer1WidthPercent={hardCupPart?.percents?.committed || undefined}
            layer2WidthPercent={hardCupPart?.percents?.yourCommit || undefined}
          />
        </>
      ) : (
        <div className={st['hard-cup']} style={(elementWidth || 0) < (badgeLength || 0) ? { opacity: 0 } : {}}>
          <Badge
            fix={!!(elementWidth && badgeNormalLength && badgeNormalLength > elementWidth - approximateBadgeWidth / 2)}
            ref={badgeRef}
            text="Hard Cap"
            color={committed >= hardCup ? Colors.good : undefined}
            untilHardCup={hardCup - committed}
          />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
