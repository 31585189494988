import { Address, Permissions, Subscriber } from 'everscale-inpage-provider';
import { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ILaunchPoolCallbackAbi from '../../abi/ton/ILaunchPoolCallback.abi.json';
import LaunchPoolAbi from '../../abi/ton/LaunchPool.abi.json';
import LaunchPoolParticipationAbi from '../../abi/ton/LaunchPoolParticipation.abi.json';
import { ReturnContext, ReturnRequest } from '../../providers/ReturnProvider';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { checkIsMyTonWalletDeployed } from '../../ton/check-is-my-ton-wallet-deployed';
import { getTransaction } from '../../ton/get-transaction';
import { TonSingleton } from '../../ton/ton-singleton';
import { subscribeOnX } from '../subscriber';

class RejectedByUserError extends Error {
  code: number;
  constructor() {
    super();
    this.message = 'Rejected by user';
    this.code = 3;
  }
}

const ton = TonSingleton.getInstance().client;

export type GetTONBalance = { address: string };
export type SendTON = { senderAddress: string; recipientAddress: string; amount: number };

export type FinishFundraisingByOwner = {
  myAddress: string;
  myPublicKey: string;
  launchPoolAddress: string;
};

const useTONCrystalWallet = () => {
  const {
    setIsMainnet,
    setAccountInteraction,
    setIsInstall,
    setIsCheck,
    setIsLogin,
    setIsAborted,
    setTONBalance,
    setIsDeployed,
    accountInteraction: _accountInteraction,
  } = useContext(TONWalletContext);
  const { setReturnEver, setReturnToken, setReturnProjectEver, setFinishFundraising } = useContext(ReturnContext);

  async function checkTonProviderInner() {
    try {
      const x = await ton?.hasProvider();
      return x;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Extension is not installed');
      return false;
    }
  }

  async function getAccountInteraction() {
    try {
      const currentProviderState = await ton?.getProviderState();
      // const isMainnet = !!(currentProviderState?.selectedConnection || '').toLocaleLowerCase().match(/main.?net/);
      // setIsMainnet(isMainnet);
      setIsMainnet(true);
      return currentProviderState?.permissions?.accountInteraction;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Get provider state failed');
      return undefined;
    }
  }

  const checkTonProvider = async () => {
    try {
      const res = await checkTonProviderInner();
      setIsInstall(!!res);
      const accountInteraction = await getAccountInteraction();
      setIsCheck(true);
      setAccountInteraction(accountInteraction);
      setIsLogin(!!accountInteraction);
      return accountInteraction || null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Ton provider checking failed', error);
      setIsCheck(true);
      setAccountInteraction(undefined);
      setIsLogin(false);
      return null;
    }
  };

  const getTONBalance = async ({ address }: GetTONBalance) => {
    try {
      const balance =
        (
          await ton?.getFullContractState({
            address: new Address(address),
          })
        )?.state?.balance || null;
      setTONBalance(balance ? String(+balance) : undefined);
      setIsDeployed((await checkIsMyTonWalletDeployed({})) || false);
      return balance;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('TON balance checking failed');
      return null;
    }
  };

  async function tonInpageProvider() {
    checkTonProvider();
    await ton?.ensureInitialized();
    const answer = await ton?.rawApi.requestPermissions({
      permissions: ['basic', 'accountInteraction'],
    });
    if (!answer || answer.accountInteraction == null) {
      throw new Error('Insufficient permissions');
    }
    getTONBalance({
      address: answer.accountInteraction.address,
    });
    return {
      accountInteraction: answer.accountInteraction as unknown as Permissions['accountInteraction'] | null,
    };
  }

  const connect = () => {
    setIsAborted(false);
    tonInpageProvider()
      .then(async (res) => {
        if (res?.accountInteraction) {
          setAccountInteraction(res.accountInteraction);
          setIsLogin(true);
          setIsDeployed((await checkIsMyTonWalletDeployed({})) || false);
        }
      })
      .catch((err) => {
        setIsAborted(true);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
  const disconnect = () => {
    ton
      ?.disconnect()
      .then(() => {
        setAccountInteraction(undefined);
        setIsLogin(false);
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  };

  const sendTON = async ({ senderAddress, recipientAddress, amount }: SendTON): Promise<boolean> => {
    const promiseFn = async () => {
      try {
        const answer = await ton?.rawApi.sendMessage({
          sender: senderAddress,
          recipient: recipientAddress,
          amount: String(Math.round(amount)),
          bounce: true, // Стёпа сказал, что true
        });

        if (!answer) {
          if (isMobile) {
            throw new RejectedByUserError();
          } else {
            throw new Error();
          }
        }

        return !answer.transaction.inMessage.bounced;
      } catch (error) {
        if (error) {
          throw new Error(JSON.stringify(error));
        } else {
          return false;
        }
      }
    };

    const answer = await subscribeOnX({
      onData: async ({ transactions }) => {
        const transaction = await getTransaction({
          src: recipientAddress,
          dst: senderAddress,
          transactions,
          abi: ILaunchPoolCallbackAbi,
          decodedMessageBody: '404',
        });
        return {
          isGoodAnswer: transaction ? +transaction.inMessage.value === 1 : undefined,
        };
      },
      promiseFn,
      x: senderAddress,
    });

    return answer ? !!answer.isGoodAnswer : false;
  };

  type OnGet = {
    launchPoolParticipationAddress: string;
    functionName: 'claimReward' | 'returnDepositSulpur' | 'returnDeposit';
    myAddress: string;
    myPublicKey: string;
  };
  type SendMessage = {
    functionName: OnGet['functionName'];
    myAddress: string;
    myPublicKey: string;
    launchPoolParticipationAddress: string;
    callbackId: string;
  };
  const getBack = async ({
    functionName,
    myAddress,
    launchPoolParticipationAddress,
    callbackId,
  }: SendMessage): Promise<boolean> => {
    const currentFunction = functionName === 'claimReward' ? setReturnToken : setReturnEver;

    currentFunction(ReturnRequest.start);
    try {
      // см. контракты Стёпы
      // claimReward - 1.5 TON
      // return-deposit-sulpur - 1 TON
      // return-deposit - 1 TON
      // 0.5 - страховка от меня
      const amount = String(1_000_000_000 * ((functionName === 'claimReward' ? 1.5 : 1) + 0.5));

      if (!ton) {
        currentFunction(ReturnRequest.bad);
        return false;
      }

      const contract = new ton.Contract(LaunchPoolParticipationAbi, new Address(launchPoolParticipationAddress));
      await contract.methods[functionName]({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        callbackId,
      }).send({
        from: new Address(myAddress),
        amount,
      });

      currentFunction(ReturnRequest.good);
      return true;
    } catch (error) {
      currentFunction(ReturnRequest.bad);
      if (error) {
        throw new Error(JSON.stringify(error instanceof TypeError ? new RejectedByUserError() : error));
      } else {
        return false;
      }
    }
  };

  const onGetBack = async ({
    launchPoolParticipationAddress,
    myAddress,
    myPublicKey,
    functionName,
  }: OnGet): Promise<boolean | null> => {
    const callbackId = String(Math.ceil(Math.random() * 1000000));
    const answer = await getBack({
      functionName,
      launchPoolParticipationAddress,
      myAddress,
      myPublicKey,
      callbackId,
    });
    return answer;
  };

  type AddTokenToTONCrystalWallet = {
    rootTokenContract: string;
  };
  const addTokenToTONCrystalWallet = async ({
    rootTokenContract,
  }: AddTokenToTONCrystalWallet): Promise<boolean | null> => {
    try {
      const accountInteraction = await getAccountInteraction();
      if (!accountInteraction?.address) return false;
      await ton?.addAsset({
        account: accountInteraction.address,
        params: {
          rootContract: new Address(rootTokenContract),
        },
        type: 'tip3_token',
      });
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return false;
    }
  };

  const finishFundraisingByOwner = async ({
    myAddress,
    launchPoolAddress,
  }: FinishFundraisingByOwner): Promise<void> => {
    setFinishFundraising(ReturnRequest.start);
    try {
      // см. контракты Стёпы
      // finishFundraising - 1 TON
      // 0.5 - страховка от меня
      const amount = String(1_000_000_000 * (1 + 0.5));

      if (!ton) {
        setFinishFundraising(ReturnRequest.bad);
        return;
      }

      const contract = new ton.Contract(LaunchPoolAbi, new Address(launchPoolAddress));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await contract.methods.finishFundraising({}).send({
        from: new Address(myAddress),
        amount,
      });
      setFinishFundraising(ReturnRequest.good);
    } catch (error) {
      setFinishFundraising(ReturnRequest.bad);
      if (error) {
        throw new Error(JSON.stringify(error instanceof TypeError ? new RejectedByUserError() : error));
      }
    }
  };

  type WithdrawalByOwner = {
    myAddress: string;
    launchPoolAddress: string;
    destinationAddress: string;
    withdrawalAmount: string;
  };
  const withdrawalByOwner = async ({
    myAddress,
    launchPoolAddress,
    destinationAddress,
    withdrawalAmount,
  }: WithdrawalByOwner): Promise<boolean | null> => {
    setReturnProjectEver(ReturnRequest.start);
    try {
      // В нагрузку отправлять 1 ever (c) Арина
      const amount = String(1_000_000_000 * 1);

      if (!ton) {
        setReturnProjectEver(ReturnRequest.bad);
        return null;
      }

      const contract = new ton.Contract(LaunchPoolAbi, new Address(launchPoolAddress));

      const transaction = await contract.methods
        .withdrawRaisedTokens({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          amount: withdrawalAmount,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          to: destinationAddress,
        })
        .send({
          from: new Address(myAddress),
          amount,
        });
      setReturnProjectEver(ReturnRequest.good);
      return !!transaction;
    } catch (error) {
      setReturnProjectEver(ReturnRequest.bad);
      if (error) {
        throw new Error(JSON.stringify(error instanceof TypeError ? new RejectedByUserError() : error));
      }
      return null;
    }
  };

  const onTransaction = async (address: string) => {
    getTONBalance({
      address,
    });
  };

  const subscribeOnTransactions = async (subscriber: Subscriber): Promise<void> => {
    try {
      const address = (await getAccountInteraction())?.address;
      if (!address) return;
      subscriber.transactions(address).makeProducer(
        () => onTransaction(address?.toString()),
        () => undefined
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    let subscriber: Subscriber | undefined;
    if (ton) {
      subscriber = new Subscriber(ton);
      subscriber.unsubscribe();

      subscribeOnTransactions(subscriber);
    }

    return () => {
      subscriber?.unsubscribe();
    };
  }, [_accountInteraction]);

  return {
    connect,
    disconnect,
    checkTonProvider,
    getTONBalance,
    sendTON,
    onGetBack,
    addTokenToTONCrystalWallet,
    finishFundraisingByOwner,
    withdrawalByOwner,
  };
};

export default useTONCrystalWallet;
