/* eslint-disable no-underscore-dangle */
import { Abi, BinaryLibrary, TonClient } from '@tonclient/core';
import { libWeb } from '@tonclient/lib-web';
import { endpoints } from '../constants/links';

const TonClientUseBinaryLibrary = TonClient.useBinaryLibrary; // hook fix ('use...')

export class ClientSingleton {
  protected static _instance: ClientSingleton;
  public client: TonClient;

  constructor() {
    if (ClientSingleton._instance) {
      throw new Error('Instantiation failed: use ClientSingleton.getInstance() instead of new.');
    }
    // fix
    TonClientUseBinaryLibrary(libWeb as unknown as () => Promise<BinaryLibrary>);
    this.client = new TonClient({ network: { endpoints } });
    ClientSingleton._instance = this;
  }

  public static getInstance(): ClientSingleton {
    if (ClientSingleton._instance) {
      return ClientSingleton._instance;
    }
    // eslint-disable-next-line no-return-assign
    return (ClientSingleton._instance = new ClientSingleton());
  }
}

export const tonClientRunLocal = async (
  abi: Abi,
  address: string,
  call_set: {
    function_name: string;
    input: object;
  }
): Promise<any | null> => {
  try {
    const { client } = ClientSingleton.getInstance();

    const [account, message] = await Promise.all([
      client.net
        .query_collection({
          collection: 'accounts',
          filter: {
            id: { eq: address },
          },
          result: 'boc',
        })
        .then(({ result }) => result[0]?.boc),
      client.abi
        .encode_message({
          abi,
          address,
          call_set,
          signer: { type: 'None' },
        })
        .then(({ message: m }) => m),
    ]);

    if (!account) return null;

    const response = await client.tvm.run_tvm({ message, account, abi });

    const value = response?.decoded?.output as unknown;

    return value;
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e);
    return null;
  }
};
