import BigNumber from 'bignumber.js';
import React, { useState } from 'react';
import { abbreviateNumber } from './number-formatters';

type Dot = '.' | ',';
type FractionDigits = {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};
export const formatAmount = (
  x: number | BigNumber,
  dot: Dot = '.',
  fractionDigits: FractionDigits = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
) => {
  return (x instanceof BigNumber ? +x.decimalPlaces(fractionDigits.maximumFractionDigits) : x)
    .toLocaleString('en', fractionDigits)
    .replaceAll(',', ' ')
    .replace('.', dot);
};

export type PriceProps = { amount: number; currency: string; rate?: number; br?: boolean };
export const formatPrice = ({ amount, currency, rate, br }: PriceProps) => (
  <>
    {formatAmount(amount)} {currency}
    {br ? <br /> : null}
    {rate ? ` (${formatAmount(amount * rate)} USDT)` : ''}
  </>
);

type PriceNewProps = { amount: number; currency: string | JSX.Element; rate?: number; colorizeCurrency?: boolean };
export const formatPriceNew = ({ amount, currency, rate, colorizeCurrency }: PriceNewProps) => {
  return (
    <>
      {abbreviateNumber(amount, {
        fixed: 2,
      })}{' '}
      <span style={colorizeCurrency ? { color: 'var(--color)' } : {}}>{currency}</span>
      {rate
        ? ` ~ ${abbreviateNumber(amount * rate, {
            fixed: 2,
          })} USDT`
        : ''}
    </>
  );
};

const getStringFromList = (list: string[], separator: string, before?: boolean) => {
  return list.map((showItem, i, arr) => {
    const separatorBefore = before && !i ? separator : '';
    const separatorAfter = i < arr.length - 1 ? separator : '';
    return (
      <span key={showItem}>
        {separatorBefore}
        {showItem}
        {separatorAfter}
      </span>
    );
  });
};

export type CurrenciesProps = { currencyList: string[] };
export const formatCurrencies = ({ currencyList }: CurrenciesProps) => {
  const N = 2;
  const SEPARATOR = ', ';

  const showList = currencyList.slice(0, N);
  const moreList = currencyList.slice(N);

  const [isFullListShow, setIsFullListShow] = useState(currencyList.length <= N);

  const onMoreClick = () => setIsFullListShow(true);

  const list = getStringFromList(showList, SEPARATOR).concat(
    isFullListShow
      ? getStringFromList(moreList, SEPARATOR, true)
      : [
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <span key="more" onClick={onMoreClick} style={{ whiteSpace: 'nowrap' }}>
            {SEPARATOR}
            <span className="initial">{N + 1} more</span>
          </span>,
        ]
  );

  return <span style={{ cursor: 'pointer' }}>{list}</span>;
};

export type DateProps = { date?: Date };
export const formatDate = ({ date }: DateProps) => {
  if (!date) return 'no date';

  const datePart = date
    .toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    .replaceAll(',', '')
    .replace(/^[a-z]{3}/i, '$&');

  const timezoneOffset = date.getTimezoneOffset();

  const replacer = (match: unknown, p1: string, p2: string, p3: string) => {
    const absOffset = Math.abs(timezoneOffset);
    const hours = absOffset / 60;
    const minutes = absOffset % 60;
    // eslint-disable-next-line no-nested-ternary
    const offsetPart = `${timezoneOffset > 0 ? '-' : timezoneOffset < 0 ? '+' : '±'}${Math.floor(
      hours
    ).toLocaleString()}${minutes > 0 ? p2 + minutes.toLocaleString().padStart(2, '0') : ''}`;

    const offset = ` UTC${offsetPart}`;
    return String(p1) + String(p2) + String(p3) + offset;
  };
  const timePart = date.toLocaleTimeString().replace(/^(\d{0,2})(\D+)(\d{0,2})(.+)/, replacer);

  return (
    <div>
      <span>{datePart}</span>
      <br />
      <span>{timePart}</span>
    </div>
  );
};

export type TONAddressProps = {
  address?: string;
  numberOfCharactersUpTo?: number;
  numberOfCharactersAfter?: number;
};
export const formatTONAddress = ({
  address,
  numberOfCharactersUpTo = 5,
  numberOfCharactersAfter = 5,
}: TONAddressProps) =>
  address
    ?.slice(0, 2 + numberOfCharactersUpTo)
    .concat('...')
    .concat(address.slice(-numberOfCharactersAfter) || '') || 'no address';

export const bytesArrayToString = (bytesArrayString: string) => {
  // return Buffer.from(bytesArrayString, 'hex').toString('utf8');

  let s = '';
  for (let i = 0; i < bytesArrayString.length; i += 2) {
    s += String.fromCharCode(parseInt(bytesArrayString.substr(i, 2), 16));
  }
  return decodeURIComponent(escape(s));

  // decimalToHex yourNumber.toString(16)
};

export const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
