import React from 'react';
import { Line } from '../line';
import st from './style.module.css';

export type ListItem = {
  title: string | JSX.Element;
  data: string | JSX.Element;
};
export type ListProps = {
  title?: string;
  titleWithSeparator?: boolean;
  list: ListItem[];
};
const List = ({ title, list, titleWithSeparator }: ListProps) => {
  return (
    <>
      {title && (
        <div className={`${st.title} ${list?.length ? '' : st.empty}`}>
          {title} {titleWithSeparator && <div className={st.separator} />}
        </div>
      )}
      {list.map(({ title: name, data }, i) => (
        <div key={`${name}${data}`}>
          <div className={st.item}>
            <div className={st.name}>{name}</div>
            <div className={st.data}>{data}</div>
          </div>
          {i !== list.length - 1 && <Line margin="16px 0" />}
        </div>
      ))}
    </>
  );
};

export default List;
