/* eslint-disable @typescript-eslint/ban-ts-comment */
const toFixedN = (number: number | null | undefined, fractionDigits = 8, canBeNull = true): string => {
  let num = number;

  // @ts-ignore
  num = +num;
  if (Number.isNaN(+num)) {
    return '-';
  }
  if (num === 0) {
    return canBeNull ? '0' : '~0';
  }
  if (num < 1) {
    // Cannot use parseFloat for small values due to exponential form
    // @ts-ignore
    num = num.toFixed(fractionDigits).replace(/\.0+$/, '');
  } else {
    num = parseFloat(num.toFixed(fractionDigits));
  }
  // @ts-ignore
  // eslint-disable-next-line no-nested-ternary
  return +num === 0 ? (canBeNull ? '0' : '~0') : num.toString();
};

const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'q', 'Q'];

export const abbreviateNumber = (number: number, params?: { fixed?: number }) => {
  // if zero, we don't need a suffix
  // eslint-disable-next-line eqeqeq
  if (number == 0) {
    return '0';
  }
  // what tier? (determines SI symbol)
  // eslint-disable-next-line no-bitwise
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  // eslint-disable-next-line no-restricted-properties
  const scale = Math.pow(10, tier * 3);
  // scale the number
  const scaled = number / scale;
  // format number and add suffix
  return [typeof params?.fixed === 'number' ? toFixedN(scaled, params?.fixed || 0) : scaled, suffix].join('');
};
