/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from 'react-reveal/Fade';
import { notificationIcons } from '../../common/icons';
import { links } from '../../constants/links';
import { ProjectTypeWithUserData } from '../../pages/Projects';
import { Context } from '../../providers/Provider';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import useTONCrystalWallet from '../../utils/hooks/useTONCrystalWallet';
import { Button } from '../button';
import { SmallCard } from '../small-card';
import { Spinner } from '../spinner';
import { TabsStatusEnum } from '../tabs/Tabs';
import { Notification } from './notification';
import st from './style.module.css';

const apply = () => {
  window
    .open(
      // eslint-disable-next-line max-len
      links.apply,
      '_blank'
    )
    ?.focus();
};

export type ProjectsProps = {
  projects: ProjectTypeWithUserData[];
  isLoading: boolean;
  status: TabsStatusEnum | undefined;
};

const Projects = ({ projects, isLoading, status }: ProjectsProps) => {
  const { htmlWidth, isMobileMenuShow } = useContext(Context);
  const { isLogin } = useContext(TONWalletContext);

  const { connect } = useTONCrystalWallet();

  if (status === 'Participated' && !isLogin)
    return (
      <Notification
        logo={notificationIcons.connectWalletIcon}
        paragraph={{
          title: 'Oops..',
          text: 'Connect your wallet to view participated projects',
        }}
        button={<Button text="CONNECT" onClick={connect} />}
      />
    );

  if (isLoading || !status)
    return (
      <Notification>
        <Spinner />
      </Notification>
    );

  if (projects.length || status === 'Live')
    return (
      <div className={st.box}>
        {projects.map((project, i) => (
          <Fade key={i}>
            <div className={`${st.card} ${htmlWidth < 350 ? st.mini : ''}`}>
              <SmallCard
                {...{ ...project, text: project.text.replaceAll(/<br.*?>/g, ' '), ...project.userData }}
                linkTo={undefined}
                projectCardLink={`/project/${project.poolNumber}`}
              />
            </div>
          </Fade>
        ))}
        {projects.map((project, i) => (
          <div key={`${i}-empty`} className={`${st.card} ${htmlWidth < 350 ? st.mini : ''}`} />
        ))}
        {projects.length < Math.floor((htmlWidth + (isMobileMenuShow ? 270 : 0)) / 350) &&
          new Array(Math.floor((htmlWidth + (isMobileMenuShow ? 270 : 0)) / 350)).fill('fix').map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}-empty2`} className={`${st.card} ${htmlWidth < 350 ? st.mini : ''}`} />
          ))}
        {!!projects?.length && status === 'Live' && <div className={`${st.card} ${htmlWidth < 350 ? st.mini : ''}`} />}
      </div>
    );

  return (
    <Notification
      logo={notificationIcons.noProjectsIcon}
      paragraph={{
        title: 'Oops..',
        text: 'There are no projects yet',
      }}
      button={<Button text="APPLY" onClick={apply} />}
    />
  );
};

export default Projects;

export type ProjectProps = {
  children: JSX.Element | JSX.Element[];
};
const Project = ({ children }: ProjectProps) => {
  return <div className={`${st.box} ${st.wrapper}`}>{children}</div>;
};

export { Project };
