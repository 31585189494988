/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { hamburger, hamburgerClose } from '../../common/icons';
import { Context } from '../../providers/Provider';
import menuButtons from './menu-buttons.module.css';

const MenuToggleButtonInner = () => {
  const { isMobileMenuShow, setIsMobileMenuShow } = useContext(Context);

  return (
    <span
      hidden={!isMobileMenuShow}
      onClick={() => setIsMobileMenuShow(!isMobileMenuShow)}
      className={menuButtons.close}
    />
  );
};

const MenuToggleButton = () => {
  const { setIsMobileMenuShow, isMobileMenuShow, isMobile } = useContext(Context);

  return (
    <div
      onClick={() => setIsMobileMenuShow(!isMobileMenuShow)}
      className={`${menuButtons.hamburger} ${isMobile ? '' : menuButtons.hide}`}
    >
      {isMobileMenuShow ? hamburgerClose : hamburger}
    </div>
  );
};

export { MenuToggleButtonInner, MenuToggleButton };
