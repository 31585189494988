import React from 'react';
import useResize from '../../utils/hooks/useResize';
import st from '../swap-tokens/style.module.css';

type WrapperProps = {
  children: JSX.Element | JSX.Element[];
  removePadding?: boolean;
};

const Wrapper = ({ children, removePadding }: WrapperProps) => {
  useResize({
    mobileWidth: 270 + 30 * 2 + 420,
  });

  return <div className={`${st.wrapper} ${removePadding ? st['remove-padding'] : ''}`}>{children}</div>;
};

export default Wrapper;
