import { useContext, useEffect } from 'react';
import { Context } from '../../providers/Provider';

export type UseResizeProps = {
  mobileWidth: number;
};

const useResize = ({ mobileWidth: incomingMobileWidth }: UseResizeProps) => {
  const mobileWidth = Math.max(incomingMobileWidth, 900);
  const { setIsMobile, setIsMobileMenuShow, setHtmlWidth, setMobileWidth } = useContext(Context);
  useEffect(() => {
    const fn = () => {
      const htmlWidth = document?.documentElement?.clientWidth;
      setHtmlWidth(htmlWidth || 0);
      setMobileWidth(mobileWidth);
      const result = htmlWidth < mobileWidth;
      setIsMobile(result);
      if (!result) setIsMobileMenuShow(result);
    };
    window.addEventListener('resize', fn);
    fn();
    return () => window.addEventListener('resize', fn);
  }, []);

  return null;
};

export default useResize;
