import React, { createContext, useState } from 'react';
import { Permissions } from 'everscale-inpage-provider';

type TONWalletContext = {
  TONBalance: string | undefined;
  setTONBalance: (TONBalance: string | undefined) => void;
  accountInteraction: Permissions['accountInteraction'] | undefined;
  setAccountInteraction: (accountInteraction: Permissions['accountInteraction'] | undefined) => void;
  isLogin: boolean | undefined;
  setIsLogin: (is: boolean) => void;
  isInstall: boolean | undefined;
  setIsInstall: (is: boolean) => void;
  isCheck: boolean | undefined;
  setIsCheck: (c: boolean) => void;
  isAborted: boolean | undefined;
  setIsAborted: (c: boolean) => void;
  isDeployed: boolean | undefined;
  setIsDeployed: (c: boolean) => void;
  isMainnet: boolean | undefined;
  setIsMainnet: (c: boolean) => void;
};

export const TONWalletContext = createContext<TONWalletContext>({
  TONBalance: undefined,
  setTONBalance: () => undefined,
  accountInteraction: undefined,
  setAccountInteraction: () => undefined,
  isLogin: undefined,
  setIsLogin: () => undefined,
  isInstall: undefined,
  setIsInstall: () => undefined,
  isCheck: undefined,
  setIsCheck: () => undefined,
  isAborted: undefined,
  setIsAborted: () => undefined,
  isDeployed: undefined,
  setIsDeployed: () => undefined,
  isMainnet: undefined,
  setIsMainnet: () => undefined,
});

const TONWalletProvider: React.FC = ({ children }) => {
  const [TONBalance, setTONBalance] = useState<string | undefined>();
  const [accountInteraction, setAccountInteraction] = useState<Permissions['accountInteraction'] | undefined>();
  const [isLogin, setIsLogin] = useState<boolean | undefined>();
  const [isInstall, setIsInstall] = useState<boolean | undefined>();
  const [isCheck, setIsCheck] = useState<boolean | undefined>();
  const [isAborted, setIsAborted] = useState<boolean | undefined>();
  const [isDeployed, setIsDeployed] = useState<boolean | undefined>();
  const [isMainnet, setIsMainnet] = useState<boolean | undefined>();

  const contextValue = {
    TONBalance,
    setTONBalance,
    accountInteraction,
    setAccountInteraction,
    isLogin,
    setIsLogin,
    isInstall,
    setIsInstall,
    isCheck,
    setIsCheck,
    isAborted,
    setIsAborted,
    isDeployed,
    setIsDeployed,
    isMainnet,
    setIsMainnet,
  };

  return <TONWalletContext.Provider value={contextValue}>{children}</TONWalletContext.Provider>;
};

export default TONWalletProvider;
