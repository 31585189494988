import React from 'react';
import st from './style.module.css';

export type ItemProps = {
  text?: string;
  anchor?: JSX.Element | string;
  id?: string; // todo
  children: JSX.Element | JSX.Element[];
  isError?: boolean;
};
const Item = ({ text, anchor, id, children, isError }: ItemProps) => {
  return (
    <div className={st.item}>
      {(text || anchor) && (
        <div className={st['item-top-group']}>
          <label htmlFor={id} className={`${st.label} ${isError ? st.error : ''}`}>
            {text}
          </label>
          <span className={st.anchor}>{anchor}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export default Item;
