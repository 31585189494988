/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { tooltipIcon } from '../../../common/icons';
import { links } from '../../../constants/links';
import { formatTONAddress } from '../../../utils/helpers';
import { useTooltip } from '../../../utils/hooks/useTooltip';
import { calculatedInfoText } from './calculated-info-text';
import st from './style.module.css';

export type UserAllocationProps = {
  amount: number;
  tokenSymbol: string;
  address: string;
};
const UserAllocation = ({ amount, tokenSymbol, address }: UserAllocationProps) => {
  const [boxWidth, setBoxWidth] = useState<number | undefined>();
  const [mainWidth, setMainWidth] = useState<number | undefined>();
  const [asideWidth] = useState(120);

  const { setTriggerRef, element } = useTooltip(
    <>
      {calculatedInfoText.map((p, i, arr) => (
        <div key={p}>
          <div>{p}</div>
          {i < arr.length - 1 && <br />}
        </div>
      ))}
    </>
  );

  const boxRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const calculateHiddenCondition = () => {
    return (boxWidth || 0) - (mainWidth || 0) < asideWidth;
  };

  useEffect(() => {
    const offsetWidth = boxRef?.current?.offsetWidth;
    setBoxWidth(offsetWidth || 0);
  }, [boxRef?.current?.offsetWidth]);

  useEffect(() => {
    const offsetWidth = mainRef?.current?.offsetWidth;
    setMainWidth(offsetWidth || 0);
  }, [mainRef?.current?.offsetWidth]);

  return (
    <div className={st.box}>
      <section ref={boxRef} className={st['text-section']}>
        <div ref={mainRef} className={st['main-part']}>
          <section className={st.title}>Your current allocation</section>
          <div className={st.body}>
            <section>
              <span>
                {amount
                  .toLocaleString('ru', {
                    maximumFractionDigits: 2,
                  })
                  .replace(',', '.')}
              </span>{' '}
              <span>{tokenSymbol}</span>
            </section>
            <section className={st.address}>
              <a
                href={`https://${links.tonscanHostName}/accounts/${address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatTONAddress({
                  address,
                  numberOfCharactersUpTo: 2,
                  numberOfCharactersAfter: 4,
                })}
              </a>
            </section>
          </div>
        </div>
        <div className={st['aside-part']} style={calculateHiddenCondition() ? { display: 'none' } : {}}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          How it's calculated
        </div>
      </section>
      <section className={st['info-section']}>
        <div ref={setTriggerRef}>{tooltipIcon}</div>
        {element}
      </section>
    </div>
  );
};

export default UserAllocation;
