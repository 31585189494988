import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import erc20Abi from '../abi/erc20/erc20.abi.json';
import vaultAbi from '../abi/erc20/Vault.abi.json';
import { useEvmWallet } from '../services/EvmWalletService';

export const getTokenAddress = async ({ web3, vault }: { web3: Web3; vault: string }): Promise<string | null> => {
  try {
    const vaultContract = await new web3.eth.Contract(vaultAbi as unknown as AbiItem, vault);
    if (!vaultContract) return null;

    const token = await (await vaultContract.methods.token()).call();

    return token || null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type Erc20Details = {
  vault: string;
  walletAddress: string;
};
export type TokenDetails = {
  decimals: number;
  tokenBalance: BigNumber;
  vaultBalance: BigNumber;
};
export const getErc20Details = async ({ vault, walletAddress }: Erc20Details): Promise<TokenDetails | null> => {
  try {
    const { web3 } = useEvmWallet();
    if (!web3) return null;

    const token = await getTokenAddress({
      vault,
      web3,
    });
    if (!token) return null;

    const contract = new web3.eth.Contract(erc20Abi as unknown as AbiItem, token);
    if (!contract) return null;

    const decimals = await (await contract.methods.decimals()).call();
    if (!decimals) return null;

    const tokenBalance = await (await contract.methods.balanceOf(walletAddress)).call();
    if (!tokenBalance) return null;

    const bTokenBalance = new BigNumber(String(tokenBalance));

    if (!(bTokenBalance.isPositive() || bTokenBalance.isZero())) return null;

    const vaultBalance = await (await contract.methods.balanceOf(vault)).call();
    if (!vaultBalance) return null;

    const bVaultBalance = new BigNumber(String(tokenBalance));
    if (!(bVaultBalance.isPositive() || bVaultBalance.isZero())) return null;

    return {
      decimals: +decimals,
      tokenBalance: bTokenBalance,
      vaultBalance: bVaultBalance,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type GetAllowance = {
  evmWallet: string;
  vault: string;
  token: string;
};
export const getAllowance = async ({ evmWallet, vault, token }: GetAllowance): Promise<string | null> => {
  try {
    const { web3 } = useEvmWallet();
    if (!web3) return null;

    const contract = new web3.eth.Contract(erc20Abi as unknown as AbiItem, token);
    if (!contract) return null;

    const outputs = await contract.methods.allowance(evmWallet, vault).call();
    if (!outputs) return null;

    return outputs;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

type SpecialToken = {
  address: string;
  chainId: number;
};
type Erc20Approve = {
  amount: string;
  evmWallet: string;
  vault: string;
  transactionType: '0x0' | '0x2';
  chainId: number;
  gasPrice?: string;
};
/**
 * transactionType:
 *
 * ETH 0x2
 * BSC 0x0
 * Fantom 0x0
 * Polygon 0x0
 */
export const getErc20Approve = async ({
  amount,
  evmWallet,
  vault,
  transactionType,
  chainId,
  gasPrice,
}: Erc20Approve): Promise<boolean | null> => {
  try {
    const { web3 } = useEvmWallet();
    if (!web3) return null;

    const token = await getTokenAddress({
      vault,
      web3,
    });
    if (!token) return null;

    const USDT_1: SpecialToken = {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      chainId: 1,
    };
    const specialTokens: SpecialToken[] = [USDT_1];

    const isSpecialToken = !!specialTokens.find((test) => test.chainId === chainId && test.address === token);

    const allowance = await getAllowance({
      evmWallet,
      vault,
      token,
    });
    if (!allowance || Number.isNaN(+allowance) || !Number.isFinite(+allowance)) return null;

    if (new BigNumber(allowance).isLessThan(new BigNumber(amount))) {
      const contract = new web3.eth.Contract(erc20Abi as unknown as AbiItem, token);
      if (!contract) return null;

      if (isSpecialToken) {
        const approve0 = await contract.methods.approve(vault, '0').send({
          from: evmWallet,
          type: transactionType,
          gasPrice,
        });
        if (!approve0) return null;
      }

      const approve = await contract.methods.approve(vault, amount).send({
        from: evmWallet,
        type: transactionType,
        gasPrice,
      });
      if (!approve) return null;
    }

    return true;
  } catch (error) {
    if (error) {
      throw new Error(JSON.stringify(error));
    } else {
      return null;
    }
  }
};

type GetDepositLimit = {
  vault: string;
};
export const getAvailableVaultDepositLimit = async ({ vault }: GetDepositLimit): Promise<BigNumber | null> => {
  try {
    const { web3 } = useEvmWallet();
    if (!web3) return null;

    const vaultContract = new web3.eth.Contract(vaultAbi as unknown as AbiItem, vault);
    if (!vaultContract) return null;

    const depositLimit = await vaultContract.methods.depositLimit().call();
    if (!depositLimit) return null;

    const bigNumber = new BigNumber(depositLimit);

    return bigNumber.isPositive() || bigNumber.isZero() ? bigNumber : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
