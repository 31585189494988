import RootTokenContractAbi from '../../abi/ton/TokenRoot.abi.json';
import { tonClientRunLocal } from '../ton-labs';

type GetDetails = { address: string };
type Details = {
  symbol: string;
  decimals: string;
};
export const getTokenDetails = async ({ address }: GetDetails): Promise<Details | null> => {
  try {
    const decimalsOutput = await tonClientRunLocal(
      {
        type: 'Contract',
        value: RootTokenContractAbi,
      },
      address,
      {
        function_name: 'decimals',
        input: {
          answerId: '0',
        },
      }
    );
    const symbolOutput = await tonClientRunLocal(
      {
        type: 'Contract',
        value: RootTokenContractAbi,
      },
      address,
      {
        function_name: 'symbol',
        input: {
          answerId: '0',
        },
      }
    );

    return decimalsOutput && symbolOutput?.value0
      ? {
          decimals: decimalsOutput?.value0 || '0',
          symbol: symbolOutput.value0,
        }
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
