import { links, WEVER } from '../constants/links';
import bridgeAssetsOldCommitJson from './bridgeAssetsOldCommit.json';

export type BridgeToken = {
  proxy: string;
  vaults: {
    chainId: string; // sting-number // сеть (56 - BSC)
    vault: string; // address: '0x...'
    ethereumConfiguration: string; // address: '0:...' // evmConfigAddress
    depositType: 'default' | 'credit'; // берём 'credit'
  }[];
};

type GetBridgeAssets = {
  name: string;
  token: {
    // токен ТОНа
    [address: string]: BridgeToken;
  };
};
export const getBridgeAssets = (): GetBridgeAssets => bridgeAssetsOldCommitJson as GetBridgeAssets;

export type Token = {
  name: string;
  chainId: number;
  symbol: string;
  decimals: number;
  address: string; // address: '0:...'
  logoURI: string; // url
  version: number;
};
type GetTonAssets = {
  $schema: string; // url
  name: string;
  version: {
    major: number;
    minor: number;
    patch: number;
  };
  keywords: string[];
  timestamp: string; // date string
  tokens: Token[];
};
export const getTonAssets = async (): Promise<GetTonAssets | null> => {
  const response = await fetch(links.tonAssets);
  if (response.ok) {
    return response.json();
  }
  // eslint-disable-next-line no-console
  console.error(`getTonAssets error: ${response.status}`);
  return null;
};

export const getWrapperTonRoot = async (): Promise<string | null> => {
  return WEVER;

  // const tonAssetsJson = await getTonAssets();
  // if (!tonAssetsJson) return null;

  // return tonAssetsJson.tokens.find((token) => ['WTON', 'WEVER'].includes(token.symbol))?.address || null;
};

type GetBridgeAssetsCredit = {
  name: string;
  creditors: {
    address: string; // '0:...';
    creditBody: string; // '123...'
  }[];
};
export const getBridgeAssetsCredit = async (): Promise<GetBridgeAssetsCredit['creditors'][0] | null> => {
  const response = await fetch(links.bridgeAssetsCredit);
  if (response.ok) {
    const json: GetBridgeAssetsCredit = await response.json();
    return json?.creditors?.[0] || null;
  }
  // eslint-disable-next-line no-console
  console.error(`getBridgeAssets error: ${response.status}`);
  return null;
};

export const getCreditorFactoryAddressHex = async (): Promise<string | null> => {
  const address = (await getBridgeAssetsCredit())?.address;
  return address ? address.replace('0:', '') : null;
};
