import React from 'react';
import st from './info-card.module.css';

export type InfoCardProps = {
  title: string;
  text: string | JSX.Element;
  icon?: JSX.Element;
};

const InfoCard = ({ title, text, icon }: InfoCardProps) => {
  return (
    <div className={st.box}>
      {!!icon && <section className={st['icon-section']}>{icon}</section>}
      <section className={st['main-section']}>
        <span className={st.title}>{title}</span>
        <span className={st.text}>{text}</span>
      </section>
    </div>
  );
};

export default InfoCard;
