import { TonClient } from '@tonclient/core';
import { Address, Transaction } from 'everscale-inpage-provider';
import { ClientSingleton } from './ton-labs';

export const decodeMessageBody = async (client: TonClient, abi: any, body?: string): Promise<string | null> => {
  if (!body) return null;
  try {
    const decodedBody = await client.abi.decode_message_body({
      abi: {
        type: 'Contract',
        value: abi,
      },
      body,
      is_internal: true,
    });
    return String(decodedBody.value.callbackId);
  } catch (error) {
    return null;
  }
};

type GetTransaction = {
  transactions: Pick<Transaction<Address>, 'inMessage'>[];
  src: string;
  dst: string;
  abi: any;
  decodedMessageBody: string;
};
export const getTransaction = async ({ transactions, src, dst, abi, decodedMessageBody }: GetTransaction) => {
  const { client } = ClientSingleton.getInstance();
  const promises = transactions
    .filter(({ inMessage }) => !!inMessage?.body && !!inMessage?.src && !!inMessage?.dst)
    .map(async (transaction) => {
      const decoded = await decodeMessageBody(client, abi, transaction?.inMessage?.body);
      return {
        transaction,
        decoded,
      };
    });
  const transactionsWithDecodedMessageBody = await Promise.all(promises);
  return transactionsWithDecodedMessageBody.length
    ? transactionsWithDecodedMessageBody.find(({ transaction: { inMessage }, decoded }) => {
        return (
          !!inMessage?.src &&
          String(inMessage.src) === src &&
          !!inMessage?.dst &&
          String(inMessage.dst) === dst &&
          decoded === decodedMessageBody
        );
      })?.transaction
    : transactions
        .filter(({ inMessage }) => !!inMessage?.src && !!inMessage?.dst)
        .find(({ inMessage }) => inMessage.bounced === true);
};

export const getAllTransactions = async (
  address: string,
  fixDate: Date
): Promise<Pick<Transaction<Address>, 'inMessage'>[] | null> => {
  try {
    const { client } = ClientSingleton.getInstance();
    const str = `query myQuery {
      transactions(
        filter: {account_addr: {
          eq: "${address}"
        }}
      ) {
        in_message {
          body,
          src,
          dst,
          value,
          created_at,
          bounced
        }
      }
    }`;

    const query = await client.net.query({
      query: str,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const transactions = query?.result?.data?.transactions;

    return transactions
      ? transactions
          .filter(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (transaction) =>
              (transaction.in_message.body || transaction.in_message.bounced === true) &&
              transaction.in_message.src &&
              transaction.in_message.dst &&
              transaction.in_message.created_at * 1000 > +fixDate
            // &&
            // // fix
            // transaction.in_message.created_at * 1000 < +fixDate + 30 * 60 * 1000
          )
          .sort((a: any, b: any) => +a.in_message.created_at - +b.in_message.created_at)
          .map((transaction: { in_message: Pick<Transaction<Address>, 'inMessage'> }) => ({
            inMessage: transaction.in_message,
          }))
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
