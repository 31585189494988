import React, { ForwardedRef, forwardRef } from 'react';
import { abbreviateNumber } from '../../../utils/number-formatters';
import { Colors } from '../common';
import { checkMarkSvg } from './checkMark';
import st from './style.module.css';

export type BadgeProps = {
  divRef?: React.ForwardedRef<unknown>;
  text: string | null;
  checkMark?: boolean;
  untilHardCup?: number;
  color?: Colors;
  fix?: boolean;
};

const Badge = ({ divRef, text, checkMark, untilHardCup, color, fix }: BadgeProps) => {
  const checkMarkElement = <section className={st['check-mark']}>{checkMarkSvg}</section>;
  const untilHardCupElement = untilHardCup ? (
    <section className={st['until-hard-cup']}>{`${abbreviateNumber(untilHardCup, {
      fixed: 2,
    })} until`}</section>
  ) : null;

  const status = checkMark ? checkMarkElement : untilHardCupElement;

  return (
    <span
      ref={divRef as ForwardedRef<HTMLInputElement>}
      className={`${st.box} ${fix ? st.fix : ''}`}
      style={
        color
          ? {
              color: `var(${color})`,
              fill: `var(${color})`,
            }
          : {}
      }
    >
      {status}
      {!!text && <section className={st.text}>{text}</section>}
    </span>
  );
};

export default forwardRef((props: Omit<BadgeProps, 'divRef'>, ref) => <Badge divRef={ref} {...props} />);
