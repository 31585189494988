import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './pages/main';
import MetaMaskProvider from './providers/MetaMaskProvider';
import Provider from './providers/Provider';
import ReturnProvider from './providers/ReturnProvider';
import TONWalletProvider from './providers/TONWalletProvider';
import { CheckTonProvider } from './ton/CheckTonProvider';
import { ScrollToTop } from './utils/ScrollToTop';

const App = () => {
  return (
    <Provider>
      <ReturnProvider>
        <TONWalletProvider>
          <MetaMaskProvider>
            <Router>
              <ScrollToTop />
              <CheckTonProvider />
              <Main />
            </Router>
          </MetaMaskProvider>
        </TONWalletProvider>
      </ReturnProvider>
    </Provider>
  );
};

export default App;
