/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { close } from '../../common/icons';
import st from './style.module.css';

export type PopupProps = {
  onCancel?: () => void;
  children?: JSX.Element | JSX.Element[];
  buttons?: JSX.Element;
  inWithdrawal?: boolean;
  className?: string;
  sectionClassName?: string;
};
const Popup = ({ onCancel, children, buttons, inWithdrawal, className, sectionClassName }: PopupProps) => {
  return (
    <div className={`${st.box} ${inWithdrawal ? st.withdrawal : ''} ${className || ''}`}>
      <section className={`${st['main-box']} ${sectionClassName || ''}`}>{children}</section>
      {!!buttons && <section className={st['buttons-group']}>{buttons}</section>}
      {onCancel && (
        <span className={st.close} onClick={onCancel}>
          {close}
        </span>
      )}
    </div>
  );
};

export default Popup;
