import React from 'react';
import { Badge, BadgeProps } from '../badge';
import st from './style.module.css';

export type SeparatorProps = BadgeProps;

const Separator = (props: SeparatorProps) => {
  const { color } = props;
  return (
    <div className={st.box}>
      <div
        className={st.separator}
        style={
          color
            ? {
                backgroundColor: `var(${color})`,
              }
            : {}
        }
      />
      <div className={st.badge}>
        <Badge {...props} />
      </div>
    </div>
  );
};

export default Separator;
