import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = (): null => {
  const { pathname } = useLocation();
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (!isFirst) {
      window.scrollTo(0, 0);
    }
    setIsFirst(false);
  }, [pathname]);

  return null;
};
