// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

class Singleton {
  protected static _instance: Singleton;

  private _activeEl;

  constructor(el, isActiveClass) {
    if (Singleton._instance) {
      throw new Error('Instantiation failed: use Singleton.getInstance() instead of new.');
    }
    this.el = el;
    this.isActiveClass = isActiveClass;

    window.addEventListener('resize', this.scrollToActiveItem.bind(this));

    this.scrollToActiveItem();
    this.px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    Singleton._instance = this;
  }

  get activeEl() {
    return this._activeEl || this.el.querySelector(`.${this.isActiveClass}`);
  }

  set activeEl(x) {
    this._activeEl = x;
  }

  scrollToActiveItem(x) {
    this.activeEl = x;
    this.isZooming();
    if (!x) return;

    const scrollParent = this.el.parentNode;
    const scrollParentWidth = scrollParent.clientWidth;
    const activeElWidth = x.clientWidth;
    const activeElLeftOffset = x.offsetLeft;

    const isActiveElInvisible = activeElLeftOffset * 2 + activeElWidth > scrollParentWidth;

    if (isActiveElInvisible) {
      scrollParent.scrollLeft = activeElLeftOffset - scrollParentWidth / 2 + activeElWidth / 2;
    } else {
      scrollParent.scrollLeft = 0;
    }
  }

  fix(el?: any) {
    const scrollParent = el?.parentNode || this.el.parentNode;
    var scrollHeight = scrollParent.scrollHeight;
    scrollParent.style.paddingBottom = `${scrollHeight}px`;
    scrollParent.style.marginBottom = `-${scrollHeight}px`;
  }

  isZooming() {
    var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio != this.px_ratio) {
      this.px_ratio = newPx_ratio;
      this.fix();
      return true;
    } else {
      return false;
    }
  }

  public static getInstance(el?: any, isActiveClass?: string): Singleton {
    if (Singleton._instance) {
      return Singleton._instance;
    }
    return (Singleton._instance = new Singleton(el, isActiveClass));
  }
}

export default Singleton;
