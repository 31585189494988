import React from 'react';
import { abbreviateNumber } from '../../../utils/number-formatters';
import { Colors } from '../common';
import st from './style.module.css';

type ColorObject = {
  key?: string;
  color: Colors;
  whiteTranslucentCanvas?: boolean;
};

export type InfoCardProps = {
  colors: ColorObject[];
  text: string;
  amount: number;
  currencyLabel: string;
};
const InfoCard = ({ colors, text, amount, currencyLabel }: InfoCardProps) => {
  return (
    <div className={st.box}>
      <section className={st.section}>
        {!!colors?.length && (
          <div className={st.colors}>
            {colors.map((color) => (
              <div
                key={color.key}
                className={`${st.color} ${color?.whiteTranslucentCanvas ? st['white-translucent-canvas'] : ''}`}
                style={{
                  backgroundColor: `var(${color?.color})`,
                }}
              />
            ))}
          </div>
        )}
        <div className={st.text}>{text}</div>
      </section>
      <section className={`${st.section} ${st['many-box']}`}>
        <span className={st.amount}>
          {abbreviateNumber(amount, {
            fixed: 2,
          })}
        </span>
        <span className={st['currency-label']}>{currencyLabel}</span>
      </section>
    </div>
  );
};

export default InfoCard;
