export const WEVER = '0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d';
const USDT = '0:a519f99bb5d6d51ef958ed24d337ad75a1c770885dcd42d51d6663f9fcdacfb2';

export const links = {
  apply:
    'https://docs.google.com/forms/d/e/1FAIpQLSePf7OyS6Xrjx9XzbwrXqbXfd6bVaElTdaKZEVWdHicasYN5g/viewform?usp=sf_link',

  metamask: 'https://metamask.io/',
  broxus: 'https://broxus.com/',
  extension: 'https://l1.broxus.com/everscale/wallet',
  tonbridgeHostName: 'octusbridge.io',
  tonscanHostName: 'everscan.io',

  tonAssets: 'https://raw.githubusercontent.com/broxus/ton-assets/master/manifest.json',
  bridgeAssets: 'https://raw.githubusercontent.com/broxus/bridge-assets/master/main.json',

  bridgeAssetsCredit: 'https://raw.githubusercontent.com/broxus/bridge-assets/master/credit.json',
};

// https://everos.dev/status
export const endpoints = ['mainnet.evercloud.dev/2d443299f60c48edb12c6676618685df'];
// export const endpoints = [
//   'https://eri01.net.everos.dev/',
//   'https://rbx01.net.everos.dev/',
//   'https://gra01.net.everos.dev/',
// ];
