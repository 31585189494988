import React from 'react';
import st from './style.module.css';

type ListProps = {
  margin?: string;
};

const List = ({ margin }: ListProps) => {
  return (
    <div
      className={st.line}
      style={
        margin
          ? {
              margin,
            }
          : {}
      }
    />
  );
};

export default List;
