import LaunchPoolAbi from '../../abi/ton/LaunchPool.abi.json';
import { tonClientRunLocal } from '../ton-labs';

export type LaunchPoolStatusEnum = 'Initializing' | 'AwaitTokens' | 'Active' | 'Vesting' | 'Complete' | 'Failed';

// eslint-disable-next-line no-shadow
export enum LaunchPoolStatus {
  Initializing,
  AwaitTokens,
  Active,
  Vesting,
  Complete,
  Failed,
}

// eslint-disable-next-line no-shadow
export enum MatchingStatuses {
  Initializing = 'Upcoming',
  AwaitTokens = 'Upcoming',
  Active = 'Live',
  Vesting = '', // нет пока нигде и может не будет (c)
  Complete = 'Finished',
  Failed = 'Failed',
}

export type SocialLink = {
  name: string;
  link: string;
};

export type VestingPeriods = {
  percent: string;
  unfreezeTime: string;
};

type GetDetails = { address: string };
type Details = {
  additionalProjectInfo: {
    projectDescription: string;
    projectImageUrl: string;
    projectName: string;
    projectLandingUrl: string;
    projectSocialLinks: SocialLink[];
  };
  amountOfInvestors: string;
  endTime: string;
  factory: string;
  hardCap: string;
  launchTokenRoot: string;
  paymentTokenRoot: string;
  owner: string;
  softCap: string;
  startTime: string;
  state: string;
  tokenWallet: string;
  tokensSupply: string;
  totalRaised: string; // Total Sales
  totalReturned: string;
  totalWithdraw: string;
  vestingPeriods: VestingPeriods[];
};
export const getProjectDetails = async ({ address }: GetDetails): Promise<Details | null> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolAbi,
      },
      address,
      {
        function_name: 'getDetails',
        input: {
          answerId: '0',
        },
      }
    );
    if (!output) return null;
    return (output?.value0 as Details) || null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type GetLaunchPoolParticipationAddress = { address: string; userAddress: string };
type LaunchPoolParticipationAddressOutput<T = string> = {
  value0: string | T;
};
type LaunchPoolParticipationAddress<T = string> = {
  launchPoolParticipationAddress: LaunchPoolParticipationAddressOutput['value0'] | T;
};
export const getLaunchPoolParticipationAddress = async ({
  address,
  userAddress,
}: GetLaunchPoolParticipationAddress): Promise<LaunchPoolParticipationAddress<null>> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolAbi,
      },
      address,
      {
        function_name: 'getLaunchPoolParticipationAddress',
        input: {
          _user: userAddress,
        },
      }
    );
    if (!output)
      return {
        launchPoolParticipationAddress: null,
      };
    return {
      launchPoolParticipationAddress: output?.value0 || null,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      launchPoolParticipationAddress: null,
    };
  }
};

type BuildPaymentPayload = {
  launchPoolAddress: string;
  userAddress?: string;
  callId: string;
};
type BuildPaymentPayloadOutput<T = string> = {
  value0: string | T;
};
type BuildPaymentPayloadCell<T = string> = {
  cell: BuildPaymentPayloadOutput['value0'] | T;
};
export const buildPaymentPayload = async ({
  launchPoolAddress,
  userAddress,
  callId,
}: BuildPaymentPayload): Promise<BuildPaymentPayloadCell<null>> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolAbi,
      },
      launchPoolAddress,
      {
        function_name: 'buildPaymentPayload',
        input: {
          _id: callId,
          _user: userAddress,
        },
      }
    );
    if (!output?.value0)
      return {
        cell: null,
      };
    return {
      cell: output?.value0 || null,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      cell: null,
    };
  }
};
