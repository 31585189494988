// Все контракты DEX для расчетов используют tip3_decimals

import BigNumber from 'bignumber.js';
import creditFactoryAbi from '../abi/ton/CreditFactory.abi.json';
import dexPairAbi from '../abi/ton/DexPair.abi.json';
import dexRootAbi from '../abi/ton/DexRoot.abi.json';
import { CONTRACT_FEE, EVER_WALLET_DEPLOY_FEE } from '../constants/marketConstants';
import { tonClientRunLocal } from '../ton/ton-labs';
import { getBridgeAssetsCredit, getCreditorFactoryAddressHex, getWrapperTonRoot } from './get-assets';
import { getAvailableVaultDepositLimit } from './web3';

type GetPairAddress = {
  dexRoot: string;
  wtonRoot: string;
  tokenRoot: string;
};
const getPairAddress = async ({ dexRoot, wtonRoot, tokenRoot }: GetPairAddress): Promise<string | null> => {
  try {
    const { value0 } = await tonClientRunLocal(
      {
        type: 'Contract',
        value: dexRootAbi,
      },
      dexRoot,
      {
        function_name: 'getExpectedPairAddress',
        input: {
          answerId: '0',
          left_root: wtonRoot,
          right_root: tokenRoot,
        },
      }
    );
    return (value0 as string) || null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

/**
 * доп расходы CreditFactory по процессингу платежа
 */
type GetCreditFactoryFee = {
  creditFactoryAddress: string;
};
const getCreditFactoryFee = async ({ creditFactoryAddress }: GetCreditFactoryFee): Promise<BigNumber | null> => {
  try {
    const {
      value0: { fee },
    } = await tonClientRunLocal(
      {
        type: 'Contract',
        value: creditFactoryAbi,
      },
      creditFactoryAddress,
      {
        function_name: 'getDetails',
        input: {
          answerId: 0,
        },
      }
    );
    if (!fee) return null;

    const bigNumber = new BigNumber(String(fee));

    return bigNumber.isPositive() || bigNumber.isZero() ? bigNumber : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

const MAX_SLIPPAGE = {
  numerator: '5',
  denominator: '1000',
};

type MaxBridgeFeeEver = {
  creditBody: BigNumber;
  creditFactoryFee: BigNumber;
  isAddExtraEvers: boolean;
};
export const getMaxBridgeFeeEver = ({ creditBody, creditFactoryFee, isAddExtraEvers }: MaxBridgeFeeEver) => {
  const participationGasFee = isAddExtraEvers
    ? CONTRACT_FEE.nanoEVER + EVER_WALLET_DEPLOY_FEE.nanoEVER
    : CONTRACT_FEE.nanoEVER;

  return creditBody
    .plus(creditFactoryFee)
    .plus(participationGasFee)
    .times(100)
    .div(100 - 10);
};

type GetExpectedSpend = {
  receiveTokenRoot: string;
  pairAddress: string;
  maxBridgeFeeEver: BigNumber;
};
type ExpectedSpend = {
  expectedAmount: BigNumber;
  expectedFee: BigNumber;
};
export const getExpectedSpendAmount = async ({
  pairAddress,
  maxBridgeFeeEver,
  receiveTokenRoot,
}: GetExpectedSpend): Promise<ExpectedSpend | null> => {
  try {
    const { expected_amount, expected_fee } = await tonClientRunLocal(
      {
        type: 'Contract',
        value: dexPairAbi,
      },
      pairAddress,
      {
        function_name: 'expectedSpendAmount',
        input: {
          answerId: 0,
          receive_amount: maxBridgeFeeEver.dp(0, BigNumber.ROUND_UP).toFixed(),
          receive_token_root: receiveTokenRoot,
        },
      }
    );
    return expected_amount && expected_fee
      ? {
          expectedAmount: new BigNumber(expected_amount),
          expectedFee: new BigNumber(expected_fee),
        }
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type CalculateMinAmount = {
  expectedAmount: ExpectedSpend['expectedAmount'];
  tip3Decimals: number;
  evmDecimals: number;
};
const calculateMinAmount = ({ expectedAmount, tip3Decimals, evmDecimals }: CalculateMinAmount) =>
  new BigNumber(expectedAmount).shiftedBy(-tip3Decimals).dp(Math.min(tip3Decimals, evmDecimals), BigNumber.ROUND_UP);

type GetExpectedExchange = {
  receiveTokenRoot: string;
  pairAddress: string;
  amount: BigNumber;
  tip3Decimals: number;
};
type ExpectedExchange = {
  expectedAmount: BigNumber;
  expectedFee: BigNumber;
};
export const getExpectedExchange = async ({
  pairAddress,
  amount,
  receiveTokenRoot,
  tip3Decimals,
}: GetExpectedExchange): Promise<ExpectedExchange | null> => {
  try {
    const result = await tonClientRunLocal(
      {
        type: 'Contract',
        value: dexPairAbi,
      },
      pairAddress,
      {
        function_name: 'expectedExchange',
        input: {
          answerId: 0,
          amount: new BigNumber(amount).shiftedBy(tip3Decimals).dp(0, BigNumber.ROUND_DOWN).toFixed(),
          spent_token_root: receiveTokenRoot,
        },
      }
    );
    if (!result) return null;
    const { expected_amount, expected_fee } = result;
    return expected_amount && expected_fee
      ? {
          expectedAmount: new BigNumber(expected_amount),
          expectedFee: new BigNumber(expected_fee),
        }
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type CalculateByNumerator = {
  numerator: string;
  expectedSpend: ExpectedExchange;
  minus: BigNumber;
};
const calculateByNumerator = ({ numerator, expectedSpend, minus }: CalculateByNumerator) => {
  const result = new BigNumber(expectedSpend.expectedAmount || 0)
    .times(new BigNumber(MAX_SLIPPAGE.denominator).minus(numerator))
    .div(MAX_SLIPPAGE.denominator)
    .minus(minus)
    .dp(0, BigNumber.ROUND_DOWN);
  return result;
};

export type ExpectedInvestedToLaunchPool = {
  medium: BigNumber;
  min: BigNumber;
  tonAmountForDepositToFactory: BigNumber;
};
type CalculateExpectedInvestedToLaunchPool = {
  tokenAmount: BigNumber;
  pairAddress: string;
  receiveTokenRoot: string;
  minTons: BigNumber;
  tip3Decimals: number;
};
/**
 * сколько TON мы получим, если цена не поменяется
 */
const calculateExpectedInvestedToLaunchPool = async ({
  tokenAmount,
  pairAddress,
  receiveTokenRoot,
  minTons,
  tip3Decimals,
}: CalculateExpectedInvestedToLaunchPool): Promise<ExpectedInvestedToLaunchPool | null> => {
  if (!tokenAmount.isPositive()) return null;
  const expectedSpend = await getExpectedExchange({
    pairAddress,
    amount: tokenAmount,
    receiveTokenRoot,
    tip3Decimals,
  });
  if (!expectedSpend) return null;

  return {
    medium: calculateByNumerator({
      numerator: MAX_SLIPPAGE.numerator,
      expectedSpend,
      minus: minTons,
    }),
    min: calculateByNumerator({
      numerator: '100',
      expectedSpend,
      minus: minTons,
    }),
    tonAmountForDepositToFactory: calculateByNumerator({
      numerator: '100',
      expectedSpend,
      minus: minTons.minus(CONTRACT_FEE.nanoEVER),
    }),
  };
};

// ============================================
type Amount = {
  tokenAmount: BigNumber;
  receiveTokenRoot: string;
};

type GetMinAmount = Omit<GetPairAddress, 'wtonRoot'> & {
  vault: string;
  evmDecimals: number;
  tip3Decimals: number;
  TONBalance?: string;
  isEverWalletDeployed: boolean;
};

type GetMaxAmount = {
  vault: string;
  userBalance: BigNumber;
  vaultBalance: BigNumber;
  evmDecimals: number;
  tip3Decimals: number;
};

export const getMaxAmount = async ({ evmDecimals, tip3Decimals, vault, userBalance, vaultBalance }: GetMaxAmount) => {
  const depositLimit = await getAvailableVaultDepositLimit({ vault });
  if (!depositLimit) return null;

  const minDecimals = Math.min(evmDecimals, tip3Decimals);

  const dUserBalance = new BigNumber(userBalance).shiftedBy(-evmDecimals).dp(minDecimals, BigNumber.ROUND_DOWN);
  const dAvailableLimit = new BigNumber(depositLimit)
    .minus(vaultBalance)
    .shiftedBy(-evmDecimals)
    .dp(minDecimals, BigNumber.ROUND_DOWN);

  return BigNumber.min(dAvailableLimit, dUserBalance);
};

export const getMinAmount = async ({
  dexRoot,
  tokenRoot,
  evmDecimals,
  tip3Decimals,
  vault,
  TONBalance,
  isEverWalletDeployed,
}: GetMinAmount) => {
  const wtonRoot = await getWrapperTonRoot();
  if (!wtonRoot) return null;

  const creditorFactoryAddressHex = await getCreditorFactoryAddressHex();
  if (!creditorFactoryAddressHex) return null;

  const bridgeAssetsCredit = await getBridgeAssetsCredit();
  if (!bridgeAssetsCredit) return null;

  const { creditBody } = bridgeAssetsCredit;

  const creditFactoryFee = await getCreditFactoryFee({
    creditFactoryAddress: `0:${creditorFactoryAddressHex}`,
  });
  if (!creditFactoryFee) return null;

  const maxBridgeFeeEver = getMaxBridgeFeeEver({
    creditBody: new BigNumber(creditBody),
    creditFactoryFee,
    isAddExtraEvers: new BigNumber(TONBalance || 0).shiftedBy(-9).isLessThan(10) || !isEverWalletDeployed,
  });
  if (!maxBridgeFeeEver) return null;

  const pairAddress = await getPairAddress({
    dexRoot,
    wtonRoot,
    tokenRoot,
  });
  if (!pairAddress) return null;

  const maxBridgeFee = await getExpectedSpendAmount({
    pairAddress,
    maxBridgeFeeEver,
    receiveTokenRoot: wtonRoot,
  });
  if (!maxBridgeFee) return null;

  const minAmount = calculateMinAmount({
    expectedAmount: maxBridgeFee.expectedAmount,
    tip3Decimals,
    evmDecimals,
  });
  if (!minAmount) return null;

  const depositLimit = await getAvailableVaultDepositLimit({
    vault,
  });
  if (!depositLimit) return null;

  return {
    pairAddress,
    minAmount,
    depositLimit,
  };
};

type Validate = {
  amount: BigNumber;
  userEvmBalance: BigNumber;
  minAmount: BigNumber;
  depositLimit: BigNumber;
  maxAmount: BigNumber;
};
export const validate = ({ amount, userEvmBalance, minAmount, maxAmount, depositLimit }: Validate) => {
  return amount.isLessThan(maxAmount) && amount.plus(minAmount).isLessThan(userEvmBalance);
};
