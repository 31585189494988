import React from 'react';
import { Modal } from '../../modal';
import { Popup } from '../../popup';
import { PaymentSteps } from '../../../bridge/payment-tracking';
import { cancel, checked } from '../../../common/icons';
import { TransactionLink } from '../../transaction-link';
import { Spinner } from '../../spinner';
import { NetworkOption, Networks } from '../../../utils/get-networks';
import { ButtonBox } from '../../content';
import st from '../style.module.css';

type InvestStepsProps = {
  isLogin?: boolean;
  paymentStepError: boolean;
  paymentStep: number;
  network: NetworkOption;
  transactionHash?: string;
};

const InvestSteps: React.FC<InvestStepsProps> = ({
  isLogin,
  paymentStep,
  paymentStepError,
  network,
  transactionHash,
}) => {
  if (!isLogin) {
    return (
      <Modal>
        <Popup className={st['invest-modal']}>
          <>
            <small>This feature is only available to authorized users</small>
            <div>Please log in</div>
            <ButtonBox />
          </>
        </Popup>
      </Modal>
    );
  }

  return (
    <Modal>
      <Popup className={st['invest-modal']}>
        <>
          <div className={st['please-wait-header']}>Purchase process</div>
          {network.chainId && transactionHash && (
            <div className={st.steps}>
              <div className={st.step}>
                {+paymentStep >= PaymentSteps.signaturesCollected ? (
                  <span className={st.checked}>{paymentStepError ? cancel : checked}</span>
                ) : (
                  <span className={st.number}>1</span>
                )}
                <span
                  className={`${st['step-text']} ${
                    +paymentStep >= PaymentSteps.signaturesCollected ? st['step-text-active'] : ''
                  }`}
                >
                  Transfer of tokens
                </span>
              </div>
              <div className={st.step}>
                {+paymentStep >= PaymentSteps.processedStatus ? (
                  <span className={st.checked}>{paymentStepError ? cancel : checked}</span>
                ) : (
                  <span className={st.number}>2</span>
                )}
                <span
                  className={`${st['step-text']} ${
                    +paymentStep >= PaymentSteps.processedStatus ? st['step-text-active'] : ''
                  }`}
                >
                  Bridge transfer
                </span>
              </div>
              <div className={st.step}>
                <span className={st.number}>3</span>
                <span className={st['step-text']}>Creating a participation contract</span>
              </div>
              <div className={st.error}>
                {paymentStepError && <span>Something went wrong!</span>}
                {network.chainId && transactionHash && (
                  <TransactionLink networkChainId={network.chainId} transactionHash={transactionHash} />
                )}
              </div>
            </div>
          )}
        </>
        <Spinner withWrapper backgroundColor="transparent" padding={0} />
        <div className={st['please-wait']}>
          Please wait it may take some time
          <br />
          <small>(about {network.value === Networks.freeTon ? 5 : 10} minutes)</small>
        </div>
      </Popup>
    </Modal>
  );
};

export default React.memo(InvestSteps);
