import React, { createContext, useState } from 'react';

type MetaMaskContext = {
  balance: string | undefined;
  setBalance: (balance: string | undefined) => void;
  isInstall: boolean | undefined;
  setIsInstall: (is: boolean) => void;
  isCheck: boolean | undefined;
  setIsCheck: (c: boolean) => void;
  isAborted: boolean | undefined;
  setIsAborted: (c: boolean) => void;
  chainId: number | undefined;
  setChainId: (c: number | undefined) => void;
  account: string | undefined;
  setAccount: (c: string | undefined) => void;
  isMetaMask: boolean | undefined;
  setIsMetaMask: (is: boolean) => void;
};

export const MetaMaskContext = createContext<MetaMaskContext>({
  balance: undefined,
  setBalance: () => undefined,
  isInstall: undefined,
  setIsInstall: () => undefined,
  isCheck: undefined,
  setIsCheck: () => undefined,
  isAborted: undefined,
  setIsAborted: () => undefined,
  chainId: undefined,
  setChainId: () => undefined,
  account: undefined,
  setAccount: () => undefined,
  isMetaMask: undefined,
  setIsMetaMask: () => undefined,
});

const MetaMaskProvider: React.FC = ({ children }) => {
  const [balance, setBalance] = useState<string | undefined>();
  const [isInstall, setIsInstall] = useState<boolean | undefined>();
  const [isCheck, setIsCheck] = useState<boolean | undefined>();
  const [isAborted, setIsAborted] = useState<boolean | undefined>();
  const [chainId, setChainId] = useState<number | undefined>();
  const [account, setAccount] = useState<string | undefined>();
  const [isMetaMask, setIsMetaMask] = useState<boolean | undefined>();

  const contextValue = {
    balance,
    setBalance,
    isInstall,
    setIsInstall,
    isCheck,
    setIsCheck,
    isAborted,
    setIsAborted,
    chainId,
    setChainId,
    account,
    setAccount,
    isMetaMask,
    setIsMetaMask,
  };

  return <MetaMaskContext.Provider value={contextValue}>{children}</MetaMaskContext.Provider>;
};

export default MetaMaskProvider;
