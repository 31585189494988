import { RefObject, useEffect, useState } from 'react';

const approximateBadgeWidth = 75;

export type UseBadgerProps = {
  ref: RefObject<HTMLElement>;
};
const useBadge = ({ ref }: UseBadgerProps) => {
  const [elementWidth, setElementWidth] = useState<number | undefined>();

  useEffect(() => {
    const offsetWidth = ref?.current?.offsetWidth;
    setElementWidth(offsetWidth || 0);
  }, [ref?.current?.offsetWidth]);

  return {
    // |1 + 5 + x + 5 + 1|
    isLineNotSmall: !!(elementWidth && elementWidth + (2 + 5 * 2) > approximateBadgeWidth),
    elementWidth,
    approximateBadgeWidth,
  };
};

export default useBadge;
