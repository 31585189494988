import { StatusEnum } from '../components/status/Status';

type Xxx = {
  status: string | undefined;
};

/**
 * TypeScript
 *
 * T: StatusEnum or TabsStatusEnum
 */
export const getStatus = <T = StatusEnum>({ status }: Xxx): T => {
  let option: string | null = null;
  switch (status?.toLocaleLowerCase()) {
    // case 'failed':
    case 'live':
    case 'upcoming':
    case 'finished':
    case 'participated':
      option = status;
      break;
    default:
      break;
  }

  return (option ? `${option[0].toLocaleUpperCase()}${option.slice(1)}` : 'Live') as unknown as T;
};
