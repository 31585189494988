import { Address, FullContractState, TransactionId, ProviderRpcClient } from 'everscale-inpage-provider';

import { TokenAbi } from './abi';
import { Token } from './types';

import { CONTRACT_FEE } from '../constants/marketConstants';

export type BalanceWalletRequest = {
  wallet: Address;
};

export type WalletAddressRequest = {
  root: Address;
  owner: Address;
};

export type TokenDetailsResponse = {
  decimals: number;
  rootOwnerAddress: Address;
  totalSupply: string;
};

function params<TRequired>(): <TOptional>(o: TOptional) => Partial<TOptional> & TRequired;
function params<TOptional>(o: TOptional): Partial<TOptional>;
// eslint-disable-next-line no-shadow
function params<T>(o?: T): Partial<T> | (<TOptional>(o: TOptional) => Partial<TOptional> & T) {
  if (o != null) {
    return o;
  }
  return ((oo: any) => oo) as any;
}

export class TokenWallet {
  static rpc = new ProviderRpcClient();

  public static async walletAddress(args: WalletAddressRequest, state?: FullContractState): Promise<Address> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, args.root);
    const { value0: tokenWallet } = await rootContract.methods
      .walletOf({
        answerId: 0,
        walletOwner: args.owner,
      })
      .call({ cachedState: state });

    return tokenWallet;
  }

  public static async balance(
    args: BalanceWalletRequest | WalletAddressRequest,
    state?: FullContractState
  ): Promise<string> {
    let { wallet } = args as BalanceWalletRequest;

    if (wallet == null) {
      wallet = await this.walletAddress(args as WalletAddressRequest);
    }

    const tokenWalletContract = new this.rpc.Contract(TokenAbi.Wallet, wallet);
    const { value0: balance } = await tokenWalletContract.methods
      .balance({
        answerId: 0,
      })
      .call({ cachedState: state });

    return balance.toString();
  }

  public static async balanceByTokenRoot(ownerAddress: Address, tokenRootAddress: Address): Promise<string> {
    try {
      const walletAddress = await TokenWallet.walletAddress({
        owner: ownerAddress,
        root: tokenRootAddress,
      });
      return await TokenWallet.balance({
        wallet: walletAddress,
      });
    } catch (e) {
      console.error(e);
      return '0';
    }
  }

  public static async balanceByWalletAddress(walletAddress: Address): Promise<string> {
    try {
      return await TokenWallet.balance({
        wallet: walletAddress,
      });
    } catch (e) {
      console.error(e);
      return '0';
    }
  }

  public static async getDetails(root: Address, state?: FullContractState): Promise<TokenDetailsResponse> {
    const [decimals, rootOwnerAddress, totalSupply] = await Promise.all([
      TokenWallet.getDecimals(root, state),
      TokenWallet.rootOwnerAddress(root, state),
      TokenWallet.totalSupply(root, state),
    ]);

    return {
      decimals,
      rootOwnerAddress,
      totalSupply,
    };
  }

  public static async getTokenFullDetails(root: string): Promise<Token | undefined> {
    const address = new Address(root);

    const { state } = await this.rpc.getFullContractState({ address });

    if (!state) {
      return undefined;
    }

    if (state.isDeployed) {
      const [decimals, name, symbol, details] = await Promise.all([
        TokenWallet.getDecimals(address, state),
        TokenWallet.getName(address, state),
        TokenWallet.getSymbol(address, state),
        TokenWallet.getDetails(address, state),
      ]);

      return {
        ...details,
        decimals,
        name,
        root,
        symbol,
      };
    }

    return undefined;
  }

  public static async getDecimals(root: Address, state?: FullContractState): Promise<number> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, root);
    const response = (await rootContract.methods.decimals({ answerId: 0 }).call({ cachedState: state })).value0;
    return parseInt(response, 10);
  }

  public static async getSymbol(root: Address, state?: FullContractState): Promise<string> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, root);
    return (
      await rootContract.methods.symbol({ answerId: 0 }).call({
        cachedState: state,
        responsible: true,
      })
    ).value0;
  }

  public static async getName(root: Address, state?: FullContractState): Promise<string> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, root);
    return (
      await rootContract.methods.name({ answerId: 0 }).call({
        cachedState: state,
        responsible: true,
      })
    ).value0;
  }

  public static async rootOwnerAddress(root: Address, state?: FullContractState): Promise<Address> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, root);
    return (
      await rootContract.methods.rootOwner({ answerId: 0 }).call({
        cachedState: state,
        responsible: true,
      })
    ).value0;
  }

  public static async totalSupply(root: Address, state?: FullContractState): Promise<string> {
    const rootContract = new this.rpc.Contract(TokenAbi.Root, root);
    return (
      await rootContract.methods.totalSupply({ answerId: 0 }).call({
        cachedState: state,
        responsible: true,
      })
    ).value0;
  }

  public static async send(
    args = params<{
      address: Address;
      recipient: Address;
      owner: Address;
      tokens: string;
    }>()({
      grams: CONTRACT_FEE.nanoEVER.toString(),
      payload: '',
      withDerive: false,
      bounce: true,
    })
  ): Promise<TransactionId> {
    let { address } = args;

    if (args.withDerive) {
      address = await this.walletAddress({
        owner: args.owner,
        root: args.address,
      });
    }

    const tokenWalletContract = new this.rpc.Contract(TokenAbi.Wallet, address);

    const { id } = await tokenWalletContract.methods
      .transfer({
        amount: args.tokens,
        recipient: args.recipient,
        payload: args.payload || '',
        notify: true,
        remainingGasTo: args.owner,
        deployWalletValue: '0',
      })
      .send({
        from: args.owner,
        bounce: args.bounce,
        amount: args.grams || CONTRACT_FEE.nanoEVER.toString(),
      });

    return id;
  }
}
