import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { isMobile as isMobileDevice } from 'react-device-detect';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';
import { arrow } from '../../common/icons';
import logo from '../../common/logo.svg';
import { MenuToggleButton } from '../../pages/main/menu-buttons';
import { Context } from '../../providers/Provider';
import { WalletsManager } from './elems/WalletsManager';
import st from './style.module.css';

export type ContentProps = {
  backTo?: string;
  title: string;
  children: JSX.Element | JSX.Element[];
};

const getLibrary = (provider: any) => new Web3(provider);

const Content = ({ backTo, title, children }: ContentProps) => {
  const { isMobile, isMobileMenuShow } = useContext(Context);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className={st.box}>
        {isMobile && (
          <div className={`${st['mobile-top']} ${!isMobileMenuShow ? st['mobile-top-closed'] : ''}`}>
            {isMobile && isMobileMenuShow && <WalletsManager inMobileMenu />}
            {isMobile && !isMobileMenuShow && (
              <NavLink to="/" style={{ display: 'flex' }}>
                <img height={48} src={logo} alt="" />
              </NavLink>
            )}
            <div>
              <MenuToggleButton />
            </div>
          </div>
        )}
        <header className={`${st.header} ${isMobileDevice && backTo ? st.mobile : ''}`}>
          {backTo ? (
            <div className={`${st.title} ${st['back-title']}`}>
              <NavLink to={backTo} className={st.link}>
                {arrow}
                <span>{title}</span>
              </NavLink>
            </div>
          ) : (
            <h1 className={st.title}>{title}</h1>
          )}
          {!isMobile ? <WalletsManager /> : null}
        </header>
        <main>{children}</main>
      </div>
    </Web3ReactProvider>
  );
};

export default Content;
