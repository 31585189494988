/* eslint-disable no-underscore-dangle */
const cutDecimals = (number: number, numberOfDecimalPlaces: number) => {
  if (Number.isFinite(number)) {
    const x = 10 ** numberOfDecimalPlaces;
    return Math.floor(number * x) / x;
  }
  return null;
};

export type CommonCalculatedReturn = {
  part: number | null;
  committedPercent: number | null;
  yourCommitPercent: number | null;
};

type CalculateSoftCupPart = {
  committed: number;
  yourCommit: number | null;
  hardCup: number;
  softCup: number;
};
export const calculateSoftCupPart = ({
  committed,
  yourCommit,
  hardCup,
  softCup,
}: CalculateSoftCupPart): CommonCalculatedReturn => {
  const fullAmount = Math.max(committed, hardCup);

  const _part = softCup > 0 ? softCup : null;
  const part = _part ? cutDecimals(softCup / fullAmount, 10) : null;
  const committedPercent = Math.min((committed / softCup) * 100, 100);
  const yourCommitPercent = Math.min(yourCommit ? (yourCommit / softCup) * 100 : 0, 100) || null;

  return {
    part,
    committedPercent,
    yourCommitPercent,
  };
};

type CalculateMiddlePart = {
  committed: number;
  yourCommit: number | null;
  hardCup: number;
  softCup: number;
};
export const calculateMiddlePart = ({
  committed,
  yourCommit,
  hardCup,
  softCup,
}: CalculateMiddlePart): CommonCalculatedReturn => {
  const fullAmount = Math.max(committed, hardCup);

  const middleFullAmount = hardCup - softCup;
  const middleCommittedAmount = committed - softCup;
  const middleYourCommitAmount = (yourCommit || 0) - softCup;

  const _part = middleFullAmount > 0 ? middleFullAmount : null;
  const part = _part ? cutDecimals(middleFullAmount / fullAmount, 10) : null;
  const committedPercent =
    middleCommittedAmount > 0 ? Math.min((middleCommittedAmount / middleFullAmount) * 100, 100) : null;
  const yourCommitPercent =
    middleYourCommitAmount > 0
      ? Math.min(middleYourCommitAmount ? (middleYourCommitAmount / middleFullAmount) * 100 : 0, 100) || null
      : null;

  return {
    part,
    committedPercent,
    yourCommitPercent,
  };
};

type CalculateHardCupPart = {
  committed: number;
  yourCommit: number | null;
  hardCup: number;
};
export const calculateHardCupPart = ({
  committed,
  yourCommit,
  hardCup,
}: CalculateHardCupPart): CommonCalculatedReturn => {
  const fullAmount = Math.max(committed, hardCup);

  const deltaFullAmount = fullAmount - hardCup;
  const deltaCommittedAmount = committed - hardCup;
  const deltaYourCommitAmount = (yourCommit || 0) - hardCup;

  const _part = deltaFullAmount > 0 ? deltaFullAmount : null;
  const part = _part ? cutDecimals(deltaFullAmount / fullAmount, 10) : null;
  const committedPercent =
    deltaCommittedAmount > 0 ? Math.min((deltaCommittedAmount / deltaFullAmount) * 100, 100) : null;
  const yourCommitPercent =
    deltaYourCommitAmount > 0
      ? Math.min(deltaYourCommitAmount ? (deltaYourCommitAmount / deltaFullAmount) * 100 : 0, 100) || null
      : null;

  return {
    part,
    committedPercent,
    yourCommitPercent,
  };
};
