import React from 'react';
import { abbreviateNumber } from '../../utils/number-formatters';
import { Currency } from '../../components/small-card/Card';
import st from './info-block.module.css';

export type InfoCardProps = {
  title: string;
  value: number;
  currency?: Currency;
};

const InfoCard = ({ title, value, currency }: InfoCardProps) => {
  return (
    <div className={st.card}>
      <section className={st.title}>{title}</section>
      <section className={st.value}>
        {`${abbreviateNumber(value, {
          fixed: 2,
        })} ${currency?.symbol || ''}`}
      </section>
    </div>
  );
};

type InfoBlockProps = {
  cards: InfoCardProps[];
  currency?: Currency;
};

const InfoBlock = ({ cards, currency }: InfoBlockProps) => {
  if (!cards.length) return null;

  return (
    <ul className={st.list}>
      {cards.map((card) => (
        <li key={card.title} className={st.item}>
          <InfoCard {...card} currency={currency} />
        </li>
      ))}
    </ul>
  );
};

export default InfoBlock;
