import React from 'react';
import { isMobile } from 'react-device-detect';
import st from './subinvest.module.css';

const MOBILE_TEXT = (
  <>
    To deposit through another network, <br /> use the desktop version.
  </>
);

export type SubinvestProps = {
  paragraph?: {
    title?: string | JSX.Element;
    text?: string | JSX.Element | (string | JSX.Element)[];
  } | null;
  children?: JSX.Element | null;
};
const Subinvest = ({ paragraph, children }: SubinvestProps) => {
  const { title, text } = paragraph || {};

  if (title || text || children || isMobile)
    return (
      <div className={st.box} style={title || text ? { width: '100%' } : undefined}>
        {(title || text) && (
          <div className={st.paragraph}>
            {title && <section className={st.title}>{title}</section>}
            {Array.isArray(text)
              ? !!text.length && text.map((line) => !!line && <section className={st.text}>{line}</section>)
              : !!text && <section className={st.text}>{text}</section>}
          </div>
        )}
        {children && (
          <div className={st.buttons}>
            <div>{children}</div>
          </div>
        )}
      </div>
    );
  return null;
};

export default Subinvest;
