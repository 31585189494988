/* eslint-disable max-len */
export const howItWorksText = [
  [
    'Tokens For Sale — how many tokens are available for purchase',
    'SoftСap — minimum threshold for funds raised to close the seal',
    'HardСap — maximum amount of funds raised that participate in the sale',
    'Vesting — number of stages and dates in which tokens will be unlocked',
  ],
  [
    'You can participate in the sale using EVER in the Everscale network, as well as various tokens in the Ethereum, Fanton, BSC and Polygon networks. If the HardCap is exceeded, only part of the funds of the participants will be used. The unused part can be obtained after the end of the event.',
  ],
  ['The number of project tokens that each participant receives is calculated using the formula:'],
  [
    'added assets of participant / total assets added * number of tokens to purchase = number of tokens a member receives',
  ],
];
