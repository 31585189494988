import React from 'react';
import st from './card-header.module.css';

export type CardHeaderProps = {
  title: string | JSX.Element;
  children?: JSX.Element;
  growTitle?: boolean;
};

const CardHeader = ({ title, children, growTitle }: CardHeaderProps) => {
  return (
    <div>
      <div className={st.box}>
        <div className={st.title}>{title}</div>
        <div className={`${st.button} ${!children || growTitle ? st['grow-title'] : ''}`}>{children}</div>
      </div>
    </div>
  );
};

export default CardHeader;
