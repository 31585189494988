import { RefObject, useLayoutEffect, useState } from 'react';
import {
  calculateHardCupPart,
  calculateMiddlePart,
  calculateSoftCupPart,
  CommonCalculatedReturn,
} from './calculations';

type GetCalcString = {
  separator1Width: number | undefined;
  separator2Width: number | undefined;
  calculatedResult: CommonCalculatedReturn | null;
};
const getParamsObject = ({ separator1Width, separator2Width, calculatedResult }: GetCalcString) => {
  return {
    css: calculatedResult?.part
      ? `calc((100% - ${separator1Width || 0}px - ${separator2Width || 0}px) * ${calculatedResult?.part})`
      : null,
    percents: calculatedResult
      ? {
          committed: calculatedResult.committedPercent,
          yourCommit: calculatedResult.yourCommitPercent,
        }
      : null,
  };
};

export type UseProgressBarProps = {
  committed: number;
  yourCommit: number | null;
  softCup: number;
  hardCup: number;
  separator1Ref: RefObject<HTMLElement>;
  separator2Ref: RefObject<HTMLElement>;
};
const useProgressBar = ({
  committed,
  yourCommit,
  softCup,
  hardCup,
  separator1Ref,
  separator2Ref,
}: UseProgressBarProps) => {
  const [separator1Width, setSeparator1Width] = useState<number | undefined>();
  const [separator2Width, setSeparator2Width] = useState<number | undefined>();

  useLayoutEffect(() => {
    const offsetWidth = separator1Ref?.current?.offsetWidth;
    setSeparator1Width(offsetWidth || 0);
  }, [separator1Ref?.current]);

  useLayoutEffect(() => {
    const offsetWidth = separator2Ref?.current?.offsetWidth;
    setSeparator2Width(offsetWidth || 0);
  }, [separator2Ref?.current]);

  return {
    softCupPart: getParamsObject({
      separator1Width,
      separator2Width,
      calculatedResult: calculateSoftCupPart({
        committed,
        yourCommit,
        hardCup,
        softCup,
      }),
    }),
    middlePart: getParamsObject({
      separator1Width,
      separator2Width,
      calculatedResult: calculateMiddlePart({
        committed,
        yourCommit,
        hardCup,
        softCup,
      }),
    }),
    hardCupPart: getParamsObject({
      separator1Width,
      separator2Width,
      calculatedResult: calculateHardCupPart({
        committed,
        yourCommit,
        hardCup,
      }),
    }),
  };
};

export default useProgressBar;
type UseProgressBarReturnType = ReturnType<typeof useProgressBar>;
export type { UseProgressBarReturnType };
