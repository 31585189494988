import React from 'react';
import { globe as globeElement, social } from '../../common/icons';
import { SocialLink } from '../../ton/utils/LaunchPool';
import { Button } from '../button';
import { CommonSmallCardProps, SmallCard } from '../small-card';
import st from './style.module.css';

export type CardProps = {
  socialLinks?: SocialLink[];
  linkTo?: string;
  children?: JSX.Element;
} & CommonSmallCardProps;

const Card = ({ socialLinks, linkTo, logoUrl, status, title, text, children, ...rest }: CardProps) => {
  const filteredSocial = social.filter(({ key }) => socialLinks?.find((link) => link.name === key));
  type FilteredSocial = typeof filteredSocial;

  const hrefTo = linkTo?.match(/^http(s?):\/\//) ? linkTo : undefined;
  type Globe = FilteredSocial[0];
  const SITE_NAME = 'site';
  const globe: Globe = {
    element: globeElement,
    key: SITE_NAME,
  };

  const links: SocialLink[] = (
    hrefTo
      ? [
          {
            name: SITE_NAME,
            link: hrefTo,
          },
        ]
      : []
  ).concat(socialLinks || []);
  const linkObjectArr: FilteredSocial = [globe].concat(filteredSocial || []);

  return (
    <div className={st.box}>
      <section className={st['small-card']}>
        <SmallCard isEmbed logoUrl={logoUrl} status={status} title={title} text={text} linkTo={undefined} {...rest}>
          <>
            {linkObjectArr?.map(({ key, element }) => {
              const socialLinkStr = links?.find((link) => link.name === key)?.link;
              const socialHref = socialLinkStr?.match(/^http(s?):\/\//) ? socialLinkStr : undefined;
              return (
                <a
                  className={st['icon-wrapper']}
                  key={key}
                  href={socialHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {element}
                </a>
              );
            })}
          </>
        </SmallCard>
      </section>
      <section className={st.card}>{children}</section>
    </div>
  );
};

export default Card;
