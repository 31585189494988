import React from 'react';
import { formatDate } from '../../../utils/helpers';
import { abbreviateNumber } from '../../../utils/number-formatters';
import { useTooltip } from '../../../utils/hooks/useTooltip';
import st from './style.module.css';

export type VestingCardProps = {
  mainSection: {
    percent: number;
    date: Date;
  };
  userSection?: {
    amount: number;
    currencyLabel: string;
  };
};

const VestingCard = ({ mainSection: { percent, date }, userSection }: VestingCardProps) => {
  const { amount, currencyLabel } = userSection || {};

  return (
    <div className={st.box}>
      <section className={`${st.section} ${userSection ? '' : st.anonymous}`}>
        <span className={st.percent}>
          {percent?.toLocaleString('en', {
            maximumFractionDigits: 2,
          })}
          %
        </span>
        <span className={st.date}>{date?.toLocaleDateString().replaceAll('/', '.')}</span>
      </section>
      {userSection && (
        <section className={st.section}>
          <span className={st.text}>Your part</span>
          <span className={st['amount-box']}>
            <span>
              {abbreviateNumber(amount || 0, {
                fixed: 2,
              })}
            </span>
            <span>{currencyLabel}</span>
          </span>
        </section>
      )}
    </div>
  );
};

export default VestingCard;
