import React, { ForwardedRef, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fade from 'react-reveal/Fade';
import st from './arrow.module.css';
import { HideObject } from './Cut';

type ArrowProps = {
  svgRef: React.ForwardedRef<unknown>;
  type?: 'prev' | 'next';
} & HideObject;

const Arrow = ({ svgRef, type = 'next', isHidePrevArrow, isHideNextArrow }: ArrowProps) => {
  const hideCheck = () => {
    const isVisible = type === 'prev' ? isHidePrevArrow : isHideNextArrow;
    return isMobile ? true : isVisible;
  };

  if (isMobile) return null;

  return (
    <div
      ref={svgRef as ForwardedRef<HTMLDivElement>}
      className={`${st.arrow} ${type === 'prev' ? st['arrow-prev'] : st['arrow-next']} ${hideCheck() ? '' : st.active}`}
    >
      <Fade when={!hideCheck()}>
        <svg width="42" height="42" viewBox="10 5 42 42" xmlns="http://www.w3.org/2000/svg" className={st.fix}>
          <g filter={`url(#filter_${type})`}>
            <path
              // eslint-disable-next-line max-len
              d="M10 26C10 14.402 19.402 5 31 5C42.598 5 52 14.402 52 26C52 37.598 42.598 47 31 47C19.402 47 10 37.598 10 26Z"
              fill="inherit"
            />
            <mask
              id={`mask_${type}`}
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="22"
              y="17"
              width="18"
              height="18"
            >
              <path
                // eslint-disable-next-line max-len
                d="M26.4715 17.4735C25.8428 18.1022 25.8428 19.1178 26.4715 19.7465L32.7261 26.001L26.4715 32.2556C25.8428 32.8842 25.8428 33.8998 26.4715 34.5285C27.1002 35.1572 28.1157 35.1572 28.7444 34.5285L36.1435 27.1294C36.7722 26.5007 36.7722 25.4852 36.1435 24.8565L28.7444 17.4574C28.1319 16.8449 27.1002 16.8449 26.4715 17.4735Z"
                fill="black"
              />
            </mask>
            <g mask={`url(#mask_${type})`}>
              <rect x="22" y="17" width="18" height="18" fill="#151939" />
            </g>
          </g>
          <defs>
            <filter
              id={`filter_${type}`}
              x="0"
              y="0"
              width="62"
              height="62"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="5" />
              <feGaussianBlur stdDeviation="5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.296302 0 0 0 0 0.258229 0 0 0 0 0.558333 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1242_3967" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1242_3967" result="shape" />
            </filter>
          </defs>
        </svg>
      </Fade>
    </div>
  );
};

export default forwardRef((props: Omit<ArrowProps, 'svgRef'>, ref) => <Arrow svgRef={ref} {...props} />);
