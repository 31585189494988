/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import { ProviderRpcClient } from 'everscale-inpage-provider';

export class TonSingleton {
  protected static _instance: any;
  public client: ProviderRpcClient | undefined;

  constructor() {
    if (TonSingleton._instance) {
      throw new Error(`Instantiation failed: use TonSingleton.getInstance() instead of new`);
    }
    let client: ProviderRpcClient | undefined;
    try {
      client = new ProviderRpcClient();
      // eslint-disable-next-line no-empty
    } catch (error) {}
    this.client = client;
    TonSingleton._instance = this;
  }

  public static getInstance(): TonSingleton {
    if (TonSingleton._instance) {
      return TonSingleton._instance;
    }
    return (TonSingleton._instance = new TonSingleton());
  }
}
