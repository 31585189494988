import React from 'react';
import { Button } from '../../components/button';
import { Modal } from '../../components/modal';
import { Popup } from '../../components/popup';
import { formatAmount } from '../../utils/helpers';
import st from './confirm-modal.module.css';

type ConfirmModalProps = {
  setIsModalShow: (bool: boolean) => void;
  onConfirm: () => void;

  currencySymbol: string;
  ownerWalletAddress: string;
  withdrawalAmount: number;
};

const ConfirmModal = ({
  setIsModalShow,
  onConfirm,
  ownerWalletAddress,
  withdrawalAmount,
  currencySymbol,
}: ConfirmModalProps) => {
  return (
    <Modal setIsShow={setIsModalShow} inWithdrawal>
      <Popup
        inWithdrawal
        buttons={
          <>
            <Button
              isInvert
              isCentred
              text="Cancel"
              width="100%"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsModalShow(false);
              }}
            />
            <Button
              isCentred
              text="Withdraw"
              width="100%"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onConfirm();
                setIsModalShow(false);
              }}
            />
          </>
        }
      >
        <div className={st.box}>
          <section className={st.title}>Make sure the data being sent is correct</section>
          <section className={st.section}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <div className={st.key}>Recipient's address</div>
            <div className={st.value}>{ownerWalletAddress}</div>
          </section>
          <section className={st.section}>
            <div className={st.key}>Amount to withdraw</div>
            <div className={`${st.value} ${st['withdrawal-amount']}`}>
              {formatAmount(withdrawalAmount)} {currencySymbol}
            </div>
          </section>
          {/* <section className={st.q}>Are you sure?</section> */}
        </div>
      </Popup>
    </Modal>
  );
};

export default ConfirmModal;
