import LaunchPoolParticipationAbi from '../../abi/ton/LaunchPoolParticipation.abi.json';
import { tonClientRunLocal } from '../ton-labs';

type GetDetails = { address: string };
type Details = {
  launchPool: string;
  user: string;
  depositAmount: string;

  lastClaim: string;
  sulpurReturned: string;
  depositReturned: string;
};
export const getUserDetails = async ({ address }: GetDetails): Promise<Details | null> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolParticipationAbi,
      },
      address,
      {
        function_name: 'getDetails',
        input: {
          answerId: '0',
        },
      }
    );
    if (!output) return null;
    return (output?.value0 as Details) || null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
