/* eslint-disable no-underscore-dangle */
import { getCreditEthereumEventConfigurationDetails, getProxyTokenTransferDetails } from '.';

type GetAddresses = {
  evmConfigAddress: string;
  chainId: number;
};

export const getAddresses = async ({ evmConfigAddress, chainId }: GetAddresses) => {
  const creditEthereumEventConfigurationDetails = await getCreditEthereumEventConfigurationDetails({
    address: evmConfigAddress,
  });
  if (!creditEthereumEventConfigurationDetails) return null;

  const { evmConfigDetails } = creditEthereumEventConfigurationDetails;

  if (
    !(+evmConfigDetails?._networkConfiguration?.chainId === chainId) ||
    !evmConfigDetails?._networkConfiguration.eventEmitter ||
    !evmConfigDetails._networkConfiguration.proxy
  )
    return null;
  const proxyTokenTransfer = await getProxyTokenTransferDetails({
    address: evmConfigDetails._networkConfiguration.proxy,
  });
  if (!proxyTokenTransfer?.address) return null;
  return {
    evmConfigDetails,
    rootTokenContractAddress: proxyTokenTransfer.address,
  };
};
