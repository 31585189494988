import React from 'react';
import st from './style.module.css';

const Separator = () => {
  return (
    <div className={st.box}>
      <div className={st.separator} />
    </div>
  );
};

export default Separator;
