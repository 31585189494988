import React, { ForwardedRef, forwardRef } from 'react';
import { Colors } from '../common';
import st from './style.module.css';

export type ProgressLineProps = {
  divRef: React.ForwardedRef<unknown>;
  lineWidthRaw: string;
  color?: Colors;
  layer1WidthPercent?: number;
  layer2WidthPercent?: number;
};

const ProgressLine = ({ divRef, lineWidthRaw, color, layer1WidthPercent, layer2WidthPercent }: ProgressLineProps) => {
  return (
    <div
      ref={divRef as ForwardedRef<HTMLInputElement>}
      className={st.box}
      style={{
        width: lineWidthRaw,
      }}
    >
      {!!color && !!layer1WidthPercent && (
        <div
          className={st.layer}
          style={{
            width: `${layer1WidthPercent}%`,
            backgroundColor: `var(${color})`,
          }}
        />
      )}
      {!!color && !!layer1WidthPercent && !!layer2WidthPercent && (
        <div
          className={st.layer}
          style={{
            width: `${Math.min(layer1WidthPercent, layer2WidthPercent)}%`,
          }}
        />
      )}
    </div>
  );
};

export default forwardRef((props: Omit<ProgressLineProps, 'divRef'>, ref) => <ProgressLine divRef={ref} {...props} />);
