import { tonClientRunLocal } from '../ton/ton-labs';
import creditEthereumEventConfigurationAbi from '../abi/ton/CreditEthereumEventConfiguration.abi.json';
import proxyTokenTransferAbi from '../abi/ton/TestProxyTokenTransfer.abi.json';

export type CreditEthereumEventConfigurationDetails = {
  _basicConfiguration: {
    eventABI: string;
    eventCode: string;
    eventInitialBalance: string; // string-number
    staking: string; // address: '0:...'
  };
  _meta: string; // hex
  _networkConfiguration: {
    chainId: string; // string-number                   // + 56 <=> BSC
    endBlockNumber: string; // string-number
    eventBlocksToConfirm: string; // string-number
    eventEmitter: string; // string-number              // +
    proxy: string; // address: '0:...'                  // +
    startBlockNumber: string; // string-number
  };
};
type GetCreditEthereumEventConfigurationDetails = { address: string };
type CreditEthereumEventConfigurationReturn = {
  evmConfigDetails: CreditEthereumEventConfigurationDetails;
};
export const getCreditEthereumEventConfigurationDetails = async ({
  address,
}: GetCreditEthereumEventConfigurationDetails): Promise<CreditEthereumEventConfigurationReturn | null> => {
  try {
    const output: CreditEthereumEventConfigurationDetails = await tonClientRunLocal(
      {
        type: 'Contract',
        value: creditEthereumEventConfigurationAbi,
      },
      address,
      {
        function_name: 'getDetails',
        input: {
          answerId: '0',
        },
      }
    );
    if (!output) return null;
    return {
      evmConfigDetails: output,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

type ProxyTokenTransferOutput = {
  value0: string; // address
};
type ProxyTokenTransfer = {
  address: ProxyTokenTransferOutput['value0'];
};
type ProxyTokenTransferDetails = { address: string };
export const getProxyTokenTransferDetails = async ({
  address,
}: ProxyTokenTransferDetails): Promise<ProxyTokenTransfer | null> => {
  try {
    const output: ProxyTokenTransferOutput = await tonClientRunLocal(
      {
        type: 'Contract',
        value: proxyTokenTransferAbi,
      },
      address,
      {
        function_name: 'getTokenRoot',
        input: {
          answerId: '0',
        },
      }
    );
    if (!output) return null;
    return output?.value0
      ? {
          address: output.value0,
        }
      : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};
