import React, { useEffect, useState } from 'react';
import ReactSelect, { defaultTheme, ThemeConfig } from 'react-select';
import { getCurrencyOptions } from '../../bridge/get-currency-options';
import st from './common.module.css';

const themeConfig: ThemeConfig = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: 'var(--dark-transparent)',
    primary75: 'var(--accent-inactive)',
    primary50: 'var(--accent-inactive)',
    primary25: 'var(--dark-transparent)',
  },
};

type AsyncReturnType<T extends (...args: any) => any> = T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : any;
export type Option = Exclude<AsyncReturnType<() => ReturnType<typeof getCurrencyOptions>>, null>[0];

export type SelectProps = {
  options: Partial<Option>[];
  initialOption?: Partial<Option>;
  setCurrentOption?: (option: any) => void;
  isSmall?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};
const Select = ({
  options,
  initialOption,
  setCurrentOption,
  isSmall = false,
  isLoading = false,
  isDisabled,
}: SelectProps) => {
  const [current, setCurrent] = useState<Partial<Option> | null>(null);

  useEffect(() => {
    setCurrent(initialOption || null);
  }, [initialOption]);

  return (
    <>
      <ReactSelect
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading || options?.length <= 1}
        className={`${st.select} ${isDisabled || isLoading || options?.length < 1 ? st.disabled : ''} ${
          isSmall ? st.small : ''
        } ${options?.length <= 1 ? st['hide-indicator'] : ''}`}
        classNamePrefix="react-select-xxx"
        options={options}
        value={isLoading ? undefined : options.find(({ value }) => value === current?.value) || null}
        onChange={(selectedOption: typeof options[0] | null) => {
          if (selectedOption) {
            setCurrent(selectedOption);
            setCurrentOption?.(selectedOption);
          }
        }}
        theme={themeConfig}
      />
    </>
  );
};

export default Select;
