import React, { useContext, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useParams } from 'react-router';
import BigNumber from 'bignumber.js';
import { Button } from '../../components/button';
import { Content } from '../../components/content';
import Wrapper from '../../components/invest/wrapper';
import { Popup } from '../../components/popup';
import { UserInvestSmallCardProps } from '../../components/small-card';
import { Spinner } from '../../components/spinner';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { ProjectType } from '../../ton/get-project';
import useTONCrystalWallet from '../../utils/hooks/useTONCrystalWallet';
import { Modal } from '../../components/modal';
import ConfirmModal from './ConfirmModal';
import Form from './Form';
import st from './style.module.css';
import useProjectData from './useProjectData';
import Waiting from './Waiting';

const isDevMode = false;

type WithdrawalByOwner = {
  commonInfo: null | ProjectType;
  userInfo: null | UserInvestSmallCardProps;
};

const WithdrawalByOwner = () => {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>();

  const [walletAddress, setWalletAddress] = useState('');
  const [amount, setAmount] = useState('0');
  const [isFormValid, setIsFormValid] = useState(false);

  const { accountInteraction } = useContext(TONWalletContext);

  const { poolNumber } = useParams();

  const height100vh = use100vh();

  const { isLoading, isLoadingError, project, redirect } = useProjectData({
    poolNumber,
    isDevMode,
  });

  const { withdrawalByOwner } = useTONCrystalWallet();

  const onWithdrawalClick = () => {
    setIsModalShow(true);
  };

  const onWithdrawalConfirm = () => {
    setIsModalShow(false);
    setIsWaiting(true);

    const myAddress = accountInteraction?.address?.toString();
    const launchPoolAddress = project?.commonInfo?.launchPoolAddress.toString();

    if (myAddress && launchPoolAddress && walletAddress && amount && isFormValid)
      withdrawalByOwner({
        myAddress,
        launchPoolAddress,
        destinationAddress: walletAddress,
        withdrawalAmount: String(+amount * 10 ** (project?.commonInfo?.mainCurrency.decimals || 0)),
      })
        .then((answer) => {
          setIsSuccess(!!answer);
        })
        .catch((error) => {
          let errorObject;
          try {
            errorObject = JSON.parse(error.message);
            // eslint-disable-next-line no-empty
          } catch (err) {}
          if (+errorObject?.code === 3) {
            setIsSuccess(undefined);
            setIsWaiting(false);
            // eslint-disable-next-line no-console
            console.error(errorObject.message);
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
            setIsSuccess(false);
          }
        });
  };

  if (isLoadingError)
    return (
      <div className={st['common-style']} style={{ minHeight: height100vh || '100vh' }}>
        <Content backTo={`/project/${poolNumber ?? ''}`} title="Back">
          <Wrapper removePadding>
            <div>
              <strong>Load Project Error</strong>
            </div>
          </Wrapper>
        </Content>
      </div>
    );

  if (isLoading || !project)
    return (
      <div className={st['common-style']} style={{ minHeight: height100vh || '100vh' }}>
        <Content backTo={`/project/${poolNumber ?? ''}`} title="Back">
          <Wrapper removePadding>
            <div>
              <Spinner />
            </div>
          </Wrapper>
        </Content>
      </div>
    );

  return (
    <div className={st['common-style']} style={{ minHeight: height100vh || '100vh' }}>
      <Content backTo={`/project/${poolNumber ?? ''}`} title="Back">
        <Wrapper>
          <Popup
            inWithdrawal
            buttons={
              // eslint-disable-next-line no-nested-ternary
              isWaiting ? (
                typeof isSuccess === 'boolean' ? (
                  <div className={st['go-back-box']}>
                    <div>
                      <Button
                        isCentred
                        text="GO BACK"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setIsWaiting(false);
                          redirect();
                        }}
                      />
                    </div>
                  </div>
                ) : undefined
              ) : (
                <>
                  <Button
                    isInvert
                    isCentred
                    text="Cancel"
                    width="100%"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      redirect();
                    }}
                  />
                  <Button
                    isDisabled={!isFormValid}
                    isCentred
                    text="Withdraw"
                    width="100%"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onWithdrawalClick();
                    }}
                  />
                </>
              )
            }
          >
            {isWaiting ? (
              <Modal>
                <Popup>
                  <Waiting isSuccess={isSuccess} poolNumber={project.commonInfo?.poolNumber} />
                </Popup>
              </Modal>
            ) : (
              <Form
                info={[
                  {
                    title: 'Total sales',
                    value: new BigNumber(project.commonInfo?.totalRaised || 0)
                      .shiftedBy(-(project.commonInfo?.mainCurrency.decimals || 0))
                      .toNumber(),
                  },
                  {
                    title: 'Hard cap',
                    value: new BigNumber(project.commonInfo?.hardcap || 0)
                      .shiftedBy(-(project.commonInfo?.mainCurrency.decimals || 0))
                      .toNumber(),
                  },
                  {
                    title: 'Already withdrawn',
                    value: new BigNumber(project.commonInfo?.totalWithdraw || 0)
                      .shiftedBy(-(project.commonInfo?.mainCurrency.decimals || 0))
                      .toNumber(),
                  },
                  {
                    title: 'Available for withdraw',
                    value: new BigNumber(
                      Math.min(project.commonInfo?.totalRaised || 0, project.commonInfo?.hardcap || 0)
                    )
                      .minus(project.commonInfo?.totalWithdraw || 0)
                      .shiftedBy(-(project.commonInfo?.mainCurrency.decimals || 0))
                      .toNumber(),
                  },
                ]}
                currency={project.commonInfo?.mainCurrency}
                maxAmount={new BigNumber(
                  Math.min(project.commonInfo?.totalRaised || 0, project.commonInfo?.hardcap || 0)
                )
                  .minus(project.commonInfo?.totalWithdraw || 0)
                  .shiftedBy(-(project.commonInfo?.mainCurrency.decimals || 0))
                  .toNumber()}
                amount={amount}
                setAmount={setAmount}
                walletAddress={walletAddress}
                setWalletAddress={setWalletAddress}
                setIsFormValid={setIsFormValid}
              />
            )}
          </Popup>
        </Wrapper>
      </Content>
      {isModalShow && (
        <ConfirmModal
          setIsModalShow={setIsModalShow}
          onConfirm={onWithdrawalConfirm}
          ownerWalletAddress={walletAddress}
          withdrawalAmount={+amount || 0}
          currencySymbol={project.commonInfo?.mainCurrency.symbol || ''}
        />
      )}
    </div>
  );
};

export default WithdrawalByOwner;
