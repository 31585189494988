/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useState } from 'react';
import { book, exit, walletLogout, walletWhite } from '../../../common/icons';
import { formatTONAddress } from '../../../utils/helpers';
import { Button } from '../../button';
import { Modal } from '../../modal';
import { Context } from '../../../providers/Provider';
import { DisconnectPopup } from './DisconnectPopup';
import st from '../style.module.css';
import wm from './wallets-manager.module.css';

type WalletButtonProps = {
  address?: string;
  icon: JSX.Element;
  connectWallet: () => void;
  disconnectWallet?: () => void;
  isInstalled: boolean;
  isConnected: boolean;
  walletName: string;
  isEverwallet?: boolean;
};

export const WalletButton: React.FC<WalletButtonProps> = ({
  address,
  icon,
  isInstalled,
  isConnected,
  connectWallet,
  disconnectWallet,
  walletName,
  isEverwallet,
}) => {
  const { isMobile } = useContext(Context);
  const [isDisconnectModalShow, setIsDisconnectModalShow] = useState(false);

  const handleConnect = useCallback(() => {
    connectWallet();
  }, [isConnected]);

  const handleDisconnect = useCallback(() => {
    if (disconnectWallet) {
      disconnectWallet();
      setIsDisconnectModalShow(false);
    }
  }, [isConnected]);

  return (
    <>
      {isDisconnectModalShow && (
        <Modal setIsShow={setIsDisconnectModalShow}>
          <DisconnectPopup
            isMobile={isMobile}
            isEverWallet={isEverwallet}
            onDisconnect={handleDisconnect}
            onCancel={() => setIsDisconnectModalShow(false)}
          />
        </Modal>
      )}
      <Button
        isInvert={isConnected}
        onClick={handleConnect}
        className={wm.item}
        text={
          <span className={isConnected ? st['sign-out'] : st['sign-in']}>
            <span
              className={st.icon}
              style={{ width: 13, height: 13, transform: isConnected ? 'scale(1.5)' : undefined }}
            >
              {isConnected ? icon : walletWhite}
            </span>
            {!isConnected && <span className={st.text}>{walletName}</span>}
            {isConnected && address && (
              <>
                <span className={st.info}>
                  <span className={st.address} style={address ? undefined : { visibility: 'hidden' }}>
                    {formatTONAddress({
                      address,
                      numberOfCharactersUpTo: 4,
                      numberOfCharactersAfter: 4,
                    })}
                  </span>
                </span>
                <span className={wm.exit} onClick={() => setIsDisconnectModalShow(true)}>
                  {walletLogout}
                </span>
              </>
            )}
          </span>
        }
      />
    </>
  );
};
