import { Address, ProviderRpcClient } from 'everscale-inpage-provider';
import ILaunchPoolCallbackAbi from '../abi/ton/ILaunchPoolCallback.abi.json';

const rpc = new ProviderRpcClient();

export const transactionSubscriber = ({
  accountInteractionAddress,
  callbackId,
  transactionMethods,
  callbackFn,
}: {
  accountInteractionAddress: Address;
  callbackId: string;
  transactionMethods: string[];
  callbackFn: (result: { isSuccessCallback: boolean; isDeclineCallback: boolean; amount: string }) => void;
}) => {
  const owner = new rpc.Contract(ILaunchPoolCallbackAbi, accountInteractionAddress);
  const subscriber = new rpc.Subscriber();
  const stream = subscriber.transactions(accountInteractionAddress);

  let resultHandler: Promise<void> | null = stream
    ?.flatMap((a) => a.transactions)
    .filterMap(async (transaction) => {
      const result: { method: string; input: { callbackId: string; amount: string }; output: never } | undefined =
        await owner.decodeTransaction({ transaction, methods: transactionMethods });

      if (result && transactionMethods.includes(result.method) && callbackId === result.input.callbackId) {
        callbackFn({
          isSuccessCallback: result.method === 'newInvestmentCallback',
          isDeclineCallback: result.method === 'investmentDeclinedCallback',
          amount: result.input.amount,
        });
        subscriber.unsubscribe().then(() => {
          resultHandler = null;
        });
      }
    })
    .first();
};
