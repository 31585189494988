import React, { useEffect, useRef, useState } from 'react';
import BigNumber from 'bignumber.js';
import st from './common.module.css';
import input from './input.module.css';

export type InputProps = {
  amount: string;
  setAmount?: (amount: string) => void;
  children?: JSX.Element;
  fixTo?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  isEver: boolean;
  currency: string;
  userDepositLimit: BigNumber | null;
};
const Input = ({
  amount,
  setAmount,
  children,
  fixTo = 0,
  isDisabled,
  isLoading,
  isEver,
  currency,
  userDepositLimit,
}: InputProps) => {
  const [inner, setInner] = useState(amount);
  const [timeoutId, setTimeoutId] = useState<number | undefined>();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEver) setAmount?.(inner);
  }, [inner]);

  useEffect(() => {
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      if (!isEver) setAmount?.(inner);
    }, 1000) as unknown as number;
    setTimeoutId(id);
    return () => {
      clearTimeout(id);
    };
  }, [inner]);

  useEffect(() => {
    setInner('0');
  }, [isEver, currency]);

  useEffect(() => {
    if (!isLoading) ref?.current?.focus();
  }, [isLoading]);

  return (
    <div className={input['input-wrapper']}>
      <input
        ref={ref}
        disabled={isDisabled || isLoading}
        type="text"
        className={`${st.select} ${isDisabled || isLoading ? st['disabled-input'] : ''} ${st.input} ${
          children ? st.plus : ''
        }`}
        value={inner}
        onChange={(e) => {
          const { value } = e.target;
          const regexp = new RegExp(`^[0-9]*[.,]?[0-9]{0,${fixTo}}$`);
          if (value === '') {
            setInner?.('0');
          } else if (userDepositLimit && new BigNumber(value).isGreaterThan(userDepositLimit)) {
            setInner?.(userDepositLimit.toString());
          } else if (value.match(regexp) !== null) {
            const newValue = amount?.includes('.')
              ? value.slice(0, value.indexOf('.') + 1).concat(
                  value
                    .slice(value.indexOf('.') + 1)
                    .replace(',', '')
                    .replace('.', '')
                )
              : value.replace(',', '.');
            const x = newValue.replace(/^0+/, '0');
            // eslint-disable-next-line no-nested-ternary
            const y = x.length > 1 ? (x[0] === '0' ? x.slice(1) || x : x) : x;

            setInner?.(y.match(/^\.(.*)/) ? y.replace(/^\./, '0.') : y);
          }
        }}
      />
      {isLoading ? (
        <div className={input['loading-wrapper']}>
          <div className={input.loading}>
            <span />
            <span />
            <span />
          </div>
        </div>
      ) : (
        <div className={st.children}>{children}</div>
      )}
    </div>
  );
};

export default Input;
