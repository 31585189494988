import { useContext, useEffect } from 'react';
import { TONWalletContext } from '../providers/TONWalletProvider';
import useTONCrystalWallet from '../utils/hooks/useTONCrystalWallet';

export const CheckTonProvider = (): null => {
  const { isCheck } = useContext(TONWalletContext);
  const { checkTonProvider, getTONBalance } = useTONCrystalWallet();

  useEffect(() => {
    if (!isCheck) {
      checkTonProvider().then((accountInteraction) => {
        if (accountInteraction?.address)
          getTONBalance({
            address: String(accountInteraction.address),
          });
      });
    }
  }, [checkTonProvider, isCheck]);

  return null;
};
