import React from 'react';
import { links } from '../../constants/links';
import st from './style.module.css';

type TransactionLinkProps = {
  networkChainId: number;
  transactionHash: string;
};
const TransactionLink = ({ networkChainId, transactionHash }: TransactionLinkProps) => {
  return (
    <span className={st.text}>
      You can monitor the status of the transaction{' '}
      <a
        // eslint-disable-next-line max-len
        href={`https://app.${links.tonbridgeHostName}/transfer/evm-${networkChainId}/ton-1/${transactionHash}/credit`}
        target="_blank"
        rel="noopener noreferrer"
        className="initial"
      >
        here
      </a>
    </span>
  );
};

export default TransactionLink;
