import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Content } from '../components/content';
import { ProjectsList } from '../components/projects-list';
import { SmallCardProps, UserInvestSmallCardProps } from '../components/small-card';
import { Tabs } from '../components/tabs';
import { TabsStatusEnum } from '../components/tabs/Tabs';
import { LAUNCH_POOL_FACTORY } from '../constants/marketConstants';
import { Context } from '../providers/Provider';
import { TONWalletContext } from '../providers/TONWalletProvider';
import { getProject, getUserDataForProject, ProjectType, ReducedProjectType } from '../ton/get-project';
import { getPoolsCount } from '../ton/utils';
import { getStatus } from '../utils/get-status';
import useResize from '../utils/hooks/useResize';

export type ProjectsProps = {
  // todo
};

export type ProjectTypeWithUserData = ProjectType & {
  userData?: Partial<UserInvestSmallCardProps> & Partial<SmallCardProps>;
  test: true;
};

const Projects = (/* {}: ProjectsProps */) => {
  const { isLogin, accountInteraction } = useContext(TONWalletContext);
  const { setHeaderWidth } = useContext(Context);

  const [currentPoolsCount, setCurrentPoolsCount] = useState<string | null>(null);
  const [currentOption, setCurrentOption] = useState<TabsStatusEnum>();
  const [projects, setProjects] = useState<ProjectTypeWithUserData[]>([]);
  const [isProjectsFetchingEnd, setIsProjectsFetchingEnd] = useState(true);
  const [isUserDataFetchingEnd, setIsUserDataFetchingEnd] = useState(true);
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { status } = useParams();

  useEffect(() => {
    setHeaderWidth(480);
  }, []);

  useResize({
    mobileWidth: 700,
  });

  const setCurrentOptionAcrossLoading = (x: typeof currentOption) => {
    setIsLoading(true);
    setCurrentOption(x);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setCurrentOptionAcrossLoading(
      getStatus<TabsStatusEnum>({
        status,
      })
    );
  }, [status]);

  useEffect(() => {
    if (isProjectsFetchingEnd && isUserDataFetchingEnd && currentOption) {
      setIsProjectsFetchingEnd(false);
      setIsUserDataFetchingEnd(false);

      getPoolsCount({
        address: LAUNCH_POOL_FACTORY,
      }).then(({ poolsCount }) => {
        if (currentPoolsCount !== poolsCount) {
          setCurrentPoolsCount(poolsCount);
          const promises: Promise<ProjectType | null>[] = [];
          for (let i = 0; i < +(poolsCount || 0); i += 1) {
            promises.push(
              getProject({
                poolNumber: i,
              })
            );
          }
          Promise.all(promises).then((p) => {
            setProjects(p.filter((_) => !!_) as ProjectTypeWithUserData[]);
            setIsProjectsFetchingEnd(true);
          });
        } else {
          setIsUserDataFetchingEnd(true);
          setIsProjectsFetchingEnd(true);
        }
      });
    }
  }, [currentOption]);

  useEffect(() => {
    const userAccountAddress = accountInteraction?.address.toString();
    if (isProjectsFetchingEnd && (!isUserDataFetchingEnd || userAccountAddress !== userAddress)) {
      if (isLogin && projects.length) {
        if (userAccountAddress) {
          setIsUserDataFetchingEnd(false);
          const promises: Promise<ReducedProjectType | null>[] = [];
          for (let i = 0; i < projects.length; i += 1) {
            promises.push(
              getUserDataForProject({
                project: projects[i],
                userAccountAddress,
              })
            );
          }
          Promise.all(promises).then((p) => {
            if (p) {
              setProjects((oldProjects) => {
                return oldProjects.map((project) => {
                  const myInvestmentInTheProject = p.find((item) => item?.poolNumber === project.poolNumber);
                  return {
                    ...project,
                    userData: myInvestmentInTheProject || undefined,
                  };
                });
              });
              setUserAddress(userAccountAddress);
            }
            setIsUserDataFetchingEnd(true);
            setIsProjectsFetchingEnd(true);
          });
        }
      } else {
        setIsUserDataFetchingEnd(true);
        setIsProjectsFetchingEnd(true);
      }
    }
    if (!isLogin) setUserAddress(null);
  }, [isProjectsFetchingEnd, isLogin]);

  return (
    <Content title="">
      <ProjectsList
        projects={
          !isProjectsFetchingEnd || !isUserDataFetchingEnd || !currentOption || (status === 'Participated' && !isLogin)
            ? []
            : (projects.filter(
                (project) =>
                  !!project &&
                  (project.status === currentOption ||
                    project.status === 'Finished' ||
                    project.status === 'Upcoming' ||
                    (currentOption === 'Participated' && project.userData?.youInvested))
              ) as ProjectTypeWithUserData[])
        }
        isLoading={!isProjectsFetchingEnd || !isUserDataFetchingEnd || isLoading}
        status={currentOption}
      />
    </Content>
  );
};

export default Projects;
