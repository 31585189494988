import { CreditEthereumEventConfigurationDetails } from '.';
import { getCreditorFactoryAddressHex } from './get-assets';
import { PaymentSteps, trackThePayment } from './payment-tracking';

type SubscriptionOnBridge = {
  evmConfigDetails: CreditEthereumEventConfigurationDetails;
  evmConfigAddress: string;
  onTrackThePayment: (step: number | null) => void;
  onCreditProcessorStatusChanged: (status: string | null) => void;
  transactionHash: string;
};
export const subscriptionOnBridge = async ({
  evmConfigDetails,
  evmConfigAddress,
  onTrackThePayment,
  onCreditProcessorStatusChanged,
  transactionHash,
}: SubscriptionOnBridge): Promise<void> => {
  onTrackThePayment(PaymentSteps.start);
  const creditorFactoryAddressHex = await getCreditorFactoryAddressHex();
  if (creditorFactoryAddressHex) {
    const trackThePaymentPromise = await trackThePayment({
      creditFactoryAddress: `0:${creditorFactoryAddressHex}`,
      txHash: transactionHash,
      evmConfigAddress,
      evmConfigDetails,
      onCreditProcessorStatusChanged,
    });
    if (trackThePaymentPromise?.checkingPromise) {
      const signaturesCollected = await trackThePaymentPromise.checkingPromise;
      if (signaturesCollected) {
        const { isAllSignExist, processedCheckingPromise } = signaturesCollected;
        if (isAllSignExist) {
          onTrackThePayment(PaymentSteps.signaturesCollected);
          const statusPromises = await processedCheckingPromise;
          if (statusPromises) {
            const { isProcessedPromise, isStartPromise } = statusPromises;
            const isStart = await isStartPromise;
            if (isStart) {
              onTrackThePayment(PaymentSteps.creditProcessorContractCreated);
              const isProcessed = await isProcessedPromise;
              if (isProcessed) {
                onTrackThePayment(PaymentSteps.processedStatus);
                return;
              }
            }
          }
        }
      }
    }
  }
  onTrackThePayment(PaymentSteps.error);
};
