import LaunchPoolFactoryAbi from '../../abi/ton/LaunchPoolFactory.abi.json';
import { tonClientRunLocal } from '../ton-labs';

type GetPoolsCount = { address: string };
type PoolsCountOutput<T = string> = {
  poolsCount: string | T;
};
export const getPoolsCount = async ({ address }: GetPoolsCount): Promise<PoolsCountOutput<null>> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolFactoryAbi,
      },
      address,
      {
        function_name: 'poolsCount',
        input: {},
      }
    );
    if (!output)
      return {
        poolsCount: null,
      };
    const { poolsCount } = output as PoolsCountOutput;
    return {
      poolsCount: poolsCount || null,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      poolsCount: null,
    };
  }
};

type GetLaunchPoolAddress = {
  address: string;
  poolNumber: string;
};
type LaunchPoolAddressOutput<T = string> = {
  value0: string | T;
};
type LaunchPoolAddress<T = string> = {
  launchPoolAddress: LaunchPoolAddressOutput['value0'] | T;
};
export const getLaunchPoolAddress = async ({
  address,
  poolNumber,
}: GetLaunchPoolAddress): Promise<LaunchPoolAddress<null>> => {
  try {
    const output = await tonClientRunLocal(
      {
        type: 'Contract',
        value: LaunchPoolFactoryAbi,
      },
      address,
      {
        function_name: 'getLaunchPoolAddress',
        input: {
          poolNumber,
        },
      }
    );
    if (!output)
      return {
        launchPoolAddress: null,
      };
    const { value0 } = output as LaunchPoolAddressOutput;
    return {
      launchPoolAddress: value0 || null,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      launchPoolAddress: null,
    };
  }
};
