import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { cancel, checked } from '../../common/icons';
import { Spinner } from '../../components/spinner';
import { Button } from '../../components/button';
import st from './waiting.module.css';

const getIcon = (isSuccess: boolean | undefined) => {
  switch (isSuccess) {
    case true:
      return checked;

    case false:
      return cancel;

    case undefined:
    default:
      return <Spinner withWrapper />;
  }
};

const getStatus = (isSuccess: boolean | undefined) => {
  switch (isSuccess) {
    case true:
      return 'Success!';

    case false:
      return 'Failure!';

    case undefined:
    default:
      return 'Loading...';
  }
};

const getText = (isSuccess: boolean | undefined) => {
  switch (isSuccess) {
    case true:
      return <>The request has been sent successfully</>;

    case false:
      return (
        <>
          Something went wrong.
          <br />
          Request not sent.
        </>
      );

    case undefined:
    default:
      return <>Wait for the transaction to process.</>;
  }
};

export type WaitingProps = {
  isSuccess: boolean | undefined;
  poolNumber?: number;
};

const Waiting = ({ isSuccess, poolNumber }: WaitingProps) => {
  const navigate = useNavigate();
  const handleClose = () => navigate(poolNumber ? `/project/${poolNumber}` : '/projects/Live');
  return (
    <div className={`${st.box} ${typeof isSuccess === 'boolean' ? st['with-button'] : ''}`}>
      <section className={st.icon}>{getIcon(isSuccess)}</section>
      <section className={st.paragraph}>
        <div className={st.title}>{getStatus(isSuccess)}</div>
        <div>{getText(isSuccess)}</div>
      </section>
      <Button width="156px" isCentred onClick={handleClose} text="Close" />
    </div>
  );
};

export default Waiting;
