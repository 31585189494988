import React, { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { formatPriceNew } from '../../utils/helpers';
import useTONCrystalWallet from '../../utils/hooks/useTONCrystalWallet';
import { Button } from '../button';
import { ListItem } from '../list';
import { CardProps, Period } from '../small-card/Card';
import st from './your-investment.module.css';

type ButtonsBoxProps = {
  buttonsShow: {
    getARefund: boolean;
    getAvailable: boolean;
  };
  onGetARefund?: () => void;
  onGetAvailable?: () => void;
} & Pick<CardProps, 'mainCurrency'>;

const ButtonsBox = ({
  mainCurrency,
  buttonsShow: { getARefund, getAvailable },
  onGetARefund,
  onGetAvailable,
}: ButtonsBoxProps) => {
  return (
    <div className={st['button-box']}>
      {getARefund && (
        <section className={st['button-box-section']}>
          <Button
            isInvert
            isCentred
            text={`RETURN ${mainCurrency.symbol}`}
            width="100%"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onGetARefund?.();
            }}
          />
        </section>
      )}
      {getAvailable && (
        <section className={st['button-box-section']}>
          <Button
            isInvert
            isCentred
            text="GET TOKENS"
            width="100%"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onGetAvailable?.();
            }}
          />
        </section>
      )}
    </div>
  );
};

export type YourInvestmentProps = Partial<
  {
    rate: number;
    yourInvestment: {
      youInvested: number;
      accessible: number | null;
      appreciated: number | null;
      appreciatedPercent: number | null;
      received: number | null;
      periods: Period[];
      _return: number;
      launchPoolParticipationAddress: string | null;
    };
    launchTokenRoot: string;
    buttonsShow: {
      getARefund: boolean;
      getAvailable: boolean;
    };
  } & CardProps
>;

const useYourInvestment = (props: YourInvestmentProps): ListItem[] => {
  const { rate, tokenCurrency, mainCurrency, yourInvestment, launchTokenRoot, buttonsShow } = props;

  const {
    // youInvested,
    accessible,
    // appreciated,
    // appreciatedPercent,
    received,
    _return,
    // launchPoolParticipationAddress,
  } = yourInvestment || {};

  const { getARefund, getAvailable } = buttonsShow || {};

  const { addTokenToTONCrystalWallet } = useTONCrystalWallet();

  useEffect(() => {
    if (accessible && launchTokenRoot) {
      addTokenToTONCrystalWallet({
        rootTokenContract: launchTokenRoot,
      });
    }
  }, [launchTokenRoot]);

  if (!(rate && tokenCurrency && mainCurrency && yourInvestment && launchTokenRoot)) return [];

  return (
    (
      [
        // {
        //   title: 'Your deposit',
        //   data: formatPriceNew({
        //     amount: (youInvested || 0) / 10 ** (mainCurrency.decimals ?? 0),
        //     currency: mainCurrency.symbol,
        //     rate,
        //   }),
        // },
      ] as ListItem[]
    )
      // .concat(
      //   appreciated
      //     ? [
      //         {
      //           title: 'Your allocation',
      //           data: (
      //             <>
      //               {formatPriceNew({
      //                 amount: appreciated / 10 ** (tokenCurrency.decimals ?? 0),
      //                 currency: (
      //                   <TokenLink tonTokenAddress={tokenCurrency.launchTokenRoot}>{tokenCurrency.symbol}</TokenLink>
      //                 ),
      //               })}
      //               {appreciatedPercent ? ` (${formatAmount(appreciatedPercent)}%)` : ''}
      //             </>
      //           ),
      //         },
      //       ]
      //     : []
      // )
      .concat(
        (accessible || accessible === 0) && getAvailable
          ? [
              {
                title: 'You can claim now',
                data: `${new BigNumber(accessible || 0).shiftedBy(-tokenCurrency.decimals ?? 0).toNumber()} ${
                  tokenCurrency.symbol
                }`,
              },
            ]
          : []
      )
      .concat(
        received && getAvailable
          ? [
              {
                title: 'Received',
                data: `${new BigNumber(received || 0).shiftedBy(-tokenCurrency.decimals ?? 0).toNumber()} ${
                  tokenCurrency.symbol
                }`,
              },
            ]
          : []
      )
      .concat(
        _return && getARefund
          ? [
              {
                title: 'Return',
                data: `${new BigNumber(_return).shiftedBy(-mainCurrency.decimals ?? 0).toNumber()} ${
                  mainCurrency.symbol
                }`,
              },
            ]
          : []
      )
  );
  // .concat(
  //   launchPoolParticipationAddress
  //     ? [
  //         {
  //           title: 'Your contract',
  //           data: (
  //             <a
  //               href={`https://${links.tonscanHostName}/accounts/${launchPoolParticipationAddress}`}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //               className="initial"
  //             >
  //               {formatTONAddress({
  //                 address: launchPoolParticipationAddress,
  //                 numberOfCharactersUpTo: 2,
  //                 numberOfCharactersAfter: 4,
  //               })}
  //             </a>
  //           ),
  //         },
  //       ]
  //     : []
  // );
};

export { useYourInvestment, ButtonsBox };
