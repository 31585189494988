import React from 'react';
import st from './style.module.css';

type InfoCardProps = {
  title: string | JSX.Element;
  value: string | JSX.Element | null;
};
const InfoCard = ({ title, value }: InfoCardProps) => {
  return (
    <div className={st['subinput-cards-card']}>
      <section className={st['subinput-cards-title']}>{title}</section>
      <section className={st['subinput-cards-value']}>{value}</section>
    </div>
  );
};

type InfoLineProps = InfoCardProps & {
  subvalue?: string | JSX.Element;
};
const InfoLine = ({ title, value, subvalue }: InfoLineProps) => {
  return (
    <>
      <div className={st['subinput-lines-line']}>
        <section className={st['subinput-lines-title']}>{title}</section>
        {value && <section className={st['subinput-lines-value']}>{value}</section>}
      </div>
      {subvalue && (
        <div className={st['subinput-lines-line']}>
          <section className={st['subinput-lines-subvalue']}>{subvalue}</section>
        </div>
      )}
    </>
  );
};

export type SubInputProps = {
  info: {
    type: 'card' | 'line';
    isShow: boolean;
    element: InfoCardProps | InfoLineProps;
    element2?: InfoLineProps;
  }[];
};
const SubInput = ({ info }: SubInputProps) => {
  return (
    <div className={st.subinput}>
      <ul className={st['subinput-cards-list']}>
        {info
          .filter(({ type, isShow }) => !!isShow && type === 'card')
          .map(({ element: card }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i} className={st['subinput-cards-item']}>
              <InfoCard {...card} />
            </li>
          ))}
      </ul>
      <ul className={st['subinput-lines-list']}>
        {info
          .filter(({ type, isShow }) => !!isShow && type === 'line')
          .map(({ element: line, element2: line2 }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i} className={st['subinput-lines-item']}>
              <InfoLine {...line} />
              {!!line2 && (
                <div>
                  <InfoLine {...line2} />
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubInput;
