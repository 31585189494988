type Response = {
  safeLow: number;
  standard: number;
  fast: number;
  fastest: number;
  blockTime: number;
  blockNumber: number;
};

const url = `https://gasstation-mainnet.matic.network/`;

export const getPolygonNetworkGasGWei = async () => {
  const response = await fetch(url);
  const json: Response = await response.json();

  return json;
};
