import { useEffect, useState } from 'react';
import { evmAddresses } from '../../constants/marketConstants';

export const useCheckWhitelist = (evmUserAddress?: string, everscaleUserAddress?: string) => {
  const [isUserWhitelisted, setIsUserWhitelisted] = useState(false);

  useEffect(() => {
    if (!evmUserAddress) return;
    const isEvmAddressInWhitelist = evmAddresses.some((address) => address.toLowerCase().match(evmUserAddress));
    const usedEverscaleAddress = window.localStorage.getItem(evmUserAddress);
    const isEvmWithEverscaleAddressesUsedBefore =
      usedEverscaleAddress !== null ? usedEverscaleAddress === everscaleUserAddress : true;
    setIsUserWhitelisted(isEvmAddressInWhitelist && isEvmWithEverscaleAddressesUsedBefore);
  }, [evmUserAddress, everscaleUserAddress]);

  return { isUserWhitelisted };
};
