import React from 'react';
import { isMobile } from 'react-device-detect';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { Separator } from '../separator';
import Arrow from './Arrow';
import Cut from './Cut';
import st from './style.module.css';

// for storybook
// eslint-disable-next-line import/extensions
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper.min.css';
// import 'swiper/modules/navigation/navigation.min.css';

type Slide = {
  key: string;
  element: JSX.Element;
};

export type VestingBlockProps = {
  slides: Slide[];
};

// let n = 1;

const VestingBlock = ({ slides }: VestingBlockProps) => {
  // const fix = slides.slice();
  // // eslint-disable-next-line no-param-reassign
  // slides = slides.concat(
  //   fix.map((props) => {
  //     return {
  //       ...props,
  //       // eslint-disable-next-line no-plusplus
  //       key: `${props.key}___${n++}`,
  //     };
  //   })
  // );
  // // eslint-disable-next-line no-param-reassign
  // slides = slides.concat(
  //   fix.map((props) => {
  //     return {
  //       ...props,
  //       // eslint-disable-next-line no-plusplus
  //       key: `${props.key}__123__${n++}`,
  //     };
  //   })
  // );
  // // eslint-disable-next-line no-param-reassign
  // slides = slides.concat(
  //   fix.map((props) => {
  //     return {
  //       ...props,
  //       // eslint-disable-next-line no-plusplus
  //       key: `${props.key}___${n++}`,
  //     };
  //   })
  // );

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className={`${st.box} ${isMobile ? st['mobile-box'] : ''}`}>
      <Swiper
        className={st.swiper}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onBeforeInit={(_swiper) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          _swiper.params.navigation.prevEl = navigationPrevRef.current;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          _swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
      >
        {slides.map(({ key, element }, i) => (
          <SwiperSlide
            key={key}
            style={{
              width: 'auto',
              // flexGrow: 1
            }}
          >
            <div className={st.slide}>
              <section className={st['slide-section']}>{element}</section>

              {i < slides.length - 1 && (
                <section className={st['separator-section']}>
                  <Separator />
                </section>
              )}
            </div>
          </SwiperSlide>
        ))}
        <Cut
          render={(props) => (
            <>
              <Arrow type="prev" ref={navigationPrevRef} {...props} />
              <Arrow type="next" ref={navigationNextRef} {...props} />
            </>
          )}
        />
      </Swiper>
    </div>
  );
};

export default VestingBlock;
