import React from 'react';
import { Popup } from '../../popup';
import { Button } from '../../button';
import { door } from '../../../common/icons';
import st from '../../install-popup/style.module.css';
import wt from './wallets-manager.module.css';

type DisconnectPopupProps = {
  onCancel: () => void;
  onDisconnect: () => void;
  isEverWallet?: boolean;
  isMobile: boolean;
};

export const DisconnectPopup: React.FC<DisconnectPopupProps> = ({ onDisconnect, onCancel, isEverWallet, isMobile }) => (
  <Popup
    onCancel={onCancel}
    buttons={
      <div className={wt['popup-buttons']}>
        <Button isCentred isInvert width="130px" text={isEverWallet && isMobile ? 'Cancel' : 'Ok'} onClick={onCancel} />
        {(isEverWallet || isMobile) && <Button isCentred width="130px" text="Disconnect" onClick={onDisconnect} />}
      </div>
    }
  >
    <div className={st.box}>
      <span>{door}</span>
      <h1 className={wt['popup-header']}>Disconnect wallet?</h1>
      {(isMobile || isEverWallet) && <span className={wt['popup-text']}>You can connect it again at any time</span>}
      {!isMobile && !isEverWallet && (
        <span className={wt['popup-text']}>You have to manually disconnect wallet in the Metamask extension</span>
      )}
    </div>
  </Popup>
);
