import React from 'react';
import { links } from '../../constants/links';
import { Button } from '../button';
import { Popup } from '../popup';
import st from './style.module.css';

export type InstallPopupProps = {
  onCancel?: () => void;
};
const InstallPopup = ({ onCancel }: InstallPopupProps) => {
  return (
    <Popup
      onCancel={onCancel}
      buttons={
        <a className={st['link-to']} href={links.extension} target="_blank" rel="noopener noreferrer">
          <Button isCentred text="Install EVER Wallet" width="100%" />
        </a>
      }
    >
      <div className={st.box}>
        <h1>
          EVER Wallet
          <br />
          Chrome plugin
        </h1>
        <p>
          To use EVER Wallet you need to install plugin using instructions from{' '}
          <a href={links.extension} target="_blank" rel="noopener noreferrer" className="initial">
            broxus.com
          </a>
          .
        </p>
      </div>
    </Popup>
  );
};

export default InstallPopup;
