/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'dragscroll';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Dragscroll from '../../../utils/dragscroll';
import { Colors } from '../common';
import { InfoCard } from '../info-card';
import st from './style.module.css';

export type InfoBlockProps = {
  currencyLabel: string;
  committed: number;
  yourCommit: number | null;
  softCup: number;
  hardCup: number;
};
const InfoBlock = ({ currencyLabel, committed, yourCommit, softCup, hardCup }: InfoBlockProps) => {
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    // eslint-disable-next-line no-new
    if (ref?.current) {
      const instance = Dragscroll.getInstance(ref?.current);
      instance.fix(ref?.current);
    }
  }, [ref?.current]);

  const committedElement = (
    <section key="committed">
      <InfoCard
        colors={(committed >= softCup
          ? [
              {
                key: 'committed Colors.good',
                color: Colors.good,
              },
            ]
          : [
              {
                key: 'committed Colors.beforeSoftCup',
                color: Colors.beforeSoftCup,
              },
            ]
        ).concat(
          committed > hardCup
            ? [
                {
                  key: 'committed Colors.afterHardCup',
                  color: Colors.afterHardCup,
                },
              ]
            : []
        )}
        text="Deposited"
        amount={committed}
        currencyLabel={currencyLabel}
      />
    </section>
  );

  const yourCommitElement = (
    <section key="yourCommit">
      <InfoCard
        colors={(yourCommit && yourCommit >= softCup
          ? [
              {
                key: 'yourCommit Colors.good',
                color: Colors.good,
                whiteTranslucentCanvas: true,
              },
            ]
          : [
              {
                key: 'yourCommit Colors.good / Colors.beforeSoftCup',
                color: committed >= softCup ? Colors.good : Colors.beforeSoftCup,
                whiteTranslucentCanvas: true,
              },
            ]
        ).concat(
          yourCommit && yourCommit > hardCup
            ? [
                {
                  key: 'yourCommit Colors.afterHardCup',
                  color: Colors.afterHardCup,
                  whiteTranslucentCanvas: true,
                },
              ]
            : []
        )}
        text="Your deposit"
        amount={yourCommit || 0}
        currencyLabel={currencyLabel}
      />
    </section>
  );
  const SoftCapElement = (
    <section key="SoftCap">
      <InfoCard colors={[]} text="Soft Cap" amount={softCup} currencyLabel={currencyLabel} />
    </section>
  );
  const HardCapElement = (
    <section key="HardCap">
      <InfoCard colors={[]} text="Hard Cap" amount={hardCup} currencyLabel={currencyLabel} />
    </section>
  );
  const OverflowElement = (
    <section key="Overflow">
      <InfoCard
        colors={
          committed > hardCup
            ? [
                {
                  key: 'Overflow Colors.afterHardCup',
                  color: Colors.afterHardCup,
                },
              ]
            : []
        }
        text="Overflow"
        amount={Math.max(committed - hardCup, 0)}
        currencyLabel={currencyLabel}
      />
    </section>
  );

  const cards = [committedElement]
    .concat(yourCommit ? [yourCommitElement] : [])
    .concat([SoftCapElement, HardCapElement])
    .concat(committed - hardCup > 0 ? [OverflowElement] : []);

  return (
    <div className={st.box}>
      <div className={`${st.main} dragscroll`} style={isMobile ? {} : { overflow: 'hidden' }}>
        <ul ref={ref} className={st.list}>
          {cards.map((card) => (
            <li
              key={`_${card.key}`}
              className={`${st.item}`}
              onClick={(e) => {
                Dragscroll.getInstance().scrollToActiveItem(e.target);
              }}
            >
              {card}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InfoBlock;
