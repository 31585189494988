/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { projects } from '../../common/icons';
import logo from '../../common/logo.svg';
import { ButtonBox, WalletsManager } from '../../components/content';
import { Invest } from '../../components/invest';
import { Context } from '../../providers/Provider';
import Project from '../Project';
import Projects from '../Projects';
import { PageNotFound } from '../page-not-found';
import { WithdrawalByOwner } from '../withdrawal-by-owner';
import { Tabs } from '../../components/tabs';
import { TabsStatusEnum } from '../../components/tabs/Tabs';
import { MenuToggleButtonInner } from './menu-buttons';
import st from './style.module.css';

export type MainProps = {
  // todo
};

const options = [
  {
    name: 'Projects',
    icon: projects,
    to: 'projects',
  },
  // {
  //   name: 'Trade',
  //   icon: trade,
  //   to: 'trade',
  // },
];

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to]);
  return null;
};

const Main = (/* {}: MainProps */) => {
  const navigate = useNavigate();
  const [pagePosition, setPagePosition] = useState<number | null>(null);
  const [currentOption, setCurrentOption] = useState<TabsStatusEnum>();

  const { isMobileMenuShow, isMobile, setIsMobileMenuShow } = useContext(Context);

  const height100vh = use100vh();

  useEffect(() => {
    if (currentOption) navigate(`/projects/${currentOption?.toLocaleLowerCase() || 'live'}`);
  }, [currentOption]);

  useEffect(() => {
    if (isMobileMenuShow) {
      setPagePosition(window.scrollY || 0);
      document.body.classList.add(st['scroll-kill']);
    } else {
      document.body.classList.remove(st['scroll-kill']);
      window.scrollTo(0, pagePosition || 0);
      setPagePosition(null);
    }
  }, [isMobileMenuShow]);

  const setCurrentOptionAcrossLoading = (x: typeof currentOption) => setCurrentOption(x);

  const menu = (
    <Tabs
      options={[
        'Live',
        // 'Upcoming',
        // 'Finished',
        // 'Failed',
        // 'Participated',
      ]}
      currentOption="Live"
      setCurrentOption={() => undefined}
      // setCurrentOption={setCurrentOptionAcrossLoading}
    />
  );

  return (
    <div className={`${st.box} ${isMobile ? st.mobile : ''}`} style={{ minHeight: height100vh || '100vh' }}>
      <section className={st.content} style={{ minHeight: height100vh || '100vh' }}>
        <Routes>
          <Route index element={<Redirect to="/projects" />} />
          <Route path="project">
            <Route path=":poolNumber/deposit" element={<Invest />} />
            <Route path=":poolNumber/withdraw" element={<WithdrawalByOwner />} />
            <Route path=":poolNumber" element={<Project />} />
            <Route index element={<Redirect to="/" />} />
          </Route>
          <Route path="projects/:status" element={<Projects />} />
          <Route path="projects" element={<Redirect to="/projects/live" />} />
          {/* <Route path="trade" element={<Trade />} /> */}
          <Route path="404" element={<PageNotFound />} />
          <Route path="*" element={<Redirect to="/" />} />
        </Routes>
      </section>
      <section className={`${st.menu} ${st['menu-desktop']}`} style={{ height: height100vh || '100vh' }}>
        <NavLink to="/" className={st['logo-wrapper']}>
          <img src={logo} alt="" />
        </NavLink>
        <nav style={{ width: '100%' }}>{menu}</nav>
      </section>
      {(isMobileMenuShow || pagePosition !== null) && (
        <>
          <div className={st['background-blur']} />
          <section
            className={`${st.menu} ${st['menu-mobile']} ${st.active}`}
            style={{ height: height100vh || '100vh' }}
          >
            <nav className={st.nav}>{menu}</nav>
            <MenuToggleButtonInner />
          </section>
        </>
      )}
    </div>
  );
};

export default Main;
