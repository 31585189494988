/* eslint-disable no-underscore-dangle */
import { useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { MetaMaskContext } from '../../providers/MetaMaskProvider';
import { networkOptionsMap } from '../get-networks';

export const injected = new InjectedConnector({
  supportedChainIds: Object.keys(networkOptionsMap).map((chainId) => +chainId),
});

export const walletConnectConnector = new WalletConnectConnector({
  supportedChainIds: Object.keys(networkOptionsMap).map((chainId) => +chainId),
});

export const WalletConnectProviderInstance = new WalletConnectProvider({
  infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
  rpc: Object.values(networkOptionsMap).reduce<Record<number, string>>((acc, value) => {
    acc[Number(value.chainId)] = value.rpcUrl!;
    return acc;
  }, {}),
  qrcodeModalOptions: {
    mobileLinks: ['trust', 'metamask'],
  },
});

const useMetaMask = () => {
  const { setIsInstall, setIsCheck, setIsAborted, setChainId, setAccount, account, setIsMetaMask, isMetaMask } =
    useContext(MetaMaskContext);

  const { activate, deactivate } = useWeb3React();

  const getEthereum = async () => {
    if (!window.ethereum) {
      window.ethereum = WalletConnectProviderInstance as any;
    }

    const ethereum = window.ethereum as unknown as typeof window.ethereum & {
      request: (x: any) => Promise<any>;
      on: (str: string, fn: (x: any) => void) => void;
      enable?: () => Promise<string>;
      disconnect?: () => Promise<void>;
    };

    setIsMetaMask(!!ethereum?.isMetaMask);

    return ethereum || null;
  };

  const connect = async () => {
    const ethereum = await getEthereum();
    setIsAborted(false);
    try {
      if (isMetaMask) activate(injected);
      else {
        const address = await ethereum?.enable?.();
        if (address) activate(walletConnectConnector);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsAborted(true);
    }
  };

  const disconnect = async () => {
    const ethereum = await getEthereum();
    try {
      if (isMetaMask) deactivate();
      else await ethereum?.disconnect?.();
      setAccount(undefined);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleChainChanged = (_chainId: number | string) => {
    setChainId(+_chainId);
  };
  const checkChainId = async () => {
    const ethereum = await getEthereum();
    if (!ethereum) {
      setChainId(undefined);
      return;
    }
    const _chainId: string = await ethereum.request({ method: 'eth_chainId' });
    handleChainChanged(_chainId);
    ethereum.on('chainChanged', handleChainChanged);
  };

  const handleAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) {
      // eslint-disable-next-line no-console
      console.error('Please connect to MetaMask.');
      setAccount(undefined);
    } else if (accounts[0] !== account) {
      setAccount(accounts[0]);
    }
  };
  const checkAccounts = async () => {
    const ethereum = await getEthereum();
    if (!ethereum) {
      setAccount(undefined);
      return;
    }
    const _accounts: string[] = await ethereum.request({ method: 'eth_accounts' });
    await handleAccountsChanged(_accounts);
    ethereum.on('accountsChanged', handleAccountsChanged);
  };

  const switchChain = async (chainId: number) => {
    try {
      const ethereum = await getEthereum();
      if (!ethereum) {
        return;
      }
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`, // A 0x-prefixed hexadecimal string
          },
        ],
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const checkMetaMask = async () => {
    const provider = await getEthereum();

    if (provider) {
      setIsInstall(true);
      await checkChainId();
      await checkAccounts();
      // eslint-disable-next-line no-console
      console.log('MetaMask is active');
    } else {
      setIsInstall(false);
      // eslint-disable-next-line no-console
      console.log('MetaMask is not available');
    }
    setIsCheck(true);
  };

  useEffect(() => {
    checkMetaMask();
  }, []);

  return {
    connect,
    disconnect,
    switchChain,
  };
};

export default useMetaMask;
