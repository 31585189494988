import React from 'react';
import st from './style.module.css';

export type StatusEnum = 'Live' | 'Upcoming' | 'Finished' | 'Failed';

export type StatusProps = {
  type: StatusEnum;
};

const Status = ({ type }: StatusProps) => {
  return (
    <div>
      <span className={`${st.box} ${st[type.toLocaleLowerCase()]}`}>
        <span className={st.text}>{type}</span>
      </span>
    </div>
  );
};

export default Status;
