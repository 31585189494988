import { getBridgeAssets, getTonAssets, Token } from './get-assets';
import { getTokenDetails } from '../ton/utils';

type GetCurrencyOptions = {
  chainId?: number;
  paymentTokenRoot?: string;
};

type CurrencyOption = {
  chainId: number | undefined;
  bridge: {
    vaults: {
      chainId: string;
      vault: string;
      ethereumConfiguration: string;
      depositType: 'default' | 'credit';
    }[];
    proxy: string;
  };
  tokenInfo: Token;
  value: string;
  label: string;
};

export const getCurrencyOptions = async ({
  chainId,
  paymentTokenRoot,
}: GetCurrencyOptions): Promise<CurrencyOption[] | null> => {
  if (!chainId && paymentTokenRoot) {
    const customTokenInfo = await getTokenDetails({ address: paymentTokenRoot });
    if (!customTokenInfo) return null;

    return [
      {
        tokenInfo: {
          decimals: +customTokenInfo.decimals,
          symbol: customTokenInfo.symbol,
        },
        value: customTokenInfo.symbol,
        label: customTokenInfo.symbol,
      } as any,
    ];
  }

  const tonAssets = await getTonAssets();
  const bridgeAssets = await getBridgeAssets();
  const tokensInfo = Object.entries(bridgeAssets?.token || {}).reduce<CurrencyOption[]>((accum, [key, token]) => {
    const tokenInfo = tonAssets?.tokens.find((t) => t.address === key && t.address === paymentTokenRoot);
    const vault = token.vaults.filter((v) => v.depositType === 'credit' && +v.chainId === chainId)?.[0];

    if (!tokenInfo || !vault) return accum;

    accum.push({
      chainId,
      bridge: {
        ...token,
        vaults: [vault],
      },
      tokenInfo,
      value: tokenInfo.symbol,
      label: tokenInfo.symbol,
    });

    return accum;
  }, []);

  if (tokensInfo?.[0]) {
    return tokensInfo as unknown as Exclude<typeof tokensInfo[0], null>[];
  }

  return null;
};
